import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const STATIC_WIDTH_FOR_ALIGNMENT = 250;

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}));

export const StyledStaticStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: STATIC_WIDTH_FOR_ALIGNMENT,
}));

export const StyledStaticBox = styled(Box)(() => ({
  width: STATIC_WIDTH_FOR_ALIGNMENT,
}));
