import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from '@desygner/ui-common-translation';

export default function LogoutButton({
  handleLogout,
}: React.PropsWithChildren<{ handleLogout: VoidFunction }>) {
  const { t } = useTranslation();

  return (
    <ListItemButton
      onClick={handleLogout}
      sx={{ justifyContent: 'start', gap: 3 }}
    >
      <LogoutIcon
        color="error"
        fontSize="small"
      />
      <ListItemText
        primary={t('layout.auth.logout', {
          defaultValue: 'Logout',
        })}
        primaryTypographyProps={{
          variant: 'textMd',
        }}
      />
    </ListItemButton>
  );
}
