import {
  StyledStack,
  StyledText,
} from '@components/search-form/react-hook-form-search-mode-select/tooltip/styles';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Stack from '@mui/material/Stack';

type Props = {
  hint: string;
};

const STAR_URL = 'https://assets.knowz.com/assets/svg/star-dark.svg';
const STAR_SIZE = 20;

export default function Content({ hint }: Props) {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={2}
      >
        <AutoAwesomeIcon />
        <Text
          variant="textSm"
          weight="semibold"
        >
          {t('page.home.hero.search.genius.title', {
            defaultValue: 'Genius mode',
          })}
        </Text>
      </Stack>
      <StyledStack>
        <Stack
          direction="row"
          gap={2}
        >
          <img
            src={STAR_URL}
            alt="knowz credits"
            width={STAR_SIZE}
            height={STAR_SIZE}
          />
          <Text
            variant="textMd"
            weight="semibold"
          >
            {t('page.home.hero.search.genius.premiumFeature', {
              defaultValue: 'Premium Feature',
            })}
          </Text>
        </Stack>
        <StyledText variant="textXs">{hint}</StyledText>
      </StyledStack>
    </Stack>
  );
}
