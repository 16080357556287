import { Branches, Sequence, Properties } from 'sequential-workflow-model';

export type StepsDefinitionsType = { [key: string]: KnowzStepDefinitionType };

export type KnowzStepDefinitionType = {
  order: number;
  propertyTypes: PropertyType[];
  triggerProperties?: TriggerType[];
  contextTypes?: BagItemType[];
  step: StepType;
  group: string;
};

export type StepType = {
  componentType: string;
  type: string;
  name: string;
  branches?: Branches;
  sequence?: Sequence;
  properties?: Properties;
  isExpanded?: boolean;
  owner?: string | null;
  blockId?: string | null;
  propertyTypes?: PropertyType[];
};

// These properties will get instantiated by the triggering side
export type TriggerType = {
  ref: string;
  type: BagItemType;
  data?: number;
};

// These properties define the editable form we will display for a given step
export type PropertyType = {
  id: string;
  usage: 'input' | 'output' | 'definition';
  name: string;
  description: string;
  wrapper: 'item' | 'list';
  types: BagItemType[];
  variant?: 'short' | 'code';
  isOptional?: boolean;
  options?: string[];
};

export const BagItemTypes = [
  'string',
  'strings',
  'number',
  'numbers',
  'match',
  'matches',
  'thread',
  'id-thread',
  'folder',
  'id-folder',
  'source',
  'id-source',
  'history-block',
  'id-history-block',
  'template',
  'id-template',
  'design',
  'json',
  'ref-string',
  'ref-strings',
  'ref-number',
  'ref-numbers',
  'ref-match',
  'ref-matches',
  'ref-thread',
  'ref-source',
  'ref-folder',
  'ref-history-block',
  'ref-history-blocks',
  'ref-template',
  'ref-design',
  'ref-json',
] as const;

// Plain types are to be defined in the steps properties via UI, and their scope is limited to the step
// References are named inputs and outputs of a given type, to be found and stored in the data bag
export type BagItemType = typeof BagItemTypes[number];
