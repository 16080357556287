import paths from '@router/paths';
import StyledLink from './styles';
import { SxProps, useTheme } from '@mui/material';
import useWorkspace from '@hooks/useWorkspace';
import Box from '@mui/material/Box';
import KnowzIcon from '~icons/knowz-iconify/knowz-monotone';

type Props = Partial<{
  variant: 'mini' | 'full';
  sx: SxProps;
}>;

const MINI_LOGO_SIZE = 45;

const KNOWZ_LOGO_SIZE = 32;

export default function Logo({ variant, sx }: Props) {
  const isMini = variant === 'mini';
  const theme = useTheme();

  const { setupMode } = useWorkspace();

  let logo;

  if (isMini) {
    logo = (
      <Box
        sx={sx}
        component="img"
        width={MINI_LOGO_SIZE}
        height={MINI_LOGO_SIZE}
        alt="knowz"
        src="https://assets.knowz.com/assets/svg/knowz-favicon---gradient.svg" //TODO: use icons
      />
    );
  }

  if (!isMini) {
    logo = (
      <KnowzIcon
        style={{
          color:
            theme.palette.mode === 'light'
              ? theme.palette.common.black
              : theme.palette.common.white,
          fontSize: KNOWZ_LOGO_SIZE,
        }}
      />
    );
  }

  return (
    <StyledLink
      to={
        setupMode === 'on' ? paths.knowledgeBase.pathname : paths.home.pathname
      }
    >
      {logo}
    </StyledLink>
  );
}
