import sendCreditsUsedToAnalytics from '@analytics/sendCreditsUsedToAnalytics';
import useQueryAfterAuthenticationLoaded from '@hooks/useQueryAfterAuthenticationLoaded';
import useSearchParamsState from '@hooks/useSearchParamsState';
import { payments } from '@lib/agent';
import { useRef } from 'react';
import useAuth from './useAuth';

export default function useGetCredit() {
  const [, setSearchParams] = useSearchParamsState('action', '');
  //? This is a reference to the previous credit amount, so we make sure not to send the same amount to analytics multiple times
  const previousCreditRef = useRef<number | null>(null);
  const { me } = useAuth();
  const {
    data: creditsData,
    isLoading: isCreditLoading,
    isError: isCreditError,
  } = useQueryAfterAuthenticationLoaded({
    queryKey: ['credits'],
    queryFn: async () => {
      try {
        const response = await payments.getCredit();
        const totalCredits = response.data.initialQuantity;
        const remainingCredits = response.data.quantity;
        const creditAsPercentage = (remainingCredits / totalCredits) * 100;
        const remainingCapacity =
          response.data.storageLimit - response.data.storageUsed;
        const knowledgeCapacityPercentage =
          (remainingCapacity / response.data.storageLimit) * 100;
        const usedCredits = totalCredits - remainingCredits;

        if (
          previousCreditRef.current === null ||
          previousCreditRef.current !== usedCredits
        ) {
          previousCreditRef.current = usedCredits;

          sendCreditsUsedToAnalytics({
            usedAmount: usedCredits,
            totalAmount: totalCredits,
            userUUID: me!.uuid,
          });
        }

        if (creditAsPercentage <= 0) {
          setSearchParams('upgrade-account');
        }

        return {
          creditAsPercentage,
          knowledgeCapacityPercentage:
            knowledgeCapacityPercentage < 0 ? 0 : knowledgeCapacityPercentage,
          remainingCredits,
        };
      } catch (error) {
        console.error(error);
      }
    },
    options: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

  return { creditsData, isCreditLoading, isCreditError };
}
