import { FormHelperText, Stack } from '@mui/material';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { forwardRef } from 'react';

type Props = Omit<OutlinedInputProps, 'size'> & {
  helperText?: string;
};

const Input = forwardRef<HTMLDivElement, Props>(
  ({ helperText, error, ...otherProps }, ref) => {
    const shouldWrap = !!helperText;

    if (shouldWrap) {
      return (
        <Stack
          ref={ref}
          sx={{
            width: '100%',
          }}
        >
          <OutlinedInput
            {...otherProps}
            error={error}
          />
          {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </Stack>
      );
    }

    return (
      <OutlinedInput
        ref={ref}
        {...otherProps}
        error={error}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
