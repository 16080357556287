import Breadcrumb from '@components/pages/knowledge-base/components/breadcrumb';
import Header from '@components/pages/knowledge-base/components/header';
import AddKnowledgeBaseModal from '@components/pages/knowledge-base/components/modals/add-knowledge-base';
import Table from '@components/pages/knowledge-base/components/table';
import useToggleState from '@hooks/useToggleState';
import { createContext, useCallback, useMemo, useState } from 'react';

type KnowledgeBaseContextType = {
  isAddKnowledgeModalOpen: boolean;
  isCreateFolderModalOpen: boolean;
  isRenameModalOpenById: number | null;
  toggleCreateFolderModal: VoidFunction;
  handleOpenAddKnowledgeBaseModal: VoidFunction;
  handleCloseAddKnowledgeBaseModal: VoidFunction;
  handleOpenRenameModal: (item: number | null) => void;
  handleCloseRenameModal: VoidFunction;
};

export const KnowledgeBaseContext =
  createContext<KnowledgeBaseContextType | null>(null);

export default function KnowledgeBaseProvider({
  children,
}: React.PropsWithChildren) {
  const { state: isCreateFolderModalOpen, toggle: toggleCreateFolderModal } =
    useToggleState(false);

  const [isRenameModalOpenById, setIsRenameModalOpenById] = useState<
    number | null
  >(null);

  const [isAddKnowledgeModalOpen, setIsAddKnowledgeModalOpen] = useState(false);

  const handleOpenAddKnowledgeBaseModal = useCallback(() => {
    setIsAddKnowledgeModalOpen(true);
  }, []);

  const handleCloseAddKnowledgeBaseModal = useCallback(() => {
    setIsAddKnowledgeModalOpen(false);
  }, []);

  const handleOpenRenameModal = useCallback((item: number | null) => {
    setIsRenameModalOpenById(item);
  }, []);

  const handleCloseRenameModal = useCallback(() => {
    setIsRenameModalOpenById(null);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isCreateFolderModalOpen,
      handleOpenAddKnowledgeBaseModal,
      handleCloseAddKnowledgeBaseModal,
      toggleCreateFolderModal,
      isAddKnowledgeModalOpen,
      isRenameModalOpenById,
      handleOpenRenameModal,
      handleCloseRenameModal,
    }),
    [
      toggleCreateFolderModal,
      handleOpenAddKnowledgeBaseModal,
      handleCloseAddKnowledgeBaseModal,
      isCreateFolderModalOpen,
      isAddKnowledgeModalOpen,
      isRenameModalOpenById,
      handleOpenRenameModal,
      handleCloseRenameModal,
    ],
  );

  return (
    <KnowledgeBaseContext.Provider value={memoizedValue}>
      {children}
    </KnowledgeBaseContext.Provider>
  );
}

KnowledgeBaseProvider.Header = Header;
KnowledgeBaseProvider.Breadcrumb = Breadcrumb;
KnowledgeBaseProvider.AddKnowledgeBaseModal = AddKnowledgeBaseModal;
KnowledgeBaseProvider.Table = Table;
