import { getStepPropertiesDefinition } from '@components/pages/command-designer/config';
import Text from '@components/text';
import { convertPxToRem } from '@lib/fonts';
import { StepType } from '@lib/step/types';
import { MDXEditorMethods } from '@mdxeditor/editor';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogProps } from '@toolpad/core';
import { useRef, useState } from 'react';
import { useStepEditor } from 'sequential-workflow-designer-react';
import DirectiveToolbarContents from './DirectiveToolbarContents';
import Editor, { markdownStyles } from './Editor';

export function SmartPromptViewer({
  markdown,
  onClick,
}: {
  markdown: string;
  onClick: () => void;
}) {
  const mdxEditorRef = useRef<MDXEditorMethods>(null);
  mdxEditorRef.current?.setMarkdown(markdown);

  return (
    // Arbitrary styles copied from apps/knowz/src/app/theme/overrides/Button.ts
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: (theme) =>
          alpha(
            theme.palette.mode === 'light'
              ? theme.palette.common.black
              : theme.palette.common.white,
            0.05,
          ),
        borderRadius: 2,
        cursor: 'pointer',
        overflow: 'hidden',
        overflowY: 'scroll',
        height: '10em',
        lineHeight: '1.2em',
        boxSizing: 'content-box',
        border: (theme) =>
          `1px solid ${alpha(theme.palette.text.primary, 0.23)}`,

        '&:hover': {
          borderColor: (theme) => theme.palette.neutral.border,
        },

        '& > *': {
          pointerEvents: 'none',
        },

        '& .MuiIconButton-root': {
          display: 'none',
        },

        ...markdownStyles,
      }}
    >
      <Editor
        ref={mdxEditorRef}
        readOnly={true}
      />
    </Box>
  );
}

export const CONTEXT_HEIGHT = '101px'; // TODO: revisit this and make it a bit more flexible (specially for mobile)

type PayloadType = {
  markdown: string;
  propertyId: string;
};

export function SmartPromptEditorDialog({
  open,
  onClose,
  payload: { markdown, propertyId },
}: DialogProps<PayloadType, string | null>) {
  const [updatedMarkdown, setUpdatedMarkdown] = useState(markdown);
  const { step } = useStepEditor();
  const stepPropertiesDefinition = getStepPropertiesDefinition(
    step as StepType,
  );

  const property = stepPropertiesDefinition.find((p) => p.id === propertyId);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose(null);
      }}
      sx={{
        '& .MuiDialog-container': {
          '& > .MuiPaper-root': {
            width: '90vw',
            maxWidth: '1080px',
            height: '90vh',
            maxHeight: '800px',
          },

          '& .MuiDialogContent-root': {
            /**
             * Scenarios taken care of:
             *  - Empty markdown can be clicked and edited (any line)
             *  - Long makrdown can be scrolled while showing context
             */
            '& > .mdxeditor-root': {
              display: 'flex',
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              flexDirection: 'column-reverse',

              background: (theme) => theme.palette.background.paper,
              border: (theme) =>
                `1px solid ${theme.palette.background.card.main}`,
              borderRadius: (theme) =>
                convertPxToRem(theme.shape.borderRadius * 2.5),
              position: 'relative',
              padding: 0,

              '& .mdxeditor-toolbar': {
                padding: 0,
                borderRadius: 0,
                overflow: 'hidden',
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: CONTEXT_HEIGHT, // TODO: revisit this and make it a bit more flexible (specially for mobile)

                '.MuiStack-root': {
                  height: CONTEXT_HEIGHT,
                },
              },

              '& .mdxeditor-root-contenteditable': {
                ...markdownStyles,

                height: `calc(100% - ${CONTEXT_HEIGHT})`,

                '& > div': {
                  overflowY: 'scroll',
                  width: '100%',
                  height: '100%',

                  '& .mdxeditor-contenteditable': {
                    minHeight: '100%',
                  },
                },
              },
            },
          },
        },
      }}
    >
      <DialogTitle>
        <Text
          variant="displayMd"
          component="span"
          sx={{ px: 2 }}
        >
          {property?.name}
        </Text>
        <Text
          variant="textMd"
          sx={{ px: 2 }}
        >
          {property?.description}
        </Text>
      </DialogTitle>

      <DialogContent>
        <Editor
          setUpdatedMarkdown={setUpdatedMarkdown}
          markdown={updatedMarkdown}
          toolbarContents={() => (
            <DirectiveToolbarContents step={step as StepType} />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ mt: 3 }}>
        <Button
          onClick={() => onClose(null)}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClose(updatedMarkdown)}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
