import useGetMembershipByWorkspaceIdLazily from '@hooks/useGetMembership';
import useLocalStorage from '@hooks/useLocalStorage';
import { getDecodedToken, isValidToken } from '@lib/jwt';
import {
  setCreationOwnerHeader,
  setReadingOwnerHeader,
} from '@lib/setSetupModeSession';
import { WorkspaceResponseType } from '@shared-types/workspace';
import { QueryKey } from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

//? on: setup mode is enabled, off: setup mode is disabled, null: individual role
export type SetupModeType = 'on' | 'off' | null;

export const WorkspaceContext = createContext<{
  isLoaded: boolean;
  isWorkspace: boolean;
  isWorkspaceAdmin: boolean;
  setupMode: SetupModeType;
  workspace: WorkspaceResponseType | null;
  handleSetWorkspace: (workspace: WorkspaceResponseType | null) => void;
  handleSetupMode: (setupMode: SetupModeType) => void;
  resetWorkspaceToIndividualRole: VoidFunction;
  alertHeight: number;
  handleSetAlertHeight: (height: number) => void;
  getNamespacedQueryKey: (...keys: QueryKey) => Array<string | number | null>;
} | null>(null);

export default function WorkspaceProvider({
  children,
}: React.PropsWithChildren) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWorkspaceAdmin, setIsWorkspaceAdmin] = useState(false);
  const [alertHeight, setAlertHeight] = useState(0);

  const token = window.localStorage.getItem('token');

  const { value: workspace, setValue: setWorkspace } =
    useLocalStorage<WorkspaceResponseType | null>('workspace', null);
  const { value: setupMode, setValue: setSetupMode } =
    useLocalStorage<SetupModeType | null>('setupMode', null);

  const handleSetWorkspace = useCallback(
    (workspace: WorkspaceResponseType | null) => {
      setIsLoading(true);
      //TODO: fix this timeout
      setTimeout(() => setWorkspace(workspace));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { membershipData } = useGetMembershipByWorkspaceIdLazily({
    id: workspace?.id,
  });

  const handleSetupMode = useCallback(
    (setupMode: SetupModeType) => {
      //! we never intercept the cycle of updating states since it can lead to breaking the chain of async update and batching
      // if (!isWorkspaceAdmin) throw new Error('User is not an admin');
      // if (!workspace) throw new Error('Role cannot be set outside a workspace');
      // if (setupMode === null)
      //   throw new Error('Workspace role cannot be individual');

      setIsLoading(true);
      //TODO: fix this timeout
      setTimeout(() => {
        setSetupMode(setupMode);
        setReadingOwnerHeader(workspace, membershipData, setupMode);
        setCreationOwnerHeader(workspace, membershipData, setupMode);
        setIsLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isWorkspaceAdmin, membershipData?.id, workspace?.id],
  );

  const handleSetAlertHeight = useCallback((height: number) => {
    setAlertHeight(height);
  }, []);

  useEffect(() => {
    const loadingDone = Object.is(workspace?.id, membershipData?.workspaceId);

    if (
      token &&
      (getDecodedToken(token)!.auth_type === 'web_fingerprint' ||
        !isValidToken(token))
    ) {
      resetWorkspaceToIndividualRole();
      return;
    }

    if (loadingDone) {
      const canBeAdmin = !!membershipData?.permissions?.admin;
      let calculateSetupMode: SetupModeType = null;

      if (workspace) {
        const hadSetupModeEnabled = setupMode === 'on';

        calculateSetupMode = canBeAdmin && hadSetupModeEnabled ? 'on' : 'off';
      }
      setSetupMode(calculateSetupMode);
      setIsWorkspaceAdmin(canBeAdmin);
      setReadingOwnerHeader(workspace, membershipData, calculateSetupMode);
      setCreationOwnerHeader(workspace, membershipData, calculateSetupMode);
      isLoaded || setIsLoaded(true);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipData?.id, token]);

  const getNamespacedQueryKey = useCallback(
    function (...keys: Array<string | number | null>) {
      return [
        `workspace: ${workspace?.id}`,
        `setupMode: ${setupMode}`,
        ...keys,
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const resetWorkspaceToIndividualRole = useCallback(() => {
    setSetupMode(null); //? individual role
    setWorkspace(null);
    setIsWorkspaceAdmin(false);
    setReadingOwnerHeader(null, null, null);
    setCreationOwnerHeader(null, null, null);
    isLoaded || setIsLoaded(true);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      isWorkspace: !!workspace,
      setupMode,
      workspace,
      isLoaded,
      isWorkspaceAdmin,
      handleSetWorkspace,
      handleSetupMode,
      getNamespacedQueryKey,
      resetWorkspaceToIndividualRole,
      handleSetAlertHeight,
      alertHeight,
    }),
    [
      workspace,
      setupMode,
      isLoaded,
      isWorkspaceAdmin,
      handleSetWorkspace,
      handleSetupMode,
      getNamespacedQueryKey,
      resetWorkspaceToIndividualRole,
      handleSetAlertHeight,
      alertHeight,
    ],
  );
  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
}
