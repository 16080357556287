import {
  StyledSkeleton,
  StyledStack,
} from '@components/pages/assistants-session/chat-history/loading-skeleton/styles';
import MockSkeletonLoadingType from '@components/thread/sections/loading-skeleton/types';
import Stack from '@mui/material/Stack';

const MOCK_SKELETON_LOADING_DATA: MockSkeletonLoadingType = [
  {
    direction: 'row-reverse',
    paragraph: { width: '70%', height: 60 },
  },
  {
    direction: 'row',
    paragraph: { width: '35%', height: 90 },
  },
  {
    direction: 'row-reverse',
    paragraph: { width: '45%', height: 90 },
  },
  {
    direction: 'row',
    paragraph: { width: '30%', height: 50 },
  },
  {
    direction: 'row-reverse',
    paragraph: { width: '50%', height: 50 },
  },
  {
    direction: 'row',
    paragraph: { width: '60%', height: 60 },
  },
  {
    direction: 'row-reverse',
    paragraph: { width: '60%', height: 75 },
  },
];

const AVATAR_SIZE = 40;

export default function SkeletonLoading() {
  return (
    <Stack gap={6}>
      {MOCK_SKELETON_LOADING_DATA.map(({ direction, paragraph }, i) => {
        return (
          <StyledStack
            direction={direction}
            key={i}
          >
            <StyledSkeleton
              animation="wave"
              width={AVATAR_SIZE}
              height={AVATAR_SIZE}
              variant="rounded"
            />
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              width={paragraph.width}
              height={paragraph.height}
            />
          </StyledStack>
        );
      })}
    </Stack>
  );
}
