import { useTranslation } from '@desygner/ui-common-translation';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { folders } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import useQueryParams from './useQueryParams';

export default function useGetFolderDataByPath({
  path,
}: {
  path: string | null;
}) {
  const { removeAllQueryParamsNoMatterWhat } = useQueryParams();

  const { t } = useTranslation();

  const isItRootFolder = path === null || path === '';

  const queryKey = ['foldersByPath', path];

  const {
    data: folderData,
    isLoading: isFolderDataLoading,
    isError: isFolderDataError,
    isPending: isFolderDataPending,
    isFetching: isFolderDataFetching,
  } = useQueryAfterWorkspaceLoaded({
    queryKey,
    queryFn: async () => {
      try {
        if (isItRootFolder) {
          return null;
        }
        const { data } = await folders.getOneByPath({
          path: normalizePath(decodeURIComponent(path!)),
        });

        return data;
      } catch (error) {
        if (isAxiosError(error) && error.response?.data.code === 404) {
          removeAllQueryParamsNoMatterWhat();
          toast.error(
            t('response.errors.code.404', {
              defaultValue: 'Resource not found.',
            }),
          );
          queryClient.removeQueries({ queryKey });
          return;
        }
        throw new Error(`An error occurred while fetching folders: ${error}`);
      }
    },
    options: {
      placeholderData: (prev) => prev,
    },
  });

  return {
    folderData,
    isFolderDataLoading,
    isFolderDataError,
    isFolderDataPending,
    isFolderDataFetching,
  };
}

function normalizePath(path: string) {
  return path.split(' ').join('-');
}
