import Markdown from '@components/markdown';
import Card from '@mui/material/Card';
import { SourceLinksType } from '@shared-types/search-result/streaming';

type Props = {
  text: string | null;
  links?: Array<SourceLinksType>;
};

export default function Info({ text, links }: Props) {
  if (text == null) return null;

  return (
    <Card>
      <Markdown links={links}>{text}</Markdown>
    </Card>
  );
}
