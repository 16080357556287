import { commandBlocks, CommandBlockType, storage } from '@lib/agent';
import { TFunction, useTranslation } from '@desygner/ui-common-translation';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import useWorkspace from '@hooks/useWorkspace';
import { toast } from 'react-toastify';
import useAuth from '@hooks/useAuth';
import { useMutation, useQueries } from '@tanstack/react-query';
import { BlockStep } from '../extensions/block-step/blockStepExtension';

const MAX_COMMAND_BLOCK_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

export function useCreateOneCommandBlock() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createOneCommandBlock,
    mutationKey: ['createOneCommandBlock'],
    onError: (error) => onError(t, error),
  });
}

async function createOneCommandBlock({
  name,
  description,
  icon,
  packages,
}: CommandBlockType) {
  return await commandBlocks.createOneCommandBlock({
    name: sanitizeName(name),
    description,
    icon,
    packages,
  });
}

function sanitizeName(name: string) {
  return name.length > MAX_COMMAND_BLOCK_NAME_LENGTH
    ? name.slice(0, MAX_COMMAND_BLOCK_NAME_LENGTH - DOTS_LENGTH) + '...'
    : name;
}

export function useSaveCommandBlockDefinition() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: saveCommandBlockDefinition,
    onError: (error) => onError(t, error),
  });
}

async function saveCommandBlockDefinition(block: BlockStep) {
  const body = JSON.stringify(block);
  const size = new Blob([body]).size;
  const { data } = await storage.createPolicy({
    type: 'application/json',
    key: 'definitions.json',
    size: size,
    context: {
      entity_type: 'command_block',
      entity_id: block.blockId,
    },
  });

  return await storage.uploadFile({
    url: data.url,
    body,
    contentType: 'application/json',
  });
}

export function useUpdateOneCommandBlock() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: updateOneCommandBlock,
    onError: (error) => onError(t, error),
  });
}

async function updateOneCommandBlock(payload: Partial<CommandBlockType> & { id: number }) {
  const { id, ...rest } = payload;

  if (rest.name) {
    rest.name = sanitizeName(rest.name);
  }

  return await commandBlocks.updateOneCommandBlock(id, rest);
}

export function useGetCommandBlockDefinitions(blocks: CommandBlockType[]) {
  const { isLoaded: isWorkspaceLoaded, getNamespacedQueryKey } = useWorkspace();
  const { isUserAuthenticated } = useAuth();
  const { workspace } = useWorkspace();
  const { t } = useTranslation();

  return useQueries({
    queries: blocks.map((block) => {
      return {
        queryKey: getNamespacedQueryKey('blockDefinition', block.id),
        queryFn: async () => {
          try {
            const s3Key = `owners/${
              workspace!.id
            }/commands/blocks/${block.id}/definition.json`;
            const { data: credentials } = await storage.getCredentials(s3Key);
            const { data } = await commandBlocks.getCommandBlockDefinition(
              credentials.url,
            );

            return data;
          } catch (error) {
            onError(t, error);
          }
        },
        enabled: isWorkspaceLoaded && isUserAuthenticated,
      }
    }),
  });
}

export function useGetCommandBlockDefinition(blockId: number | string) {
  const { workspace } = useWorkspace();
  const { t } = useTranslation();

  const { data: blockDefinition, isLoading: isLoadingBlockDefinition } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['blockDefinition', blockId],
      queryFn: async () => {
        try {
          const s3Key = `owners/${
            workspace!.id
          }/commands/blocks/${blockId}/definition.json`;
          const { data: credentials } = await storage.getCredentials(s3Key);
          const { data } = await commandBlocks.getCommandBlockDefinition(
            credentials.url,
          );

          return data;
        } catch (error) {
          onError(t, error);
        }
      },
    });

  return { blockDefinition, isLoadingBlockDefinition };
}

export function useGetCommandBlockPackages() {
  const { t } = useTranslation();

  const { data: blockPackages, isLoading: isLoadingBlockPackages } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['blockPackages'],
      queryFn: async () => {
        try {
          return await commandBlocks.getAllPackages();
        } catch (error) {
          onError(t, error);
        }
      },
    });

  return { blockPackages, isLoadingBlockPackages };
}

function onError(t: TFunction, error: any) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}
