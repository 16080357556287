import Markdown from '@components/markdown';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import { useTranslation } from '@desygner/ui-common-translation';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Grid2';

const ORB_ICON_ELEMENT = (
  <Icon>
    <img
      src="https://assets.knowz.com/assets/svg/orb---small.png"
      alt="answer section"
    />
  </Icon>
);

type Props = {
  tos: string;
};

export default function TermsOfService({ tos }: Props) {
  const { t } = useTranslation();

  return (
    <Grid2
      container
      spacing={5}
      mb={5}
    >
      <Grid2 size={12}>
        <Stack gap={5}>
          <Card>
            <SectionHeading
              title={t('page.search.sections.answer', {
                defaultValue: 'Terms of Service',
              })}
              icon={ORB_ICON_ELEMENT}
            >
              <Markdown>{tos}</Markdown>
            </SectionHeading>
          </Card>
        </Stack>
      </Grid2>
    </Grid2>
  );
}
