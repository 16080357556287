import { createContext, useCallback, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import { SourceLinksType } from '@shared-types/search-result/streaming';
import { SourceDetailsType } from '@shared-types/sources';
import useGetSourceDetailsByIdLazily from '@components/pages/knowledge-base/hooks/useGetSourceDetailsByIdLazily';
import useGetCredentialByKeyLazily from '@hooks/useGetCredentialByKey';

type sourcePreviewCardType = {
  isOpen: boolean;
  metadata: SourceLinksType | null;
  currentSourceId: string | null;
};

type SourcePreviewContextType = {
  sourcePreviewCard: sourcePreviewCardType;
  handleSourcePreviewCardClick(params: SourcePreviewParams): void;
  closeSourcePreviewCard: VoidFunction;
  isLoading: boolean;
  sourceDetailsData?: SourceDetailsType;
  handleLinkPath: VoidFunction;
};

type SourcePreviewParams = {
  sourceId: string;
  sourceData?: Array<SourceLinksType>;
  sourceWithSubId?: string;
};

export const SourcePreviewContext =
  createContext<SourcePreviewContextType | null>(null);

export default function SourcePreviewProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [sourcePreviewCard, setSourcePreviewCard] = useState<{
    isOpen: boolean;
    metadata: SourceLinksType | null;
    currentSourceId: string | null;
  }>({
    isOpen: false,
    metadata: null,
    currentSourceId: null,
  });

  const handleSourcePreviewCardClick = useCallback(
    ({ sourceId, sourceData, sourceWithSubId }: SourcePreviewParams) => {
      if (sourceWithSubId === undefined) {
        setSourcePreviewCard({
          isOpen: true,
          metadata: null,
          currentSourceId: sourceId,
        });
        return;
      }
      const source = sourceData?.find((link) => {
        return link?.id === sourceWithSubId;
      });
      setSourcePreviewCard((prevState) => {
        if (!source) return prevState;

        if (
          prevState.metadata?.id === source?.id &&
          prevState.currentSourceId === sourceId
        ) {
          return prevState;
        }

        return {
          isOpen: true,
          metadata: source,
          currentSourceId: sourceId,
        };
      });
    },
    [],
  );

  const closeSourcePreviewCard = useCallback(() => {
    setSourcePreviewCard({
      isOpen: false,
      metadata: null,
      currentSourceId: null,
    });
  }, []);

  const sourceId =
    Number(sourcePreviewCard.currentSourceId) ??
    Number(sourcePreviewCard.metadata?.sourceId);

  const { sourceDetailsData, isSourceDetailsLoading } =
    useGetSourceDetailsByIdLazily({
      id: sourceId,
    });

  const hasHttps = sourceDetailsData?.path?.startsWith('https://');
  const { credentialData, isCredentialDataLoading } =
    useGetCredentialByKeyLazily({
      key: hasHttps ? undefined : sourceDetailsData?.path,
    });

  const handleLinkPath = useCallback(() => {
    let url = '';
    if (
      sourcePreviewCard.metadata?.link &&
      sourcePreviewCard.metadata?.link !== 'null'
    ) {
      url = sourcePreviewCard.metadata.link;
    } else if (
      sourceDetailsData?.originalPath &&
      sourceDetailsData?.originalPath !== 'null'
    ) {
      url = sourceDetailsData.originalPath;
    } else if (
      sourceDetailsData?.sourceConfiguration?.handler?.toLowerCase() ===
      'website'
    ) {
      url = `https://${sourceDetailsData.folder?.name}${sourceDetailsData.name}`;
    } else if (sourceDetailsData?.path?.startsWith('https://')) {
      url = sourceDetailsData.path;
    } else {
      url = credentialData?.data?.url || '';
    }

    if (url) {
      window.open(url, '_blank');
    }
  }, [sourcePreviewCard.metadata, sourceDetailsData, credentialData]);

  const value = useMemo(
    () => ({
      sourcePreviewCard,
      handleSourcePreviewCardClick,
      closeSourcePreviewCard,
      isLoading: isSourceDetailsLoading || isCredentialDataLoading,
      sourceDetailsData,
      handleLinkPath,
    }),
    [
      sourcePreviewCard,
      handleSourcePreviewCardClick,
      closeSourcePreviewCard,
      isSourceDetailsLoading,
      isCredentialDataLoading,
      sourceDetailsData,
      handleLinkPath,
    ],
  );

  return (
    <SourcePreviewContext.Provider value={value}>
      {children}
    </SourcePreviewContext.Provider>
  );
}
