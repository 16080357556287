import {
  StyledFormStack,
  StyledIconButton,
  StyledTextarea,
} from '@components/pages/assistants-session/message-field/styles';
import FormProvider from '@components/react-hook-form/FormProvider';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
//import AttachFileIcon from '@mui/icons-material/AttachFile'; //TODO
import messageFieldSchema from '@components/pages/assistants-session/message-field/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const DEFAULT_VALUES = {
  text: '',
};

export default function MessageField() {
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(messageFieldSchema),
    defaultValues: DEFAULT_VALUES,
  });
  const { threadId } = useGetThreadIdFromQueryParam();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods;

  function onSubmit(data: { text: string }) {
    dispatch({
      type: 'assistantResult/askAIEmitted',
      payload: {
        type: 'assistant',
        prompt: data.text,
        thread: threadId!,
        usePublicKnowledge: true,
      },
    });
    reset({ text: '' });
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledFormStack>
        <StyledFormStack
          direction="row"
          sx={{
            position: 'fixed',
            left: {
              xs: 5,
              md: 'unset',
            },
            right: {
              xs: 5,
              md: 'unset',
            },
            bottom: 20,
            borderRadius: '100px',
            border: (theme) =>
              `5px solid ${
                theme.palette.mode === 'dark'
                  ? theme.palette.common.black
                  : theme.palette.common.white
              }`,
          }}
        >
          <StyledIconButton
            disabled
            sx={{ left: 4 }}
          >
            <AutoAwesomeIcon color="action" />
          </StyledIconButton>
          <StyledTextarea
            maxRows={5}
            minRows={0}
            required
            name="text"
            placeholder={t('page.assistantsSession.messageField.placeholder', {
              defaultValue: 'Type your message here...',
            })}
          />
          <StyledIconButton
            onClick={handleSubmit(onSubmit)}
            sx={{
              right: 2,
              backgroundColor: 'background.secondary',
              border: '1px solid',
              borderColor: 'background.secondary',
            }}
            disabled={!isDirty}
          >
            <ArrowUpwardIcon color="action" />
          </StyledIconButton>
        </StyledFormStack>
      </StyledFormStack>
    </FormProvider>
  );
}
