import friendlyNameManager from '@components/pages/live-session/utils/getFriendlyName';
import { threads } from '@lib/agent';
import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  FactCheckLabelsType,
  FactCheckType,
  SentimentLabelsType,
  TranscriptType,
} from '@shared-types/live';
import { LiveHistoryBlockType } from '@shared-types/threads';
import { RootType } from '@state/store';

type FeedType = FactCheckType & { time: TranscriptType['time'] };

type LiveSessionStateType = {
  threadId?: string;
  status: 'loading' | 'active';
  transcripts: TranscriptType[];
  feeds: FeedType[];
  voiceMatch: Record<
    string,
    {
      name: string | null;
    }
  > | null;
};

const initialState: LiveSessionStateType = {
  threadId: undefined,
  status: 'loading',
  transcripts: [],
  feeds: [],
  voiceMatch: null,
};

const liveSession = createSlice({
  name: 'liveSession',
  initialState,
  reducers: {
    setThreadId: (
      state,
      action: PayloadAction<LiveSessionStateType['threadId']>,
    ) => ({
      ...initialState,
      threadId: action.payload,
    }),

    setActive: (state) => ({
      ...state,
      status: 'active',
    }),

    setTranscripts: (
      state,
      action: PayloadAction<LiveSessionStateType['transcripts']>,
    ) => ({
      ...state,
      transcripts: action.payload,
    }),

    setFeeds: (
      state,
      action: PayloadAction<LiveSessionStateType['feeds']>,
    ) => ({
      ...state,
      feeds: action.payload,
    }),

    addFeed: (state, action: PayloadAction<Omit<FeedType, 'time'>>) => {
      const { index } = action.payload;

      const transcriptIndex = state.transcripts.findLastIndex(
        (t) => t.id === index.toString(),
      );

      if (transcriptIndex < 0) return state;

      return {
        ...state,
        feeds: [
          ...state.feeds,
          {
            ...action.payload,
            time: state.transcripts[transcriptIndex]!.time,
          },
        ],
      };
    },

    addTranscript: (
      state,
      action: PayloadAction<Omit<TranscriptType, 'labels'>>,
    ) => {
      const { id } = action.payload;

      return {
        ...state,
        transcripts: [
          ...state.transcripts.filter((transcript) => transcript.id !== id),
          {
            ...action.payload,
            labels: {
              sentiments: null,
              factChecks: null,
            },
          },
        ],
      };
    },

    setSentiments: (
      state,
      action: PayloadAction<{
        historyBlockId: string;
        sentiments: SentimentLabelsType;
      }>,
    ) => {
      const { historyBlockId, sentiments } = action.payload;
      const index = state.transcripts.findLastIndex(
        (t) => t.id === historyBlockId,
      );

      if (index > -1) {
        state.transcripts[index].labels.sentiments = sentiments;
      }

      return state;
    },

    setFacts: (
      state,
      action: PayloadAction<{
        historyBlockId: string;
        factChecks: FactCheckLabelsType;
      }>,
    ) => {
      const { historyBlockId, factChecks } = action.payload;
      const index = state.transcripts.findLastIndex(
        (t) => t.id === historyBlockId,
      );

      if (index > -1) {
        state.transcripts[index].labels.factChecks = factChecks;
      }

      return state;
    },

    setVoiceMatch: (
      state,
      action: PayloadAction<LiveSessionStateType['voiceMatch']>,
    ) => {
      return {
        ...state,
        voiceMatch: {
          ...state.voiceMatch,
          ...action.payload,
        },
      };
    },
  },
});

export const onThreadIdListener = createListenerMiddleware();
onThreadIdListener.startListening({
  predicate: ({ type }) => type === liveSession.actions.setThreadId.type,
  effect: (_, { dispatch }) => {
    dispatch(loadThread());
  },
});

const loadThread = createAsyncThunk(
  'liveSession/loadThread',
  async (_, { getState, dispatch }) => {
    const {
      liveSession: { threadId },
    } = getState() as RootType;

    if (!threadId) {
      dispatch(liveSession.actions.setActive());
      return;
    }

    // TODO: do this with tanstack
    const { data } = await threads.getHistoryBlockById<LiveHistoryBlockType>({
      id: threadId!,
      size: '500', // TOdO: paginate
      order: 'ASC',
    });

    const { data: threadData } = await threads.getThreadById(threadId!);
    const startingTime = new Date(threadData.createdAt || null);
    const transcripts: LiveSessionStateType['transcripts'] = [];
    const feeds: LiveSessionStateType['feeds'] = [];

    for (const block of data) {
      const diff = new Date(block.createdAt).getTime() - startingTime.getTime();
      const minutes = Math.floor(diff / 60000) + '';
      const seconds = ((diff % 60000) / 1000).toFixed(0) + '';
      const time = {
        minutes: minutes.padStart(2, '0'),
        seconds: seconds.padStart(2, '0'),
      };

      const sentiments = block.match?.map(({ classification, sentiment }) => ({
        classification,
        sentiment,
      })) as SentimentLabelsType;

      let factChecks = null;

      if (block.fact?.length) {
        factChecks = [];
        for (const fact of block.fact) {
          factChecks.push({ id: fact.id, outcome: fact.outcome });
        }

        feeds.push({
          avatar: 'https://assets.knowz.com/assets/svg/fack-checker.svg', // TODO: check why this is not in history block
          name: 'factCheck',
          result: block.fact,
          index: block.id,
          time,
        });
      }

      transcripts.push({
        id: block.id,
        content: block.text,
        speaker: {
          reference: block.speaker.reference,
          friendlyName: friendlyNameManager.setFriendlyName(
            block.speaker.reference,
          ),
        },
        time,
        labels: { sentiments, factChecks },
      });
    }

    dispatch(liveSession.actions.setTranscripts(transcripts));
    dispatch(liveSession.actions.setFeeds(feeds));
    dispatch(liveSession.actions.setActive());
    dispatch(
      liveSession.actions.setVoiceMatch({
        ...threadData.live.speakers,
      }),
    );
  },
);

export const {
  setThreadId,
  addTranscript,
  addFeed,
  setFacts,
  setSentiments,
  setVoiceMatch,
} = liveSession.actions;

export default liveSession.reducer;
