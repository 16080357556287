import useAuth from '@hooks/useAuth';
import usePaymentWithStripe from '@hooks/usePaymentWithStripe';
import StyledStack from '@layouts/main/sections/toolbar/expanded/sections/credit-usage/purchased-ai-credit/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import ArrowRightIcon from '~icons/knowz-iconify/arrow-right';
import CreditActionButton from './action-button';
import CreditNotification from './credit-notification';

export default function PurchasedAICredit() {
  const { isManagingPaymentLoading, managePayments } = usePaymentWithStripe();
  const { me } = useAuth();

  return (
    <StyledStack gap={1}>
      <Stack direction="row">
        <CreditNotification />
        {me?.paid && (
          <ButtonBase
            disableRipple
            disableTouchRipple
            disabled={isManagingPaymentLoading}
            onClick={managePayments}
            sx={{
              color: isManagingPaymentLoading
                ? 'text.disabled'
                : 'text.primary',
            }}
          >
            <ArrowRightIcon fontSize="1.5em" />
          </ButtonBase>
        )}
      </Stack>
      <CreditActionButton />
    </StyledStack>
  );
}
