import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import PurchasedAICredit from '@layouts/main/sections/toolbar/expanded/sections/credit-usage/purchased-ai-credit';
import {
  StyledBox,
  StyledStack,
} from '@layouts/main/sections/toolbar/expanded/sections/credit-usage/styles';

export default function CreditUsage() {
  return (
    <StyledBox>
      <StyledStack
        direction="row"
        gap={2}
      >
        <CreditQuantity />
      </StyledStack>
      <PurchasedAICredit />
    </StyledBox>
  );
}
