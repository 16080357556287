import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetCredit from '@hooks/useGetCredit';
import StyledExpandedSlider from '@layouts/main/sections/toolbar/components/credit-quantity/styles';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SKELETON_WIDTH = 35;
const SKELETON_HEIGHT = 20;

export default function CreditQuantity() {
  const { creditsData, isCreditLoading } = useGetCredit();

  const { t } = useTranslation();

  return (
    <Stack
      sx={{ width: '100%' }}
      gap={4}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between' }}
      >
        <Text
          variant="textMd"
          weight="semibold"
        >
          {t('layout.toolbar.buyAICredit.credits', {
            defaultValue: 'Credits',
          })}
        </Text>
        {isCreditLoading || typeof creditsData === 'undefined' ? (
          <Skeleton
            variant="rectangular"
            width={SKELETON_WIDTH}
            height={SKELETON_HEIGHT}
          />
        ) : (
          <Text
            variant="textMd"
            weight="semibold"
          >
            {creditsData.remainingCredits.toLocaleString()}
          </Text>
        )}
      </Stack>
      <StyledExpandedSlider
        value={creditsData?.creditAsPercentage || 0}
        orientation="horizontal"
      />
    </Stack>
  );
}
