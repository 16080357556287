import { useTranslation } from '@desygner/ui-common-translation';
import { auth } from '@lib/agent';
import { LoginBodyType } from '@shared-types/auth';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const login = async (data: LoginBodyType) => await auth.login(data);

export default function useLogin() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: login,
    onSuccess: () => {
      toast.success(
        t('response.success.login', {
          defaultValue: 'You have successfully logged in.',
        }),
      );
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        toast.error(
          t('response.errors.code.401', {
            defaultValue: 'Invalid credentials. Please try again.',
          }),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
      console.error(error);
    },
  });
}
