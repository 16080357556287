import Box, { BoxProps } from '@mui/material/Box';
import { alpha } from '@mui/material';
import React from 'react';

type DirectiveLabelPropsType = {
  color: 'purple' | 'green';
};

export default function DirectiveLabel(
  props: BoxProps & DirectiveLabelPropsType,
) {
  const { color, sx, ...rest } = props;

  return (
    <Box
      {...rest}
      sx={{
        ...(sx || {}),
        color: (theme) => theme.palette.brand[color].main,
        border: (theme) =>
          `1px solid ${alpha(theme.palette.brand[color].main, 0.25)}`,
        padding: (theme) => theme.spacing(0.5, 2),
        borderRadius: (theme) => theme.spacing(2),
        background: (theme) => alpha(theme.palette.brand[color].main, 0.1),
      }}
    >
      {props.children}
    </Box>
  );
}
