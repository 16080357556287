import { useMemo } from 'react';

import { SxProps } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

import { StyledMarkdown, StyledText } from '@components/markdown/styles';
import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';

import useAppSelector from '@hooks/useAppSelector';
import { SourceLinksType } from '@shared-types/search-result/streaming';
import { Options as MarkdownOptions } from 'react-markdown';
import { useTranslation } from '@desygner/ui-common-translation';

type Props = React.PropsWithChildren<MarkdownOptions & { sx?: SxProps }> & {
  links?: Array<SourceLinksType>;
};

export default function Markdown({ links, children, sx, ...rest }: Props) {
  const { t } = useTranslation();

  const { handleSourcePreviewCardClick } = useSourcePreview();
  const streamingLinks = useAppSelector(
    (state) => state.searchResult.knowledgeLinks,
  );

  const filteredLinks = useMemo(
    () =>
      streamingLinks?.filter((link) => link?.kind !== 'customsearch#result'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) as Array<SourceLinksType>;

  const linksRef = filteredLinks ?? links;

  return (
    <StyledMarkdown
      sx={sx}
      components={{
        a(props) {
          const { children, node, href, ...rest } = props;
          const sourceWithSubId = href?.split('id=')[1];
          if (sourceWithSubId) {
            const sourceId = sourceWithSubId.split('-')[0];

            return (
              <ButtonBase
                onClick={() => {
                  handleSourcePreviewCardClick({
                    sourceId: sourceId,
                    sourceData: linksRef,
                    sourceWithSubId: sourceWithSubId,
                  });
                }}
              >
                <StyledText variant="textMd">
                  {t('page.search.section.knowledgeSource', {
                    defaultValue: 'Knowledge Base',
                  })}
                </StyledText>
              </ButtonBase>
            );
          }
          return (
            <a
              href={href}
              {...rest}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );
        },
      }}
      {...rest}
    >
      {children?.toString()}
    </StyledMarkdown>
  );
}
