import ActionButton from '@components/pages/knowledge-base/components/action-button';
import LinkIcon from '@mui/icons-material/Link';
import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';

type Props = {
  sourceId: number | undefined;
};

export default function SourcePreviewButton({ sourceId }: Props) {
  const { handleSourcePreviewCardClick } = useSourcePreview();
  return (
    <ActionButton
      aria-label="source preview"
      title="Source Preview"
      onClick={() => {
        handleSourcePreviewCardClick({ sourceId: JSON.stringify(sourceId) });
      }}
    >
      <LinkIcon fontSize="small" />
    </ActionButton>
  );
}
