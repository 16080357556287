import LAYOUT_CONFIG from '@layouts/main/config';
import { convertPxToRem } from '@lib/fonts';
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (props) => props !== 'calculatedWidth',
})<{ calculatedWidth: string }>(({ theme, calculatedWidth }) => ({
  backgroundColor: 'transparent',
  backdropFilter: 'blur(2px)',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: calculatedWidth,
    transition: 'transform 0.3s, width 0.3s!important', //? important to override the default transition, otherwise slide in animation will not work for the header onscroll
  },
  zIndex: theme.zIndex.drawer,
}));

export const StyledToolbar = styled(Toolbar)(() => ({
  height: convertPxToRem(LAYOUT_CONFIG.HEADER.HEIGHT),
  justifyContent: 'space-between',
  padding: '0!important',
})) as typeof Toolbar;

export const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  padding: theme.spacing(3),
}));
