import Fade from '@mui/material/Fade';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import { BreakpointValues } from '@shared-types/theme';
import StyledStack from '@components/modal/styles';

const PADDING = {
  xs: 2, //? 8px
  sm: 4, //? 16px
  md: 6, //? 24px
  lg: 8, //? 32px
  none: 0, //? 0px
};

type Props = React.PropsWithChildren<
  Omit<ModalProps, 'children'> & {
    width?:
      | number
      | string
      | Partial<{ [key in BreakpointValues]: number | string }>;
    height?:
      | number
      | string
      | Partial<{ [key in BreakpointValues]: number | string }>;
    paddingType?: 'sm' | 'md' | 'lg' | 'none';
  }
>;

export default function Modal({
  width,
  height,
  open,
  children,
  paddingType = 'none',
  sx,
  ...otherProps
}: Props) {
  return (
    <MuiModal
      open={open}
      disableEnforceFocus
      {...otherProps}
    >
      <Fade in={open}>
        <StyledStack
          direction="row"
          sx={{
            padding: PADDING[paddingType],
            width,
            height,
            ...sx,
          }}
        >
          {children}
        </StyledStack>
      </Fade>
    </MuiModal>
  );
}
