import Text from '@components/text';

import {
  StyledClickableAvatar,
  StyledIconMiniButton,
} from '@components/workspace/workspace-selector/triggers/styles';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import Tooltip from '@mui/material/Tooltip';
import { forwardRef } from 'react';
import WindowGradIcon from '~icons/knowz-iconify/window-grad';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Mini = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { workspace } = useWorkspace();
  const { me } = useAuth();

  return (
    <Tooltip
      title="Workspace Selection"
      placement="right"
      arrow
      slotProps={{
        tooltip: { sx: { marginInlineStart: '18px!important' } },
      }}
    >
      <StyledIconMiniButton
        ref={ref}
        {...props}
      >
        {workspace && (
          <StyledClickableAvatar src={workspace.profilePicture || ''}>
            <Text variant="textSm">{workspace.name[0].toUpperCase()}</Text>
          </StyledClickableAvatar>
        )}
        {!workspace && me && <WindowGradIcon />}
        {/* TODO: skeleton */}
      </StyledIconMiniButton>
    </Tooltip>
  );
});

Mini.displayName = 'Mini';

export default Mini;
