import protectRoutes from '@lib/protectRoutes';
import AssistantsPage from '@pages/assistants';
import AssistantsSessionPage from '@pages/assistants-session';
import CommandDesignerPage from '@pages/command-designer';
import CommandsPage from '@pages/commands';
import ConnectorsPage from '@pages/connectors';
import ErrorPage from '@pages/error';
import GetTheAppPage from '@pages/get-the-app';
import HistoryPage from '@pages/history';
import HomePage from '@pages/home';
import {
  AssistantsSessionOutlet,
  MainOutlet,
  FullScreenOutlet,
} from '@pages/IndexPage';
import KnowledgeBasePage from '@pages/knowledge-base';
import SearchPage from '@pages/search';
import LivePage from '@pages/live';
import LiveSessionPage from '@pages/live-session';
import SsoPage from '@pages/sso';
import SsoEditorPage from '@pages/sso-editor';
import UpgradeAccountPage from '@pages/upgrade-account';
import Users from '@pages/users';
import paths from '@router/paths';
import { json, LoaderFunctionArgs, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <FullScreenOutlet />,
    errorElement: <ErrorPage />,
    loader: protectRoutes,
    children: [
      {
        path: paths.editCommand.pathname,
        element: <CommandDesignerPage />,
      },
    ],
  },
  {
    path: '/',
    element: <MainOutlet />,
    errorElement: <ErrorPage />,
    loader: protectRoutes,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: paths.search.pathname,
        element: <SearchPage />,
        loader: async ({ request }: LoaderFunctionArgs) => {
          const url = new URL(request.url).searchParams.get('t');
          if (url == null) {
            return json({ error: 'thread id is required' }, { status: 404 });
          }

          return 'ok';
        },
      },
      {
        path: paths.live.pathname,
        element: <LivePage />,
      },
      {
        path: paths.liveSession.pathname,
        element: <LiveSessionPage />,
      },
      {
        path: paths.connectors.pathname,
        element: <ConnectorsPage />,
      },
      {
        path: paths.commands.pathname,
        element: <CommandsPage />,
      },
      {
        path: paths.history.pathname,
        element: <HistoryPage />,
      },
      {
        path: paths.assistants.pathname,
        element: <AssistantsPage />,
      },
      {
        path: paths.assistantsSession.pathname,
        element: <AssistantsSessionPage />,
      },
      {
        path: paths.sso.pathname,
        element: <SsoPage />,
      },
      {
        path: paths.editSso.pathname,
        element: <SsoEditorPage />,
      },
      {
        path: paths.users.pathname,
        element: <Users />,
        errorElement: <ErrorPage />,
      },
      {
        path: paths.getTheApp.pathname,
        element: <GetTheAppPage />,
      },
      {
        path: paths.knowledgeBase.pathname,
        element: <KnowledgeBasePage />,
      },
      {
        path: paths.upgradeAccount.pathname,
        element: <UpgradeAccountPage />,
      },
    ],
  },
  {
    path: paths.assistantsSession.pathname,
    element: <AssistantsSessionOutlet />,
    loader: protectRoutes,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <AssistantsSessionPage />,
      },
    ],
  },
];

export default routes;
