import Box from '@mui/material/Box';
import { useRef } from 'react';
import StyledMuiCircularProgress from './styles';

type Props = {
  value?: number;
};

const THICKNESS = 4.75;

const RAW_TRACK = (
  <StyledMuiCircularProgress
    variant="determinate"
    sx={{
      color: 'background.card.main',
    }}
    thickness={THICKNESS}
    value={100}
  />
);

export default function CircularProgress({ value }: Props) {
  const circularProgressRef = useRef<SVGGeometryElement | null>(null);

  if (!value) {
    return <StyledMuiCircularProgress variant="indeterminate" />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {RAW_TRACK}
      <svg
        width={0}
        height={0}
      >
        <defs>
          <linearGradient
            id="credit-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor="#36BFFA"
            />
            <stop
              offset="100%"
              stopColor="#0040C1"
            />
          </linearGradient>
        </defs>
      </svg>

      <StyledMuiCircularProgress
        variant="determinate"
        ref={circularProgressRef}
        value={value}
        thickness={THICKNESS}
        sx={{
          'svg circle': { stroke: 'url(#credit-gradient)' },
          strokeLinecap: 'round',
        }}
      />
    </Box>
  );
}
