import { useMemo } from 'react';
import Match from './modal/match';
import Rematch from './modal/rematch';
import Modal from '@components/modal';
import { VoiceMatchType } from '../../types';

type Props = {
  type: VoiceMatchType;
  open: boolean;
  onClose: VoidFunction;
  speakerRef: string;
  handleSetVoiceMatchType: (type: VoiceMatchType) => void;
};

export default function VoiceMatchSpeakerModal({
  type,
  onClose,
  open,
  speakerRef,
  handleSetVoiceMatchType,
}: Props) {
  const MODAL_CONTENT_TYPE: Record<
    Exclude<VoiceMatchType, null>,
    React.ReactNode
  > = useMemo(
    () => ({
      match: (
        <Match
          handleCloseModal={onClose}
          speakerRef={speakerRef}
        />
      ),
      rematch: (
        <Rematch
          handleCloseModal={onClose}
          speakerRef={speakerRef}
          handleSetVoiceMatchType={handleSetVoiceMatchType}
        />
      ),
    }),
    [handleSetVoiceMatchType, onClose, speakerRef],
  );

  if (type == null) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      paddingType="md"
      disableRestoreFocus
    >
      {MODAL_CONTENT_TYPE[type]}
    </Modal>
  );
}
