import EnterpriseButton from '@components/enterprise-button';
import StyledText from '@components/pages/home/styles';
import { FormType } from '@components/pages/search/types';
import {
  StyledCenteredContent,
  StyledGradientText,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import SearchForm from '@components/search-form';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import useCreateOneThread from '@hooks/useCreateOneThread';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import paths from '@router/paths';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const { t } = useTranslation();

  const navigateTo = useNavigate();

  const theme = useTheme();

  const { mutateAsync: createOneThreadAsync } = useCreateOneThread();

  async function onSubmit({ name }: FormType) {
    const { data } = await createOneThreadAsync({ type: 'search', name });
    navigateTo(`${paths.search.pathname}?t=${data.id}`, {
      state: { prompt: name, isUserRedirected: true },
    });
  }

  return (
    <StyledViewportHeightStack>
      <StyledCenteredContent gap={8}>
        <EnterpriseButton />
        <Stack gap={2.5}>
          <Text
            component="h1"
            variant="displayXl"
            weight="semibold"
            sx={{ textAlign: 'center' }}
          >
            <StyledGradientText component="span">
              {t('page.home.hero.title', {
                defaultValue: 'Unleash AI Insights',
              })}
            </StyledGradientText>
          </Text>
          <StyledText
            variant="textMd"
            align="center"
          >
            {t('page.home.hero.subtitle', {
              defaultValue: 'Search no more, Knowz better',
            })}
          </StyledText>
        </Stack>
        <SearchForm
          onSubmit={onSubmit}
          sx={{
            width: theme.spacing(139),
            minWidth: theme.spacing(10),
            [theme.breakpoints.down('md')]: {
              width: theme.spacing(100),
            },
            [theme.breakpoints.down('sm')]: {
              width: theme.spacing(80),
            },
          }}
        />
      </StyledCenteredContent>
    </StyledViewportHeightStack>
  );
}
