import LogoutButton from '@components/logout-button';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useWorkspace from '@hooks/useWorkspace';
import { signUp } from '@lib/auth/fingerprint';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { queryClient } from '@providers/ReactQueryProvider';
import paths from '@router/paths';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type TrimmedButtonProps = Omit<ButtonProps, 'label'>;

const DESKTOP_BUTTONS: Array<
  TrimmedButtonProps & {
    mappedLabel:
      | 'page.auth.mobile.buttons.primary'
      | 'page.auth.mobile.buttons.secondary';
    defaultLabel: 'Login' | 'Sign Up';
  }
> = [
  {
    mappedLabel: 'page.auth.mobile.buttons.secondary',
    defaultLabel: 'Login',
    variant: 'contained',
    size: 'medium',
    color: 'primary',
    id: 'login',
  },
  {
    mappedLabel: 'page.auth.mobile.buttons.primary',
    defaultLabel: 'Sign Up',
    variant: 'outlined',
    size: 'medium',
    color: 'primary',
    id: 'sign-up',
  },
];

export default function AuthButton() {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParamsState('action', '');
  const { me, handleUpdateMe } = useAuth();
  const { resetForm } = useUserAuth();
  const { resetWorkspaceToIndividualRole, setupMode } = useWorkspace();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigateTo = useNavigate();

  function openModal() {
    setSearchParams('secure-account');
  }

  async function handleLogout() {
    window.localStorage.removeItem('token');
    resetWorkspaceToIndividualRole();
    //TODO: temporary fix to redirect user to home page after logout if they are in setup mode
    if (setupMode !== null) {
      navigateTo(paths.home.pathname);
    }
    resetForm();
    toast.success(
      t('response.success.logout', {
        defaultValue: 'You have successfully logged out',
      }),
    );

    if (!executeRecaptcha) return;

    const recaptchaToken = await executeRecaptcha();

    const response = await signUp(recaptchaToken);

    queryClient.refetchQueries();

    if (response?.data.status === 'Ok') {
      window.localStorage.removeItem('fingerPrintId');
      handleUpdateMe(response.data.token);
    }
  }

  if (me && (me.auth_type === 'email' || me.auth_type === 'oauth2')) {
    return <LogoutButton handleLogout={handleLogout} />;
  }

  return (
    <Stack
      direction="row"
      gap={2}
    >
      {DESKTOP_BUTTONS.map(({ defaultLabel, mappedLabel, id, ...other }) => {
        return (
          <Button
            key={mappedLabel}
            {...other}
            onClick={openModal}
          >
            {t(mappedLabel, { defaultValue: defaultLabel })}
          </Button>
        );
      })}
    </Stack>
  );
}
