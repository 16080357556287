import useWorkspace from '@hooks/useWorkspace';
import { BlockStep } from '../extensions/block-step/blockStepExtension';
import { useConfirm } from 'material-ui-confirm';
import { useDialogs } from '@toolpad/core';
import PackagesDialog from '../dialogs/PackagesDialog';
import {
  useCreateOneCommandBlock,
  useSaveCommandBlockDefinition,
  useUpdateOneCommandBlock,
} from './useCommandBlocks';

export default function useStepsDefinition() {
  const confirm = useConfirm();
  const dialogs = useDialogs();
  const { workspace } = useWorkspace();
  const { mutateAsync: mutateCreateOneCommandBlockAsync } = useCreateOneCommandBlock();
  const { mutateAsync: mutateSaveCommandBlockDefinitionAsync } = useSaveCommandBlockDefinition();
  const { mutateAsync: mutateUpdateOneCommandBlockAsync } = useUpdateOneCommandBlock();

  async function handleSaveBlock(step: BlockStep) {
    const block = JSON.parse(JSON.stringify(step));

    delete block.id;
    block.isExpanded = false;

    if (block.blockId) {
      return await updateBlockDefinition(block);
    }

    return await createBlockDefinition(block);
  }

  async function createBlockDefinition(block: BlockStep) {
    const packages = await dialogs.open(PackagesDialog);
    if (!packages) return;

    const { data } = await mutateCreateOneCommandBlockAsync({
      name: block.name,
      description: block.description || '',
      icon: '', // TODO: add icon
      packages,
    });

    block.owner = workspace!.id; // TODO: support other uses (member or user blocks)
    block.blockId = data.id!;

    await mutateSaveCommandBlockDefinitionAsync(block);

    return block;
  }

  async function updateBlockDefinition(block: BlockStep) {
    try {
      await confirm({
        title: 'Update Block',
        description: 'You are about to update the block definition. ' +
          'The updated block will not affect the existing Commands, ' +
          'but only new usages of this block. ' +
          'Are you sure you want to proceed?',
      });
    } catch (_) {
      return;
    }

     await mutateUpdateOneCommandBlockAsync({
      id: block.blockId!,
      name: block.name,
      description: block.description || '',
      icon: '', // TODO: add icon
    });

    await mutateSaveCommandBlockDefinitionAsync(block);

    return block;
  }

  return {
    handleSaveBlock,
  };
}
