import {
  StyledRoot,
  StyledAnimatedBorder1,
  StyledAnimatedBorder2,
} from '@components/indicator-loading/styles';
import Logo from '@components/logo';
import { motion } from 'framer-motion';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

const LOGO_SIZE = 64;

const INDICATOR_DEFAULT_SIZE = 150;

type Props = Partial<{
  width: number;
  height: number;
  sx: SxProps;
  title: React.ReactNode;
}>;

export default function IndicatorLoading({
  height = INDICATOR_DEFAULT_SIZE,
  width = INDICATOR_DEFAULT_SIZE,
  sx,
  title,
}: Props) {
  return (
    <StyledRoot
      sx={sx}
      width={width}
      height={height}
    >
      <Box
        component={motion.div}
        animate={{
          scale: [0.8, 1, 1, 1, 1, 1, 0.8],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 1.2,
          repeat: Infinity,
          bounceStiffness: 40,
        }}
      >
        <Logo
          variant="mini"
          sx={{ width: LOGO_SIZE, height: LOGO_SIZE }}
        />
      </Box>

      <Box
        component={motion.div}
        animate={{ opacity: 1 }}
      />
      <StyledAnimatedBorder1
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
      />

      <StyledAnimatedBorder2
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
      />
      {title}
    </StyledRoot>
  );
}
