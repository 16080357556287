import { DirectiveDescriptor, useNestedEditorContext } from '@mdxeditor/editor';
import { useState } from 'react';
import DirectiveLabel from './DirectiveLabel';

export const contextRegex = /:context\[([^\]]+)\]{type="([^"]+)"}/g;

export const ContextDirectiveDescriptor: DirectiveDescriptor = {
  name: 'context',
  testNode: (node) => node.name === 'context',
  hasChildren: true,
  attributes: [],
  type: 'textDirective',
  Editor: ({ mdastNode }) => {
    const { parentEditor, lexicalNode } = useNestedEditorContext();
    const [initialValue] = useState(mdastNode.children[0]?.value);

    return (
      <DirectiveLabel
        component="span"
        color="purple"
        suppressContentEditableWarning={true}
        contentEditable="true"
        onInput={(e) => {
          const node = structuredClone(mdastNode);
          node.children[0].value = e.currentTarget.textContent;

          parentEditor.update(
            () => {
              lexicalNode.setMdastNode({ ...mdastNode, ...node } as any);
            },
            { discrete: true },
          );
        }}
      >
        {initialValue || '<missing context>'}
      </DirectiveLabel>
    );
  },
};
