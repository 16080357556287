import friendlyNameManager from '@components/pages/live-session/utils/getFriendlyName';
import useAppSelector from '@hooks/useAppSelector';
import useResponsive from '@hooks/useResponsive';
import useToggleState from '@hooks/useToggleState';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useState } from 'react';
import { VoiceMatchType } from '../types';
import MenuItem from './menu-item';
import VoiceMatchSpeakerModal from './voice-match';

type Props = {
  speaker: string;
  iconProps?: IconButtonProps;
};

export default function Dropdown({
  speaker,
  iconProps,
  children,
}: React.PropsWithChildren<Props>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const isItDesktop = useResponsive('up', 'md');
  const { state: isVoiceMatchModalOpen, toggle: toggleVoiceMatchModal } =
    useToggleState(false);
  const [voiceMatchType, setVoiceMatchType] = useState<VoiceMatchType>(null);

  const voiceMatch = useAppSelector((state) => state.liveSession.voiceMatch);

  function handleSetVoiceMatchType(type: VoiceMatchType) {
    setVoiceMatchType(type);
  }

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    const tryingToRematch = voiceMatch && voiceMatch[speaker]?.name;
    handleSetVoiceMatchType(tryingToRematch ? 'rematch' : 'match');
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <VoiceMatchSpeakerModal
        type={voiceMatchType}
        open={isVoiceMatchModalOpen}
        handleSetVoiceMatchType={handleSetVoiceMatchType}
        speakerRef={speaker}
        onClose={toggleVoiceMatchModal}
      />
      <IconButton
        size="small"
        onClick={handleClick}
        {...iconProps}
      >
        {children}
      </IconButton>
      {isItDesktop ? (
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          elevation={1}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          slotProps={{
            paper: {
              sx: {
                padding: 0,
                background: 'background.paper',
              },
            },
          }}
        >
          <MenuItem
            voiceMatch={{
              name: voiceMatch && voiceMatch[speaker]?.name,
              speaker: friendlyNameManager.getKnownNameBySpeakerId(speaker),
              toggleModal: toggleVoiceMatchModal,
            }}
          />
        </Menu>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={isOpen}
          onClose={handleClose}
          onOpen={handleClick}
        >
          <MenuItem
            voiceMatch={{
              name: voiceMatch && voiceMatch[speaker]?.name,
              speaker: friendlyNameManager.getKnownNameBySpeakerId(speaker),
              toggleModal: toggleVoiceMatchModal,
            }}
          />
        </SwipeableDrawer>
      )}
    </>
  );
}
