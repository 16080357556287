import { useTranslation } from '@desygner/ui-common-translation';
import { folders } from '@lib/agent';
import { FolderBodyType } from '@shared-types/folders';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

type QueryType = {
  onSuccess:
    | ((
        data: unknown,
        variables: Pick<FolderBodyType, 'name'>,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  onMutate?: (variables: Pick<FolderBodyType, 'name'>) => unknown;
  onSettled?:
    | ((
        data: unknown,
        error: Error | null,
        variables: Pick<FolderBodyType, 'name'>,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  id?: number | null;
};

export default function useMutateFoldersById({
  onSuccess,
  onMutate,
  id,
  onSettled,
}: QueryType) {
  const { t } = useTranslation();

  const {
    data: folderData,
    isError: isMutationFolderError,
    isPending: isMutationFolderPending,
    mutateAsync: mutateFoldersAsync,
  } = useMutation({
    mutationFn: async ({ name }: Pick<FolderBodyType, 'name'>) => {
      const { data } = await folders.createOne({
        name,
        parent: id,
      });

      return data;
    },
    onMutate,
    onError: (error) => {
      const data = isAxiosError(error) && error.response?.data;
      if (data.code === 400 && data.message.includes('already exists')) {
        toast.error(
          t('response.errors.createFolder.duplicate', {
            defaultValue: 'A folder with this name already exists.',
          }),
        );
        return;
      }
      console.error(`An error occurred while creating a folder: ${error}`);
    },
    onSuccess,
    onSettled,
  });

  return {
    folderData,
    isMutationFolderError,
    isMutationFolderPending,
    mutateFoldersAsync,
  };
}
