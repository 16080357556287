import useStartRecordingSession from '@components/pages/live/hooks/useStartRecordingSession';
import {
  StyledStack,
  StyledCard,
  StyledIcon,
  StyledMobileCard,
} from '@components/pages/live/sections/recorder/styles';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import { AudioWave } from '@components/pages/live-session/recorder/audio-wave';
import useResponsive from '@hooks/useResponsive';
import { Box } from '@mui/material';

export const MOBILE_HEIGHT = 120;

export default function Recorder() {
  const { t } = useTranslation();
  const isItDesktop = useResponsive('up', 'md');

  const { handleStartRecoding, isMutateLiveTokenPending } =
    useStartRecordingSession();

  if (isItDesktop) {
    return (
      <StyledCard
        component={Stack}
        direction="row"
        gap={4}
        alignItems="center"
      >
        <ButtonBase
          component={Stack}
          onClick={handleStartRecoding}
          disabled={isMutateLiveTokenPending}
          disableRipple
          disableTouchRipple
          sx={{ alignItems: 'flex-start' }}
        >
          <StyledStack
            direction="row"
            gap={2.5}
          >
            <StyledIcon />
            <Text variant="textMd">
              {t('page.live.recorder.button', {
                defaultValue: 'Start Now',
              })}
            </Text>

            {isMutateLiveTokenPending && <CircularProgress size={20} />}
          </StyledStack>
        </ButtonBase>

        <Stack
          direction="row"
          sx={{ flexGrow: 1 }}
        >
          <AudioWave />
        </Stack>
      </StyledCard>
    );
  }

  return (
    <StyledMobileCard
      component={Stack}
      gap={4}
      sx={{ height: MOBILE_HEIGHT }}
      justifyContent="space-between"
      className="mobile"
    >
      <ButtonBase
        component={Stack}
        onClick={handleStartRecoding}
        disabled={isMutateLiveTokenPending}
        disableRipple
        disableTouchRipple
        direction="row"
        gap={2.5}
        sx={{ justifyContent: 'space-between' }}
      >
        <Text
          variant="textSm"
          weight="semibold"
          alignItems="flex-start"
          sx={{ flex: '1 1 0' }}
        >
          {t('page.live.recorder.button', {
            defaultValue: 'Start Now',
          })}
        </Text>

        <Stack
          alignItems="center"
          sx={{ flex: '1 1 0' }}
        >
          <StyledIcon />
        </Stack>

        <Stack
          alignItems="flex-end"
          sx={{ flex: '1 1 0' }}
        >
          {isMutateLiveTokenPending && <CircularProgress size={20} />}
        </Stack>
      </ButtonBase>

      <AudioWave />
    </StyledMobileCard>
  );
}
