import Recorder from '@components/pages/live-session/recorder/desktop';
import Feed from '@components/pages/live-session/sections/content/components/feed';
import Transcript from '@components/pages/live-session/sections/content/components/transcript';
import Stack from '@mui/material/Stack';
import { StyledPaper } from '@components/pages/live/styles';

export default function Content() {
  return (
    <>
      <StyledPaper component={Stack}>
        <Transcript />
        <Feed />
      </StyledPaper>

      <Recorder />
    </>
  );
}
