import EllipsisText from '@components/ellipsis-typography';

import {
  StyledClickableAvatar,
  StyledListItem,
  StyledStack,
  StyledWorkspaceItemIcon,
} from '@components/workspace/workspace-selector/triggers/styles';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { forwardRef } from 'react';
import ChevSelectorVerticalIcon from '~icons/knowz-iconify/chev-selector-vertical';
import WindowGradIcon from '~icons/knowz-iconify/window-grad';

const SHARED_PROPS = {
  disablePadding: true,
  disableGutters: true,
};

const Default = forwardRef<HTMLDivElement>((props, ref) => {
  const { workspace } = useWorkspace();
  const { me } = useAuth();

  return (
    <StyledStack
      direction="row"
      spacing={1}
      ref={ref}
      {...props}
    >
      {workspace && (
        <StyledListItem {...SHARED_PROPS}>
          <ListItemButton>
            <Stack
              direction="row"
              gap={2}
              sx={{ alignItems: 'center', width: '100%' }}
            >
              <StyledClickableAvatar
                sx={{ pointerEvents: 'none' }}
                src={workspace.profilePicture || ''}
              >
                {workspace.name[0].toUpperCase()}
              </StyledClickableAvatar>

              <ListItemText
                primary={
                  <EllipsisText
                    variant="textMd"
                    lineClamp={1}
                    sx={{ wordBreak: 'break-word' }}
                    title={workspace.name}
                    component="span"
                  >
                    {workspace.name}
                  </EllipsisText>
                }
                primaryTypographyProps={{
                  variant: 'textMd',
                }}
              />
            </Stack>
            <ListItemIcon>
              <ChevSelectorVerticalIcon fontSize="1.1em" />
            </ListItemIcon>
          </ListItemButton>
        </StyledListItem>
      )}

      {!workspace && me && (
        <StyledListItem {...SHARED_PROPS}>
          <ListItemButton>
            <StyledWorkspaceItemIcon>
              <WindowGradIcon />
            </StyledWorkspaceItemIcon>
            <ListItemText
              primary="My Space"
              primaryTypographyProps={{
                variant: 'textMd',
              }}
            />
            <ListItemIcon>
              <ChevSelectorVerticalIcon
                fontSize="1.1em"
                color="#85888E" /* TODO: design system */
              />
            </ListItemIcon>
          </ListItemButton>
        </StyledListItem>
      )}

      {/* TODO: skeleton */}
    </StyledStack>
  );
});

Default.displayName = 'Default';

export default Default;
