import UpgradeModal from '@components/upgrade-modal';
import Layout from '@layouts/index';
import { Outlet } from 'react-router-dom';

export function MainOutlet() {
  return (
    <Layout variant="main">
      <UpgradeModal />
      <Outlet />
    </Layout>
  );
}

export function FullScreenOutlet() {
  return (
    <Layout variant="full-screen">
      <UpgradeModal />
      <Outlet />
    </Layout>
  );
}

export function AssistantsSessionOutlet() {
  return (
    <Layout variant="assistants-session">
      <Outlet />
    </Layout>
  );
}

export function KnowledgeBaseOutlet() {
  return (
    <Layout variant="knowledge-base">
      <Outlet />
    </Layout>
  );
}
