import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { convertPxToRem } from '@lib/fonts';
import { alpha, styled } from '@mui/material';

const StyledTextarea = styled(BaseTextareaAutosize)(({ theme }) => ({
  resize: 'none',
  width: '100%',
  padding: theme.spacing(2, 3),
  fontSize: theme.typography.textMd.fontSize,
  lineHeight: theme.typography.textMd.lineHeight,
  borderRadius: convertPxToRem(theme.shape.borderRadius * 2.5),
  color: theme.palette.text.primary,
  background: alpha(
    theme.palette.mode === 'light'
      ? theme.palette.common.black
      : theme.palette.common.white,
    0.05,
  ),

  border: `1px solid ${alpha(theme.palette.text.primary, 0.23)} !important`,

  '&:hover': {
    borderColor: `${theme.palette.neutral.border} !important`,
  },

  '&:focus': {
    borderColor: `${theme.palette.primary.main} !important`,
  },

  '&:focus-visible': {
    outline: 0,
  },
}));

export default StyledTextarea;
