import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import CreditUsage from '@layouts/main/sections/toolbar/expanded/sections/credit-usage';
import {
  StyledButton,
  StyledExpandedToolbar,
  StyledStack,
} from '@layouts/main/sections/toolbar/expanded/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledListStack } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import Socials from '@layouts/main/sections/toolbar/socials';
import { StyledStackWrapper } from '@layouts/styles';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import AlignLeftIcon from '~icons/knowz-iconify/align-left';
import ExpandedListItem from '../components/expanded-list-item';
import LegalLinks from '../components/legal-links';
import { StyledExpandedToolbarGridItem } from '../styles';

export default function ExpandedToolbar() {
  const {
    toolbarExpansionStatus,
    handleHideExpandedButton,
    handleDisplayExpandedButton,
    handleToggleToolbar,
  } = useToolbar();
  const isToolbarExpanded = toolbarExpansionStatus === 'on';

  return (
    <StyledExpandedToolbarGridItem
      component={Collapse}
      in={isToolbarExpanded}
      orientation="horizontal"
      onMouseEnter={handleDisplayExpandedButton}
      onMouseLeave={handleHideExpandedButton}
    >
      <StyledExpandedToolbar disableGutters>
        <StyledStack>
          <StyledListStack
            disablePadding
            component={Stack}
          >
            <StyledButton
              disableRipple
              disableTouchRipple
              onClick={handleToggleToolbar}
            >
              <AlignLeftIcon fontSize="0.8em" />
            </StyledButton>
            <ExpandedListItem />
          </StyledListStack>
          <StyledStackWrapper>
            <Stack gap={4}>
              <Socials />
              <LegalLinks />
            </Stack>
            <CreditUsage />
          </StyledStackWrapper>
        </StyledStack>
        <ToolbarExpansionButton />
      </StyledExpandedToolbar>
    </StyledExpandedToolbarGridItem>
  );
}
