import DataLoader from '@components/data-loader';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetCredit from '@hooks/useGetCredit';
import useResponsive from '@hooks/useResponsive';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import HelpRoundedIcon from '~icons/knowz-iconify/help-rounded';
import ArcProgressWithTooltip from './acr-progress-with-tooltip';
import Tooltip from './tooltip';

export default function CapacityIndicator() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { creditsData, isCreditLoading, isCreditError } = useGetCredit();
  const isItMobileOrTablet = useResponsive('down', 'md');

  return (
    <Stack
      direction="row"
      gap={4}
    >
      <Tooltip>
        <Stack
          direction="row"
          gap={1.5}
          sx={{
            alignItems: 'center',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Text
            variant="textSm"
            fontWeight={600}
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            {t('page.knowledgeBase.table.capacity', {
              defaultValue: 'Knowledge Capacity',
            })}
          </Text>
          <HelpRoundedIcon color={theme.palette.text.secondary} />
        </Stack>
      </Tooltip>
      <DataLoader
        loading={{
          isLoading: isCreditLoading,
          description: 'Loading knowledge capacity ...',
        }}
        error={{
          isError: isCreditError,
          title: t('response.errors.code.520', {
            defaultValue: 'Unknown error. Please try again later.',
          }),
        }}
      >
        {creditsData && (
          <ArcProgressWithTooltip
            withTooltip={isItMobileOrTablet}
            variant="determinate"
            value={Math.round(creditsData.knowledgeCapacityPercentage)}
          />
        )}
      </DataLoader>
    </Stack>
  );
}
