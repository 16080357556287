import Text from '@components/text';
import { useRadioGroup } from '@mui/material';
import Radio from '@mui/material/Radio';
import { StyledInnerStack, StyledWrapperStack } from './styles';

export default function RadioGroupContainer({
  children,
  value,
  title,
}: React.PropsWithChildren<{
  value: string;
  title: string;
}>) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === value;
  }

  return (
    <StyledWrapperStack
      checked={checked}
      gap={4}
    >
      <StyledInnerStack
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        direction="row"
      >
        <Text
          variant="textMd"
          weight="semibold"
        >
          {title}
        </Text>
        <Radio
          size="small"
          value={value}
        />
      </StyledInnerStack>
      {children}
    </StyledWrapperStack>
  );
}
