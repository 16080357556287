import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import { StyledStack } from '@components/pages/commands/styles';
import SsoListItem from '@components/pages/sso/components/SsoListItem';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import {
  useCreateOneAccessConfiguration,
  useDeleteOneAccessConfiguration,
  useGetAllAccessConfigurations,
} from '@hooks/useAccessConfigurations';
import useResponsive from '@hooks/useResponsive';
import LockOpen from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import paths from '@router/paths';
import { AccessConfigurationType } from '@shared-types/sso';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

export default function SsoConfigurations() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [accessConfigurationForDeletion, setAccessConfigurationForDeletion] =
    useState<AccessConfigurationType | null>(null);

  const { mutateAsync: mutateCreateOneAccessConfigurationAsync } =
    useCreateOneAccessConfiguration();

  const { mutateAsync: mutateDeleteOneAccessConfigurationAsync } =
    useDeleteOneAccessConfiguration();

  const { accessConfigurationsData, isLoadingAccessConfigurations } =
    useGetAllAccessConfigurations();

  const isItMobileOrTablet = useResponsive('down', 'md');
  const data = isLoadingAccessConfigurations
    ? []
    : accessConfigurationsData?.data || [];

  const [filter, setFilter] = useState('');
  const confirm = useConfirm();

  const onRemoveAccessConfigurationHandler = useCallback(() => {
    accessConfigurationForDeletion &&
      mutateDeleteOneAccessConfigurationAsync(
        accessConfigurationForDeletion.id!,
      );
    setAccessConfigurationForDeletion(null);
  }, [accessConfigurationForDeletion, mutateDeleteOneAccessConfigurationAsync]);

  useEffect(() => {
    if (!accessConfigurationForDeletion) return;

    (function () {
      confirm({
        title: t('page.sso.deleteConfirmation.title', {
          defaultValue: 'Delete Configuration',
        }),
        description: t('page.sso.deleteConfirmation.content', {
          defaultValue:
            'Are you sure you want to delete this single sign on configuration?',
        }),
        confirmationText: t('page.sso.deleteConfirmation.yes', {
          defaultValue: 'Yes, please',
        }),
        cancellationText: t('page.sso.deleteConfirmation.no', {
          defaultValue: "No, I'm good",
        }),
      })
        .then(onRemoveAccessConfigurationHandler)
        .catch((err) => {
          setAccessConfigurationForDeletion(null);
          console.error(err);
        });
    })();
  }, [
    t,
    confirm,
    onRemoveAccessConfigurationHandler,
    accessConfigurationForDeletion,
  ]);

  async function onNewAccessConfigurationHandler() {
    const { data } = await mutateCreateOneAccessConfigurationAsync({
      name: 'SSO Configuration',
      alias: '',
      isRootDefault: true,
      isSample: false,
      config: {
        clientId: '',
        clientSecret: '',
        authorizationUrl: '',
        accessTokenUrl: '',
        infosUrl: '',
      },
    });

    onOpenAccessConfigurationHandler(data);
  }

  function onOpenAccessConfigurationHandler({ id }: AccessConfigurationType) {
    navigate(generatePath(paths.editSso.pathname, { id }));
  }

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/svg/waves.svg"
        alt="Single Sign On"
      />

      <Text
        component="h1"
        variant="displaySm"
      >
        {t('layout.page.sso.hero.title', {
          defaultValue: 'Configure Single Sign On',
        })}
      </Text>

      <Button
        startIcon={<LockOpen />}
        variant="contained"
        size={isItMobileOrTablet ? 'medium' : 'large'}
        onClick={onNewAccessConfigurationHandler}
      >
        Add Single Sign On Configuration
      </Button>

      {!!data.length && (
        <StyledStack gap={5}>
          <TextField
            label={t('page.sso.search.title', {
              defaultValue: 'Find Single Sign On Configuration',
            })}
            onChange={(event) =>
              setFilter(event.target.value.toLocaleLowerCase())
            }
            variant="outlined"
          />

          <ListboxComponent>
            {data
              ?.filter(
                (item) =>
                  !filter ||
                  item.name.toLocaleLowerCase().includes(filter) ||
                  item.alias.toLocaleLowerCase().includes(filter),
              )
              .map((item) => {
                return (
                  <SsoListItem
                    key={`access-configuration-${item.id}`}
                    accessConfiguration={item}
                    onOpen={() => onOpenAccessConfigurationHandler(item)}
                    onRemove={() => setAccessConfigurationForDeletion(item)}
                  />
                );
              })}
          </ListboxComponent>
        </StyledStack>
      )}
    </>
  );
}
