import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to right, #53B1ED2B, #3C1AF92B)', //TODO: design system
  borderRadius: theme.shape.borderRadius * 3,
  width: '100%',
  overflow: 'hidden',
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
}));
