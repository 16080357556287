import { storage } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

type CredentialType = {
  key?: string;
};

export default function useGetCredentialByKeyLazily({ key }: CredentialType) {
  const {
    data: credentialData,
    isLoading: isCredentialDataLoading,
    isError: isCredentialDataError,
  } = useQuery({
    queryKey: ['credentials', key],
    queryFn: async () => {
      return await storage.getCredentials(key!);
    },
    enabled: !!key,
    staleTime: Infinity,
  });

  return {
    credentialData,
    isCredentialDataLoading,
    isCredentialDataError,
  };
}
