import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import getOrdinal from '@lib/getOrdinalNumber';
import Skeleton from '@mui/material/Skeleton';
import { getDay } from 'date-fns';
import { useCallback } from 'react';

const SKELETON_WIDTH = '100%';
const SKELETON_HEIGHT = 20;

export default function CreditNotification() {
  const { me, isUserAuthenticated } = useAuth();
  const { t } = useTranslation();

  const calculateUserAccountSubscription = useCallback(() => {
    if (me == null) return null;

    if (me.subscription) {
      switch (me.subscription.interval) {
        case 'month':
        case 'year': {
          return (
            t('layout.toolbar.buyAICredit.type.plan.segment1', {
              defaultValue: 'Credits refill on the ',
            }) +
            getDay(me.subscription.next_update) +
            getOrdinal(getDay(me.subscription.next_update)) +
            t('layout.toolbar.buyAICredit.type.plan.segment2', {
              defaultValue:
                ' of each month. Top up at anytime with a credit pack',
            })
          );
        }
        default:
          console.error('could not find plan');
      }
    }

    if (me.paid) {
      return t('layout.toolbar.buyAICredit.type.creditPack', {
        defaultValue:
          'Top up anytime with a credit pack or upgrade to a plan for the best value.',
      });
    }

    if (me.auth_type === 'web_fingerprint') {
      return t('layout.toolbar.buyAICredit.type.notLoggedIn', {
        defaultValue:
          'Top up anytime with a credit pack or pick a plan for the best value.',
      });
    }

    return t('layout.toolbar.buyAICredit.type.freeAccount', {
      defaultValue:
        'Buy a credit pack or subscribe to make the most out of Knowz.',
    });
  }, [me, t]);

  if (!isUserAuthenticated || !calculateUserAccountSubscription) {
    return Array.from({ length: 2 }).map((_, index) => (
      <Skeleton
        key={index}
        width={SKELETON_WIDTH}
        height={SKELETON_HEIGHT}
      />
    ));
  }

  return (
    <Text
      variant="textMd"
      color="text.secondary"
    >
      {calculateUserAccountSubscription()}
    </Text>
  );
}
