//TODO: Implement Selector later
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';

// const MenuProps: SelectProps['MenuProps'] = {
//   MenuListProps: {
//     disablePadding: true,
//   },
//   PaperProps: {
//     sx: {
//       p: 0,
//       backgroundColor: 'background.card.light',
//     },
//     style: {
//       minWidth: 250,
//     },
//   },
// };

export default function Selector() {
  const { t } = useTranslation();

  // const selectedAssistants = useAppSelector(
  //   (state) => state.live.selectedAssistants,
  // );

  // const dispatch = useAppDispatch();

  // const handleChange = (event: SelectChangeEvent<AssistantsKindType[]>) => {
  //   const modes = {} as SelectedAssistantsType;
  //   const value = event.target.value;
  //   if (!Array.isArray(value)) return null;

  //   value.forEach((mode) => {
  //     modes[mode] = !modes[mode];
  //   });

  //   dispatch(
  //     selectedAssistantsUpdated({
  //       ...modes,
  //     }),
  //   );
  // };

  // const value = useMemo(
  //   () =>
  //     Object.entries(selectedAssistants)
  //       .filter(Boolean)
  //       .map(([key, _value]) => key) as AssistantsKindType[],
  //   [selectedAssistants],
  // );

  return (
    <>
      <Text
        variant="textLg"
        weight="semibold"
      >
        {t('page.liveSession.feed.title', {
          defaultValue: 'Feed',
        })}
      </Text>
      {/* <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={value}
        size="small"
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          sx: { backgroundColor: 'background.default' },
        }}
        renderValue={(selected) => (
          <Box>
            {selected.length === CONFIG.ASSISTANTS.length ? (
              <Text variant="textMd">General</Text>
            ) : selected.length > 0 &&
              selected.length < CONFIG.ASSISTANTS.length ? (
              <Text variant="textMd">Custom</Text>
            ) : (
              <Text variant="textMd">No Modes</Text>
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {CONFIG.ASSISTANTS.map(({ fieldName, label, image }) => (
          <MenuItem
            disableRipple
            disableTouchRipple
            disableGutters
            key={label}
            value={fieldName}
          >
            <Checkbox
              icon={<StyledCheckboxIcon />}
              checkedIcon={<StyledCheckbox />}
              checked={fieldName in selectedAssistants}
            />
            <Stack direction="row" gap={2.5}>
              <img src={image} alt="sad" width={24} height={24} />
              {label}
            </Stack>
          </MenuItem>
        ))}
      </Select> */}
    </>
  );
}
