import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { AxiosHeaders } from 'axios';

function getCreationOwnerHeader() {
  const methodHeaders = [
    axiosApiInstance.defaults.headers.post,
    axiosApiInstance.defaults.headers.put,
    axiosApiInstance.defaults.headers.patch,
    axiosApiInstance.defaults.headers.delete,
  ] as AxiosHeaders[];

  const creationOwners = methodHeaders.map<string | null | undefined>(
    (headers) => headers['Knowz-Creation-Owner'] || null,
  );

  //? Find the first non-null value, since all headers should have the same value
  const creationOwner = creationOwners.find((owner) => owner !== null);

  return creationOwner || '';
}

export default getCreationOwnerHeader;
