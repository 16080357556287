import { styled, alpha, ListItemProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { NavLinkProps, To } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import CONFIG from '@components/workspace/config';
import { convertPxToRem } from '@lib/fonts';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';

export const StyledClickableAvatar = styled(Avatar)(({ theme }) => ({
  width: convertPxToRem(26),
  height: convertPxToRem(26),
  cursor: 'pointer',
  color: theme.palette.primary.contrastText,
  fontSize: theme.typography.textSm.fontSize,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },

  '& span': {
    marginTop: theme.spacing(1),
  },
}));

export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
  paddingBottom: 0,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 100,
  padding: 0,
})) as React.ComponentType<
  ListItemProps & Omit<NavLinkProps, 'to'> & { to?: To }
>;

export const StyledIconMiniButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.main,
  border: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(1.5),
  marginBlockEnd: theme.spacing(3),
}));

export const StyledWorkspaceItemIcon = styled(ListItemIcon)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.main,
  border: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 100,
  marginInlineEnd: theme.spacing(2),
  width: CONFIG.AVATAR_SIZE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
