/* eslint-disable import/prefer-default-export */
import { SequentialStep, StepExtension } from 'sequential-workflow-designer';
import { createBlockStepComponentViewFactory } from './blockStepComponentView';
import { BlockStepExtensionConfiguration } from './blockStepExtensionConfiguration';
import { PropertyType } from '@lib/step/types';

// Combining the defaults of task and container steps
const defaultConfiguration: BlockStepExtensionConfiguration = {
  view: {
    paddingTop: 20,
    paddingX: 20,
    // inputSize: 18,
    inputRadius: 4,
    inputIconSize: 14,
    autoHideInputOnDrag: true,
    label: {
      height: 22,
      paddingX: 10,
      minWidth: 50,
      radius: 10
    },
    paddingLeft: 12,
    paddingRight: 12,
    paddingY: 10,
    textMarginLeft: 12,
    minTextWidth: 70,
    iconSize: 22,
    radius: 5,
    inputSize: 14,
    outputSize: 10
  }
};

export interface BlockStep extends SequentialStep {
  isExpanded: boolean;
  owner: string | null;
  blockId: number | null;
  description: string | null;
  propertyTypes: PropertyType[];
}

export class BlockStepExtension implements StepExtension<BlockStep> {
  public static create(configuration?: BlockStepExtensionConfiguration): BlockStepExtension {
    return new BlockStepExtension(configuration ?? defaultConfiguration);
  }

  public readonly componentType = 'block';

  private constructor(private readonly configuration: BlockStepExtensionConfiguration) {}

  public readonly createComponentView = createBlockStepComponentViewFactory(this.configuration.view);
}
