import Logo from '@components/logo';
import StyledStack from '@components/user-authentication/sections/modal-stepper/mobile/styles';
import ModalWrapper from '@components/user-authentication/sections/modal-stepper/ModalWrapper';
import Stack from '@mui/material/Stack';

export default function ModalStepper({ children }: React.PropsWithChildren) {
  return (
    <ModalWrapper
      width="100%"
      height="100%"
    >
      <StyledStack direction="row">
        <Stack sx={{ p: 4, width: '100%' }}>
          <Logo />
          {children}
        </Stack>
      </StyledStack>
    </ModalWrapper>
  );
}
