import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  marginBlockEnd: theme.spacing(2.5),
}));

export const StyledMuiLink = styled(Link)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.disabled,
})) as typeof Link;
