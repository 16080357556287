import LiveKitAudioTranscriber from '@components/pages/live-session/sections/content/components/transcript/livekit-audio-transcriber';
import ContentLayout from '@components/pages/live-session/sections/content/content-layout';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import useRoomMetadata from '@components/pages/live-session/hooks/useRoomMetadata';
import Card from '@components/pages/live-session/sections/content/components/transcript/card';
import useAppSelector from '@hooks/useAppSelector';
import HugeScrollableList from '../huge-scrollable-list';
import Box from '@mui/material/Box';
import ResumeButton from './components/resume-button';

const HEIGHT_OF_TRANSCRIPT_HEADER = 59;

export default function Transcript() {
  const { t } = useTranslation();
  const transcripts = useAppSelector((state) => state.liveSession.transcripts);
  const voiceMatch = useAppSelector((state) => state.liveSession.voiceMatch);
  const { error: roomError, roomStatus } = useRoomMetadata();
  const showTranscript = roomStatus === 'active';
  const ongoingTranscriptMargin = showTranscript ? 1 : 0;

  return (
    <ContentLayout
      headerSlot={
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Text
            variant="textLg"
            weight="semibold"
          >
            {t('page.liveSession.transcript', { defaultValue: 'Transcript' })}
          </Text>
          <ResumeButton />

          {roomStatus === 'error' && (
            <Alert
              severity="error"
              sx={{ backgroundColor: 'transparent' }}
            >
              {roomError || 'Room closed with error'}
            </Alert>
          )}

          {roomStatus === 'closed' && (
            <Alert
              severity="warning"
              sx={{ backgroundColor: 'transparent' }}
            >
              Closed Room
            </Alert>
          )}
        </Stack>
      }
    >
      {roomStatus === 'loading' && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: `calc(100% - ${HEIGHT_OF_TRANSCRIPT_HEADER}px)` }}
        >
          <LinearProgress sx={{ width: '60%' }} />
        </Stack>
      )}

      <HugeScrollableList
        sx={{
          maxHeight: `calc(100% - ${HEIGHT_OF_TRANSCRIPT_HEADER}px)`,
          px: 4,
        }}
        gap={3}
        totalItems={transcripts.length + ongoingTranscriptMargin}
        minBlockHight={70}
        renderItem={({ index }) => {
          if (showTranscript && index === 0) {
            return <LiveKitAudioTranscriber key="transcriber" />;
          }

          const transcript =
            transcripts[
              transcripts.length - index + ongoingTranscriptMargin - 1
            ];

          if (!transcript) return <Box />;

          const calculatedName =
            (voiceMatch && voiceMatch[transcript.speaker.reference]?.name) ??
            transcript.speaker.friendlyName;
          return (
            <Card
              key={transcript.id}
              speaker={{
                name: calculatedName,
                reference: transcript.speaker.reference,
              }}
              content={transcript.content!}
              labels={transcript.labels}
              time={transcript.time}
              id={transcript.id}
            />
          );
        }}
      />
    </ContentLayout>
  );
}
