import SimpleDropdown from '@components/dropdown/simple';
import EllipsisText from '@components/ellipsis-typography';
import { StyledListItem } from '@components/invitation-form/components/listitem/styles';
import Text from '@components/text';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

const SHARED_PROPS: {
  variant: React.ComponentProps<typeof Text>['variant'];
  color: React.ComponentProps<typeof Text>['color'];
} & { lineClamp: number } = {
  lineClamp: 1,
  variant: 'textMd',
  color: 'text.secondary',
};

export default function Listitem() {
  const theme = useTheme();

  const { membersToInvite, handleUpdateMember, handleDeleteMember } =
    useWorkspaceSetup();

  return (
    <>
      {!!membersToInvite.length && <Divider />}
      <List
        disablePadding
        sx={{ maxHeight: 150, overflowY: 'auto' }}
      >
        {membersToInvite.map((member) => {
          return (
            <StyledListItem
              key={member.email}
              disablePadding
              sx={{
                mb: 2,
              }}
            >
              <Stack
                direction="row"
                gap={2}
              >
                <EllipsisText
                  title={member.email}
                  {...SHARED_PROPS}
                  sx={{
                    wordBreak: 'break-word',
                    flex: 1,
                  }}
                >
                  {member.email}
                </EllipsisText>
              </Stack>
              <SimpleDropdown
                sx={{
                  popperSx: {
                    zIndex: theme.zIndex.tooltip,
                  },
                }}
                trigger={{
                  element: (
                    <Button
                      color="inherit"
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      endIcon={<ExpandMoreIcon />}
                    >
                      {member.isAdmin ? 'admin' : 'member'}
                    </Button>
                  ),
                }}
                placement="right-start"
                options={[
                  {
                    text: 'member',
                    value: member.email,
                    onClick: () =>
                      handleUpdateMember({ ...member, isAdmin: false }),
                    icon: {
                      iconStart: (
                        <CheckIcon
                          sx={{
                            visibility: member.isAdmin ? 'hidden' : 'visible',
                          }}
                        />
                      ),
                    },
                  },
                  {
                    text: 'admin',
                    value: member.email,
                    onClick: () =>
                      handleUpdateMember({ ...member, isAdmin: true }),
                    icon: {
                      iconStart: (
                        <CheckIcon
                          sx={{
                            visibility: member.isAdmin ? 'visible' : 'hidden',
                          }}
                        />
                      ),
                    },
                  },
                  {
                    text: 'remove',
                    value: member.email,
                    onClick: () => handleDeleteMember(member.email),
                    icon: {
                      iconStart: <DeleteOutlineIcon />,
                    },
                  },
                ]}
              />
            </StyledListItem>
          );
        })}
      </List>
    </>
  );
}
