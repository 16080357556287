import LoadingScreen from '@components/indicator-loading';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import {
  StyledWrapper,
  StyledCharsTypography,
} from '@components/pages/search/components/smart-loading/styles';
import useScrollIntoView from '@hooks/useScrollIntoView';
import { useRef } from 'react';

const DELAY_BETWEEN_EACH_CHARACTER = 0.01;
const DELAY_BEFORE_STARTING_ANIMATION = 0.2;
const LOGO_SIZE = 40;

export default function SmartLoading() {
  const smartLoading = useSelector(
    (state: RootType) => state.websocket.WSResponse.searchResult.smartLoading,
  );

  const wrapperRef = useRef(null);

  useScrollIntoView(wrapperRef, []);

  const characters = smartLoading.response?.split('');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: DELAY_BETWEEN_EACH_CHARACTER,
        delayChildren: DELAY_BEFORE_STARTING_ANIMATION,
      },
    },
  };

  const characterVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <StyledWrapper ref={wrapperRef}>
      <LoadingScreen
        sx={{
          backgroundColor: 'transparent',
          height: 120,
        }}
        width={LOGO_SIZE}
        height={LOGO_SIZE}
      />
      <StyledCharsTypography
        variant="textSm"
        component={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        key={smartLoading.response}
      >
        {characters?.map((char, index) => (
          <motion.span
            key={index}
            variants={characterVariants}
          >
            {char}
          </motion.span>
        ))}
      </StyledCharsTypography>
    </StyledWrapper>
  );
}
