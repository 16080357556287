import { CircularProgress, styled } from '@mui/material';

const StyledMuiCircularProgress = styled(CircularProgress)(() => ({
  width: '56px!important',
  height: '56px!important',
  position: 'absolute',
  left: 0,
  top: 0,
  transform: 'rotate(-90deg)',
}));

export default StyledMuiCircularProgress;
