import useAuth from '@hooks/useAuth';
import { memberships } from '@lib/agent';
import useQueryAfterAuthenticationLoaded from './useQueryAfterAuthenticationLoaded';

type getMembershipType = {
  id?: string;
};

export default function useGetMembershipByWorkspaceIdLazily({
  id,
}: getMembershipType) {
  const { me } = useAuth();
  const { data: membershipData } = useQueryAfterAuthenticationLoaded({
    queryKey: [id, me?.uuid],
    queryFn: async () => {
      if (!id) return null;

      const { data } = await memberships.getAllMine({
        workspaceId: id,
      });

      return data[0];
    },
    options: {
      enabled: !!id,
    },
  });

  return { membershipData };
}
