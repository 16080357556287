import Details from '@components/pages/live-session/sections/content/components/feed/list-item/details';
import Summary from '@components/pages/live-session/sections/content/components/feed/list-item/summary';
import { FactCheckResultType, TimeType } from '@shared-types/live';
import { forwardRef, useState } from 'react';
import { StyledAccordion } from '@components/pages/live-session/sections/content/components/feed/styles';

type Props = Omit<FactCheckResultType[number], 'id'> & {
  avatar: string;
  name: string;
  time: TimeType;
};

const Accordion = forwardRef<HTMLDivElement, Props>(
  ({ body, outcome, avatar, time, name, sources }, ref) => {
    const [isExpandedLocal, setIsExpandedLocal] = useState(true);

    return (
      <StyledAccordion
        disableGutters
        expanded={isExpandedLocal}
        onChange={(_, isExpanded) => setIsExpandedLocal(isExpanded)}
      >
        <Summary
          time={time}
          outcome={outcome}
          avatar={avatar}
          name={name}
        />
        <Details reasoning={body} />
      </StyledAccordion>
    );
  },
);

Accordion.displayName = 'Accordion';

export default Accordion;
