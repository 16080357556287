import Stack from '@mui/material/Stack';
import HelpRoundedIcon from '~icons/knowz-iconify/help-rounded';
import Tooltip from '../tooltip';
import { ArcProgressWithTooltipType } from '../types';
import ArcProgress from './arc-progress';
import { useTheme } from '@mui/material';

export default function ArcProgressWithTooltip({
  withTooltip,
  ...otherProps
}: ArcProgressWithTooltipType) {
  const theme = useTheme();

  if (withTooltip) {
    return (
      <Tooltip>
        <Stack direction="row">
          <ArcProgress {...otherProps} />
          <HelpRoundedIcon color={theme.palette.text.secondary} />
        </Stack>
      </Tooltip>
    );
  }

  return <ArcProgress {...otherProps} />;
}
