import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

type Props = {
  loading: {
    description: string;
    isLoading: boolean;
  };
  error: {
    title: string;
    isError: boolean;
  };
  data?: {
    emptyMessage?: string;
    length?: number;
  };
  children: React.ReactNode;
};

export default function DataLoader({ loading, error, data, children }: Props) {
  if (loading?.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress
          aria-describedby={loading.description}
          aria-busy={true}
        />
      </Box>
    );
  }

  if (error?.isError) {
    return <Alert severity="error">{error.title}</Alert>;
  }

  if (data?.length === 0) {
    return <Alert severity="info">{data.emptyMessage}</Alert>;
  }

  return children;
}
