import FormProvider from '@components/react-hook-form/FormProvider';
import Text from '@components/text';
import FormHeading from '@components/user-authentication/components/form-heading';
import useResetPassword from '@components/user-authentication/hooks/useResetPassword';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { resetPasswordSchema } from '@components/user-authentication/schemas';
import PasswordField from '@components/user-authentication/sections/steps/components/password-field';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';

const defaultValues = {
  password: '',
  confirmPassword: '',
};

type ResetPasswordFormType = {
  password: string;
  confirmPassword: string;
};

export default function ResetPassword() {
  const { t } = useTranslation();
  const { email, handleCustomStep, resetToken } = useUserAuth();
  const {
    mutateAsync: mutateAsyncResetPassword,
    isPending: isResetPending,
    isError: isResetError,
  } = useResetPassword();

  const methods = useForm<ResetPasswordFormType>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues,
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const isBusy = isResetPending || isSubmitting;

  async function onSubmit(data: ResetPasswordFormType) {
    await mutateAsyncResetPassword({
      auth_id: email,
      token: resetToken,
      plain_password: {
        first: data.password,
        second: data.confirmPassword,
      },
    });
    if (isResetError) return;
    handleCustomStep(0);
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.resetPassword.label')}
        withBackButton
      />
      <Stack gap={5}>
        <Text variant="textSm">
          {t(
            'layout.auth.desktop.modal.steps.resetPassword.form.password.label',
            {
              defaultValue: 'Enter your new password',
            },
          )}
        </Text>
        <PasswordField
          placeholder={t(
            'layout.auth.desktop.modal.steps.resetPassword.form.password.placeholder',
          )}
          name="password"
          disabled={isBusy}
          helperText={errors.password?.message}
        />
        <PasswordField
          placeholder={t(
            'layout.auth.desktop.modal.steps.resetPassword.form.confirmPassword.placeholder',
            { defaultValue: 'Confirm new password' },
          )}
          name="confirmPassword"
          disabled={isBusy}
          helperText={errors.confirmPassword?.message}
        />
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          disabled={!isDirty || isBusy}
          loading={isBusy}
        >
          {t('layout.auth.desktop.modal.steps.resetPassword.form.button', {
            defaultValue: 'Reset Password',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
