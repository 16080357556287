import Text from '@components/text';
import KeyFeatures from '@components/upgrade-modal/components/card/key-features';
import PopularBadge from '@components/upgrade-modal/components/card/popular-badge';
import {
  StyledStack,
  StyledStackWrapper,
} from '@components/upgrade-modal/components/card/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';

type Props = {
  title: string;
  subtitle: string;
  priceInfo: {
    priceLine: string;
    creditLine: string;
  };
  keyFeatures: string[];
  button: {
    text: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isLoading: boolean;
  };
  isMostPopular?: boolean;
  switcher?: React.ReactNode;
  sx?: SxProps;
};

export default function Card({
  priceInfo,
  title,
  subtitle,
  keyFeatures,
  button,
  isMostPopular,
  switcher,
  sx,
}: Props) {
  const { t } = useTranslation();

  return (
    <StyledStackWrapper isMostPopular={isMostPopular}>
      {isMostPopular && <PopularBadge switcher={switcher} />}
      <StyledStack
        sx={sx}
        gap={3}
      >
        <Stack
          gap={3}
          sx={{
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Text variant="displaySm">{title}</Text>
          <Text
            variant="textMd"
            color="text.secondary"
          >
            {subtitle}
          </Text>
        </Stack>
        <KeyFeatures keys={keyFeatures} />
        <Stack
          gap={4}
          sx={{ textAlign: 'center' }}
        >
          <Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: 'center' }}
              gap={1}
            >
              <Text variant="textMd">
                {t('layout.upgrade.plans.currency', {
                  defaultValue: 'US',
                })}
              </Text>
              <Text variant="displayLg">{priceInfo.priceLine}</Text>
            </Stack>
            <Text
              variant="textXs"
              color="text.disabled"
            >
              {priceInfo.creditLine}
            </Text>
          </Stack>
          <LoadingButton
            variant="contained"
            size="large"
            fullWidth
            loading={button.isLoading}
            onClick={button.onClick}
          >
            {button.text}
          </LoadingButton>
        </Stack>
      </StyledStack>
    </StyledStackWrapper>
  );
}
