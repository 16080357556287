import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import MenuIcon from '~icons/knowz-iconify/menu';
import StyledButton from './styles';

export default function ToolbarToggle() {
  const { handleToggleToolbar } = useToolbar();

  return (
    <StyledButton
      disableRipple
      disableTouchRipple
      onClick={handleToggleToolbar}
    >
      <MenuIcon fontSize="1.5em" />
    </StyledButton>
  );
}
