import Header from '@layouts/main/sections/header';
import StyledMainBox from '@layouts/main/sections/toolbar/components/content/styles';
import ToolbarToggle from '../toolbar-toggle';

export default function Content({ children }: React.PropsWithChildren) {
  return (
    <>
      <Header ToolbarToggle={<ToolbarToggle />} />
      <StyledMainBox component="main">{children}</StyledMainBox>
    </>
  );
}
