import {
  getStepDescriptionDefinition,
  getStepPropertiesDefinition,
} from '@components/pages/command-designer/config';
import { BlockPropertyEditorDialog } from '@components/pages/command-designer/dialogs/block-property-editor';
import { BlockStep } from '@components/pages/command-designer/extensions/block-step/blockStepExtension';
import useStepsDefinition from '@components/pages/command-designer/hooks/useBlockDefinition';
import Runner from '@components/pages/command-designer/sections/root-editor/Runner';
import {
  StyledModifiableTitle,
  StyledProperties,
} from '@components/pages/command-designer/sections/step-editor/styles';
import Text from '@components/text';
import useAppSelector from '@hooks/useAppSelector';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useDialogs } from '@toolpad/core';
import { useState } from 'react';
import { useStepEditor } from 'sequential-workflow-designer-react';
import { BranchedStep, Branches } from 'sequential-workflow-model';
import InputBlock from './InputBlock';
import { BagItem, InputBlockWithContext } from './InputBlockWithContext';
import SingleObject from './SingleObject';

export default function Editor() {
  const {
    name,
    setName,
    properties,
    setProperty,
    step,
    notifyChildrenChanged,
  } = useStepEditor();

  const { handleSaveBlock } = useStepsDefinition();

  const [importedBlock, setImportedBlock] = useState<BlockStep | null>(null);

  const isEmptyBlock =
    step.componentType === 'block' &&
    !(step as BlockStep).propertyTypes.length &&
    !(step as BlockStep).sequence.length;

  const dialogs = useDialogs();

  const runnerStatus = useAppSelector((state) => state.flowRun.runnerStatus);

  const stepPropertiesDefinition = getStepPropertiesDefinition(step);
  const stepDescriptionDefinition = getStepDescriptionDefinition(step);

  async function handleBlockPropertyEditor(
    type: 'input' | 'output',
    id?: string,
  ) {
    await dialogs.open(BlockPropertyEditorDialog, { id, type });
  }

  function onNameChanged(value: string) {
    setName(value);
  }

  function onPropertyChanged(propertyId: string, value: BagItem | BagItem[]) {
    if (step.componentType === 'switch' && propertyId === 'branches') {
      value = (value as BagItem[]).filter((v) => v.data);

      const { branches } = step as BranchedStep;
      const existingBranches = Object.keys(branches);

      if (existingBranches.length < value.length) {
        for (const { data } of value) {
          if (data && !branches[data]) branches[data] = [];
        }
      } else if (existingBranches.length > value.length) {
        if (value.length >= 2) {
          for (const branch of existingBranches) {
            if (!value.find((v) => v.data === branch)) {
              delete branches[branch];
            }
          }
        } else {
          renameBranch(branches, value);
        }
      } else {
        renameBranch(branches, value);
      }

      setTimeout(() => notifyChildrenChanged());
    }

    setProperty(propertyId, value);
  }

  function renameBranch(branches: Branches, value: BagItem[]) {
    const existingBranches = Object.keys(branches);

    let newBranchName: string | null = null;
    let oldBranchName: string | null = null;

    for (const { data } of value) {
      if (data && !branches[data]) {
        newBranchName = data as string;
        break;
      }
    }

    for (const branch of existingBranches) {
      if (!value.find((v) => v.data === branch)) {
        oldBranchName = branch;
        break;
      }
    }

    if (newBranchName && oldBranchName) {
      branches[newBranchName] = branches[oldBranchName];
      delete branches[oldBranchName];
    }
  }

  if (runnerStatus !== 'idle') {
    return <Runner />;
  }

  // TODO: check ownership
  if (step.componentType === 'block' && (step as BlockStep).isExpanded) {
    return (
      <StyledProperties justifyContent="space-between">
        <Box>
          <TextField
            fullWidth
            sx={{ mb: (theme) => theme.spacing(4) }}
            size="small"
            value={name}
            onChange={(e) => onNameChanged(e.target.value)}
          />

          <SingleObject
            name="Block description"
            description="Describe your block for users and agents to understand its purpose"
            wrapper="item"
            types={['string']}
            value={{ type: 'string', data: (step as BlockStep).description }}
            onChange={(value) => {
              (step as BlockStep).description = value.data;
              notifyChildrenChanged();
            }}
          />

          <InputBlockWithContext
            name="Inputs"
            description="These are the arguments that will need to be defined in order for your block to run"
          >
            <Stack
              spacing={2}
              sx={{ mb: (theme) => theme.spacing(4) }}
            >
              {(step as BlockStep).propertyTypes
                .filter((property) => property.usage !== 'output')
                .map((property) => (
                  <Button
                    sx={{
                      justifyContent: 'normal',
                      color: 'text.main',
                    }}
                    variant="text"
                    onClick={() =>
                      handleBlockPropertyEditor('input', property.id)
                    }
                  >
                    {property.name}
                  </Button>
                ))}
              <Button
                variant="outlined"
                onClick={() => handleBlockPropertyEditor('input')}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add input
              </Button>
            </Stack>
          </InputBlockWithContext>

          <InputBlockWithContext
            name="Outputs"
            description="These are the block's results that will be available for other blocks to use"
          >
            <Stack
              spacing={2}
              sx={{ mb: (theme) => theme.spacing(4) }}
            >
              {(step as BlockStep).propertyTypes
                .filter((property) => property.usage === 'output')
                .map((property) => (
                  <Button
                    sx={{
                      justifyContent: 'normal',
                      color: 'text.main',
                    }}
                    variant="text"
                    onClick={() =>
                      handleBlockPropertyEditor('output', property.id)
                    }
                  >
                    {property.name}
                  </Button>
                ))}
              <Button
                variant="outlined"
                onClick={() => handleBlockPropertyEditor('output')}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add output
              </Button>
            </Stack>
          </InputBlockWithContext>
        </Box>

        <Stack
          sx={{ mb: (theme) => theme.spacing(4) }}
          gap={3}
        >
          {isEmptyBlock && (
            <>
              <SingleObject
                name="Import block"
                description="Paste an existing block definition here to import it"
                // wrapper="item"
                // types={['string']}
                value={{ type: 'string' }}
                onChange={(value) => {
                  const definition = JSON.parse(value.data);

                  definition &&
                    definition.propertyTypes &&
                    definition.sequence &&
                    setImportedBlock(definition);
                }}
              />
              <Button
                startIcon={<AttachFileIcon />}
                disabled={!importedBlock}
                variant="outlined"
                onClick={() => {
                  const currentStep = step as BlockStep;
                  currentStep.propertyTypes = [...importedBlock!.propertyTypes];
                  currentStep.sequence = [...importedBlock!.sequence];

                  if (importedBlock!.name) {
                    currentStep.name = importedBlock!.name;
                  }

                  if (importedBlock!.description) {
                    currentStep.description = importedBlock!.description;
                  }

                  notifyChildrenChanged();
                }}
              >
                Import block
              </Button>
            </>
          )}

          {!isEmptyBlock && (
            <Button
              size="large"
              variant="contained"
              onClick={async () => {
                const currentStep = step as BlockStep;
                const block = await handleSaveBlock(currentStep);

                if (block && !currentStep.blockId) {
                  currentStep.owner = block.owner;
                  currentStep.blockId = block.blockId;
                  notifyChildrenChanged();
                }
              }}
            >
              Save block
            </Button>
          )}

          <Button
            variant="outlined"
            onClick={() => {
              (step as BlockStep).isExpanded = false;
              notifyChildrenChanged();
            }}
          >
            Collapse block
          </Button>
        </Stack>
      </StyledProperties>
    );
  }

  return (
    <StyledProperties justifyContent="space-between">
      <Box>
        <StyledModifiableTitle
          value={name}
          onChange={(e) => onNameChanged(e.target.value)}
        />

        {stepDescriptionDefinition && (
          <Text
            color="GrayText"
            variant="displayXs"
          >
            {stepDescriptionDefinition}
          </Text>
        )}

        {stepPropertiesDefinition
          // TODO: solve this without UI glitch of things changing order
          // .sort((a, b) =>
          //   a.usage === 'output' && b.usage !== 'output' ? 1 : -1,
          // )
          .map((property) => (
            <InputBlock
              {...property}
              key={`property-${property.id}`}
              value={(properties && properties[property.id]) || ''}
              onChange={(value: BagItem | BagItem[]) =>
                onPropertyChanged(property.id, value)
              }
            />
          ))}
      </Box>

      {/* TODO: only for creator or admin user */}
      {step.componentType === 'block' && (
        <Button
          size="large"
          sx={{ mb: (theme) => theme.spacing(4) }}
          variant="contained"
          onClick={() => {
            (step as BlockStep).isExpanded = true;
            notifyChildrenChanged();
          }}
        >
          Edit block
        </Button>
      )}
    </StyledProperties>
  );
}
