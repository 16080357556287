import StyledIconButton from '@components/pages/knowledge-base/components/action-button/styles';
import { IconButtonProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export default function ActionButton({
  children,
  title,
  ...otherProps
}: IconButtonProps) {
  return (
    <Tooltip
      title={title}
      placement="top"
      arrow
    >
      <StyledIconButton
        disableRipple
        {...otherProps}
      >
        {children}
      </StyledIconButton>
    </Tooltip>
  );
}
