import Box from '@mui/material/Box/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SKELETON_WIDTH = ['100%', '100%', '100%', '100%', '80%', '40%'];

type Props = {
  sourceIcon: React.ReactNode;
  title: React.ReactNode;
  close: React.ReactNode;
};

export default function LoadingSkeleton({ sourceIcon, title, close }: Props) {
  return (
    <Stack
      direction="row"
      gap={5}
      sx={{
        width: {
          xs: '100%',
          md: '500px',
        },
        height: '100%',
      }}
    >
      {sourceIcon}

      <Box sx={{ flex: 1 }}>
        <Stack gap={5}>
          {title}
          <Box>
            {SKELETON_WIDTH.map((width, index) => (
              <Skeleton
                key={index}
                variant="text"
                width={width}
              />
            ))}
          </Box>
        </Stack>
      </Box>
      <Box>{close}</Box>
    </Stack>
  );
}
