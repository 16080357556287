import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import ArrowRightIcon from '~icons/knowz-iconify/arrow-right';

export default function Content() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { me } = useAuth();

  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'flex-end' }}
      gap={2}
    >
      <Text
        variant="textSm"
        sx={{ flex: 1 }}
      >
        {t('page.knowledgeBase.capacityIndicator.tooltip.content.segment1', {
          defaultValue:
            'This is the combined size of the files you can store in Knowledge Base.',
        })}
        {!me?.paid &&
          t('page.knowledgeBase.capacityIndicator.tooltip.content.segment2', {
            defaultValue:
              ' Free accounts get 50MB platform storage, upgrade now for more.',
          })}
      </Text>
      <ArrowRightIcon
        color={theme.palette.primary.main}
        fontSize="1.5em"
      />
    </Stack>
  );
}
