import Content from '@components/pages/live-session/sections/content';
import Wrapper from '@components/pages/live/Wrapper';
import {
  LiveKitRoom,
  RoomAudioRenderer,
  RoomContext,
} from '@livekit/components-react';
import { Room } from 'livekit-client';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useStayAwake from '@hooks/useStayAwake';
import { DialogsProvider } from '@toolpad/core';
import SourcePreviewProvider from '@components/source-preview/providers/SourcePreviewProvider';
import RoomMetadataProvider from './providers/RoomMetadataProvider';

export default function LiveSession() {
  const { state }: { state: { token?: string } } = useLocation();
  const { current: room } = useRef<Room>(new Room());

  useStayAwake({ enabled: true });

  return (
    <DialogsProvider>
      <SourcePreviewProvider>
        <RoomContext.Provider value={room}>
          <RoomMetadataProvider>
            <LiveKitRoom
              token={state?.token}
              serverUrl={import.meta.env.VITE_LIVE_KIT_SERVER_URL}
              room={room}
              audio={true}
              connect={true}
            >
              <RoomAudioRenderer volume={1.0} />
              <Wrapper>
                <Content shouldLoadHistory={!state?.token} />
              </Wrapper>
            </LiveKitRoom>
          </RoomMetadataProvider>
        </RoomContext.Provider>
      </SourcePreviewProvider>
    </DialogsProvider>
  );
}
