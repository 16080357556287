import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  overflowY: 'hidden',
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
}));
