import { StyledClickableAvatar } from '@components/workspace/workspace-selector/triggers/styles';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import WindowGradIcon from '~icons/knowz-iconify/window-grad';
import IconButton from '@mui/material/IconButton';
import { forwardRef } from 'react';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Mobile = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { workspace } = useWorkspace();
  const { me } = useAuth();

  return (
    <IconButton
      ref={ref}
      {...props}
    >
      {workspace && (
        <StyledClickableAvatar src={workspace.profilePicture || ''}>
          {workspace.name[0].toUpperCase()}
        </StyledClickableAvatar>
      )}
      {!workspace && me && <WindowGradIcon />}
      {/* TODO: skeleton */}
    </IconButton>
  );
});

Mobile.displayName = 'Mobile';

export default Mobile;
