import useResponsive from '@hooks/useResponsive';
import ToolbarProvider from '@layouts/main/sections/toolbar/providers/ToolbarProvider';
import {
  ToolbarGridContainer,
  StyledToolbarBox,
} from '@layouts/main/sections/toolbar/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function Toolbar({ children }: React.PropsWithChildren) {
  const isItDesktop = useResponsive('up', 'md');

  return (
    <ToolbarProvider>
      <Stack
        direction="row"
        gap={isItDesktop ? 4 : 0}
      >
        <StyledToolbarBox>
          {isItDesktop ? (
            <ToolbarGridContainer>
              <ToolbarProvider.MiniToolbar />
              <ToolbarProvider.ExpandedToolbar />
            </ToolbarGridContainer>
          ) : (
            <>
              <ToolbarProvider.NavbarDrawer />
              <ToolbarProvider.MobileToolbar />
            </>
          )}
        </StyledToolbarBox>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <ToolbarProvider.Content>{children}</ToolbarProvider.Content>
        </Box>
      </Stack>
    </ToolbarProvider>
  );
}
