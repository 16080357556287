import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AskAISearchType,
  TextToSpeechType,
} from '@shared-types/search-result/types';
import { audioStore } from '@state/middleware/utils/textToSpeechHandler';
import {
  SourceLinksType,
  SourceLinksExternalType,
} from '@shared-types/search-result/streaming';

type AudioMetadataType = {
  isPlaying: boolean;
};

type SearchResultState = {
  askAIErrored: {
    status: number;
  } | null;
  mode: AskAISearchType['mode'];
  audioMetadata: {
    [key: string]: AudioMetadataType;
  } | null;
  currentAudioPlayingId: string | null;
  WSTextToSpeechIsLoading: boolean;
  WSTextToSpeechStatus: TextToSpeechType['status'];
  knowledgeLinks: SourceLinksType[] | null;
};

const initialState: SearchResultState = {
  askAIErrored: null,
  mode: 'fluid' as AskAISearchType['mode'],
  audioMetadata: null,
  currentAudioPlayingId: null,
  WSTextToSpeechIsLoading: false,
  WSTextToSpeechStatus: null,
  knowledgeLinks: null,
};

const searchResult = createSlice({
  name: 'searchResult',
  initialState,
  reducers: {
    modeUpdated: (state, action: PayloadAction<SearchResultState['mode']>) => ({
      ...state,
      mode: action.payload,
    }),
    askAIErrored: (
      state,
      action: PayloadAction<SearchResultState['askAIErrored']>,
    ) => {
      if (action.payload == null) return state;

      return {
        ...state,
        askAIErrored: { status: action.payload.status },
      };
    },
    WSTextToSpeechLoaded: (
      state,
      action: PayloadAction<SearchResultState['WSTextToSpeechIsLoading']>,
    ) => {
      return { ...state, WSTextToSpeechIsLoading: action.payload };
    },
    WSTTSMessageStatusUpdated: (
      state,
      action: PayloadAction<SearchResultState['WSTextToSpeechStatus']>,
    ) => {
      return { ...state, WSTextToSpeechStatus: action.payload };
    },
    currentAudioPlayingIdUpdated: (state, action: PayloadAction<string>) => ({
      ...state,
      currentAudioPlayingId: action.payload,
    }),
    audioMetadata: (
      state,
      action: PayloadAction<{
        id: string;
        type: 'toggle' | 'play' | 'pause' | 'stop';
      }>,
    ) => {
      const targetAudioId = action.payload.id;
      const targetAudio = audioStore.get(targetAudioId);

      if (!targetAudio) return;

      audioStore.forEach((audio, audioId) => {
        if (audioId !== targetAudioId) {
          audio.pause();
          audio.currentTime = 0;
          if (state.audioMetadata && state.audioMetadata[audioId]) {
            state.audioMetadata[audioId].isPlaying = false;
          }
        }
      });

      let isPlaying = false;
      if (action.payload.type === 'toggle') {
        if (targetAudio.paused) {
          targetAudio.play();
          isPlaying = true;
        } else {
          targetAudio.pause();
          isPlaying = false;
        }
      } else if (action.payload.type === 'pause') {
        targetAudio.pause();
        isPlaying = false;
      } else if (action.payload.type === 'play') {
        targetAudio.play();
        isPlaying = true;
      }

      if (!state.audioMetadata) {
        state.audioMetadata = {};
      }
      state.audioMetadata[targetAudioId] = { isPlaying };
      state.currentAudioPlayingId = targetAudioId;
    },
    // todo relocate to websocket slice
    knowledgeLinksUpdated: (
      state,
      action: PayloadAction<SourceLinksExternalType[]>,
    ) => ({
      ...state,
      knowledgeLinks: action.payload
        .filter((link) => link !== null)
        .map((link) => ({
          ...link,
          sourceId: link.source_id,
          originalText: link.original_text,
          sourceName: link.document_name,
        })),
    }),
  },
});

export const {
  askAIErrored,
  modeUpdated,
  currentAudioPlayingIdUpdated,
  WSTextToSpeechLoaded,
  WSTTSMessageStatusUpdated,
  knowledgeLinksUpdated,
} = searchResult.actions;

export default searchResult.reducer;
