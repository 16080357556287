import { live } from '@lib/agent/endpoints/live';
import { LiveLiveRequestType } from '@shared-types/live';
import { useMutation } from '@tanstack/react-query';

export default function useMutateLiveToken() {
  const {
    mutateAsync: mutateLiveTokenAsync,
    isPending: isMutateLiveTokenPending,
  } = useMutation({
    mutationKey: ['createLiveToken'],
    mutationFn: async (body: LiveLiveRequestType) =>
      await live.createLiveToken(body),
  });

  return {
    isMutateLiveTokenPending,
    mutateLiveTokenAsync,
  };
}
