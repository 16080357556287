import {
  //StyledImg, //TODO:
  StyledStack,
} from '@components/pages/assistants-session/welcome-message/styles';
import useGetOneAssistant from '@components/pages/hooks/useGetOneAssistantByBotId';
import useGetOneThreadLazily from '@components/pages/hooks/useGetOneThreadLazily';
import Text from '@components/text';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

export default function WelcomeMessage() {
  const { oneThreadData } = useGetOneThreadLazily();

  const {
    oneAssistantData,
    isGetOneAssistantLoading,
    isGetOneAssistantFetching,
  } = useGetOneAssistant(oneThreadData?.botId);
  const isGetOneAssistantBusy =
    isGetOneAssistantLoading || isGetOneAssistantFetching;

  return (
    <StyledStack
      direction="row"
      sx={{
        backgroundColor: (theme) => theme.palette.background.card.light,
      }}
      gap={5}
    >
      {/* {assistantData && assistantData.image && (
        <StyledImg src={assistantData.image} alt={assistantData.name} />
      )} */}
      <Stack sx={{ width: '100%' }}>
        {isGetOneAssistantBusy ? (
          <Skeleton
            variant="text"
            height={40}
            width={120}
          />
        ) : (
          <Text
            variant="displayMd"
            component="h1"
          >
            {oneAssistantData?.name}
          </Text>
        )}
        {isGetOneAssistantBusy ? (
          <Skeleton
            variant="text"
            sx={{ width: '100%' }}
          />
        ) : (
          <Text variant="textSm">{oneAssistantData?.description}</Text>
        )}
      </Stack>
    </StyledStack>
  );
}
