import StyledCanvas from '@components/pages/live-session/recorder/audio-wave/styles';
import { isTrackReference } from '@livekit/components-core';
import {
  useMediaDeviceSelect,
  useRoomContext,
} from '@livekit/components-react';
import { alpha, useTheme } from '@mui/material';
import {
  AudioAnalyserOptions,
  createAudioAnalyser,
  LocalAudioTrack,
  LocalTrackPublication,
  RoomEvent,
} from 'livekit-client';
import { useCallback, useEffect, useMemo, useState } from 'react';

type DataPoint = {
  volume: number;
  timestamp: number;
};

const WAVE_LENGTH_IN_SECONDS = 25;
const WAVE_LINE_DISTANCE = 5;
const WAVE_LINE_WIDTH = 2;

export function RoomAudioWave() {
  const room = useRoomContext();
  const [track, setTrack] = useState<LocalAudioTrack | undefined>();
  const { activeDeviceId } = useMediaDeviceSelect({ kind: 'audioinput' });

  const getVolume = useMemo(
    () => getTrackVolumeHandler(track),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [track, activeDeviceId],
  );

  useEffect(() => {
    room.on(RoomEvent.LocalTrackPublished, onLocalTrack);
    function onLocalTrack({ audioTrack }: LocalTrackPublication) {
      audioTrack !== track && setTrack(audioTrack);
    }

    return () => {
      room.off(RoomEvent.LocalTrackPublished, onLocalTrack);
    };
  }, [room, track]);

  return <AudioWave getVolume={getVolume} />;
}

export function AudioWave({ getVolume }: { getVolume?: () => number }) {
  const theme = useTheme();
  const [stopAnimation, setStopAnimation] = useState<{ cleanup: () => void }>();

  useEffect(() => {
    return () => {
      stopAnimation && stopAnimation.cleanup();
    };
  }, [stopAnimation]);

  const handleCanvas = useCallback(
    (canvas: HTMLCanvasElement) => {
      const context = canvas?.getContext('2d');
      if (!context) return;
      canvas.width = canvas.clientWidth;
      const heightRatio = 1.5;
      canvas.height = canvas.clientHeight * heightRatio;
      canvas.style.width = canvas.width + 'px';

      let lastTick = Date.now();
      const now = Date.now();
      const points = canvas.width / WAVE_LINE_DISTANCE;
      const tickDistance = (WAVE_LENGTH_IN_SECONDS / points) * 1000;
      const dataPoints: DataPoint[] = [];
      const averages: DataPoint[] = Array.from({ length: points }, (_, i) => ({
        volume: 0,
        timestamp: now - i * tickDistance,
      }));

      let isRunning = true;

      function updateAverages() {
        const now = Date.now();
        const points = canvas.width / WAVE_LINE_DISTANCE;
        const tickDistance = (WAVE_LENGTH_IN_SECONDS / points) * 1000;
        let lastIndex = 0;
        let nextTick = lastTick + tickDistance;

        if (!dataPoints.length) {
          return;
        }

        // Fast forward to the next tick
        while (dataPoints[lastIndex].timestamp <= nextTick) {
          lastIndex++;
          if (lastIndex >= dataPoints.length) {
            return;
          }
        }

        // Calculate averages for new each tick
        while (nextTick < now) {
          let val = 0;
          let totalPoints = 0;

          while (dataPoints[lastIndex]?.timestamp > lastTick) {
            val += dataPoints[lastIndex].volume;
            totalPoints++;
            lastIndex++;

            if (lastIndex >= dataPoints.length) {
              break;
            }
          }

          const volume = totalPoints ? val / totalPoints : 0;
          averages.push({ volume, timestamp: nextTick });
          lastTick = nextTick;
          nextTick = lastTick + tickDistance;
        }

        // Remove old data points
        averages.splice(0, averages.length - points);
        dataPoints.splice(0, lastIndex - 1);
      }

      (function animate() {
        dataPoints.push({
          volume: getVolume?.() || 0,
          timestamp: Date.now(),
        });

        updateAverages();

        const gradient = context.createLinearGradient(
          0,
          0,
          canvas.width,
          canvas.height,
        );
        const primaryColor = theme.palette.primary.main;
        gradient.addColorStop(0, alpha(primaryColor, 0));
        gradient.addColorStop(0.05, alpha(primaryColor, 1));
        gradient.addColorStop(0.95, alpha(primaryColor, 1));
        gradient.addColorStop(1, alpha(primaryColor, 0));

        context.fillStyle = gradient;
        context.strokeStyle = gradient;
        context.clearRect(0, 0, canvas.width, canvas.height);

        const points = averages.length;
        const middle = canvas.height / 2;
        const tickDistance = (WAVE_LENGTH_IN_SECONDS / points) * 1000;
        const hasData = averages.find((p) => p.volume > 0);
        const offset = hasData
          ? ((lastTick - Date.now()) / tickDistance) * WAVE_LINE_DISTANCE
          : 0;

        for (let i = points - 1; i >= 0; i--) {
          let h = averages[i].volume * canvas.height;
          const x = i * WAVE_LINE_DISTANCE;
          if (h < WAVE_LINE_WIDTH) h = WAVE_LINE_WIDTH;
          context.beginPath();
          context.roundRect(x + offset, middle - h / 2, WAVE_LINE_WIDTH, h, 2);
          context.fill();
        }

        isRunning && requestAnimationFrame(animate);
      })();

      setStopAnimation({ cleanup: () => (isRunning = false) });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getVolume],
  );

  return <StyledCanvas ref={handleCanvas} />;
}

function getTrackVolumeHandler(
  trackOrTrackReference?: LocalAudioTrack,
  options: AudioAnalyserOptions = { fftSize: 32, smoothingTimeConstant: 0 },
): () => number {
  const track = isTrackReference(trackOrTrackReference)
    ? trackOrTrackReference.publication.track
    : trackOrTrackReference;

  if (!track || !track.mediaStream) {
    return () => 0;
  }

  const { analyser } = createAudioAnalyser(track as LocalAudioTrack, options);
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  return () => {
    analyser.getByteFrequencyData(dataArray);
    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const a = dataArray[i];
      sum += a * a;
    }

    return Math.sqrt(sum / dataArray.length) / 255;
  };
}
