import useAppDispatch from '@hooks/useAppDispatch';
import useTimer from '@hooks/useTimer';
import { FactCheckType, SentimentLabelsType } from '@shared-types/live';
import { DataPacket_Kind, Participant, Room, RoomEvent } from 'livekit-client';
import { useRef, useState, useSyncExternalStore } from 'react';
import {
  addTranscript,
  addFeed,
  setSentiments,
  setFacts,
} from '@state/slices/live-session';
import friendlyNameManager from '../utils/getFriendlyName';

type SpeechToTextFinalDataType = {
  type: 'stt_sentence';
  text: string;
  index: number;
  start: number;
  end: number;
  speaker: string;
};

type SemanticSearchDataType = {
  type: 'semantic_search';
  index: number;
  result: SentimentLabelsType;
};

type SpeechToTextTempDataType = {
  type: 'stt_temp';
  text: string;
  index: number;
};

type FactCheckSearchDataType = FactCheckType & {
  type: 'fact_check';
};

type RoomTopicType =
  | SpeechToTextFinalDataType['type']
  | SemanticSearchDataType['type']
  | SpeechToTextTempDataType['type']
  | FactCheckSearchDataType['type']
  | 'stt_consolidated_buffer'
  | 'stt_bot_buffer';

export default function useLiveKitTranscriber(room: Room) {
  const [interimTranscript, setInterimTranscript] = useState<string | null>(
    null,
  );
  const { current: textDecoderRef } = useRef(new TextDecoder());

  const { minutes, seconds } = useTimer();

  const dispatch = useAppDispatch();

  function subscriber(onStoreChange: VoidFunction) {
    function handleDataReceived(
      payload: Uint8Array,
      _participant?: Participant,
      _kind?: DataPacket_Kind,
      topic?: string,
    ) {
      const strData = textDecoderRef.decode(payload);
      const parsedData = JSON.parse(strData) as unknown;
      switch (topic as RoomTopicType) {
        case 'stt_temp': {
          const typedParsedData = parsedData as SpeechToTextTempDataType;

          const text = typedParsedData.text;

          setInterimTranscript(text);
          onStoreChange();
          break;
        }
        case 'stt_sentence': {
          const {
            text,
            index,
            speaker: speakerReference,
          } = parsedData as SpeechToTextFinalDataType;

          dispatch(
            addTranscript({
              speaker: {
                friendlyName:
                  friendlyNameManager.setFriendlyName(speakerReference),
                reference: speakerReference,
              },
              content: text,
              id: index.toString(),
              time: {
                // TODO: move away from useTimer and use start & end fields instead
                minutes: minutes.toString().padStart(2, '0'),
                seconds: seconds.toString().padStart(2, '0'),
              },
            }),
          );

          if (interimTranscript) {
            const from = text.length;
            setInterimTranscript(
              interimTranscript.substring(from).replace(/^\.+/, '').trim(),
            );
          }

          onStoreChange();
          break;
        }
        case 'semantic_search': {
          const { index, result } = parsedData as SemanticSearchDataType;

          dispatch(
            setSentiments({
              historyBlockId: index.toString(),
              sentiments: result,
            }),
          );

          break;
        }
        case 'fact_check': {
          const { index, result, avatar, name } =
            parsedData as FactCheckSearchDataType;

          dispatch(
            setFacts({
              historyBlockId: index.toString(),
              factChecks: result.map(({ outcome, id }) => ({ id, outcome })),
            }),
          );

          dispatch(addFeed({ avatar, index, name, result }));

          break;
        }
        case 'stt_consolidated_buffer': {
          // console.log('stt_consolidated_buffer', parsedData);
          break;
        }
        case 'stt_bot_buffer': {
          // console.log('stt_bot_buffer', parsedData);
          break;
        }
        default: {
          console.error('Unknown topic', topic);
        }
      }
    }

    room.on(RoomEvent.DataReceived, handleDataReceived);

    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
    };
  }

  function getSnapshot() {
    return room.state === 'connected' ? interimTranscript : null;
  }

  const transcript = useSyncExternalStore(subscriber, getSnapshot);

  return {
    transcript,
  };
}
