import CONFIG from '@components/pages/live/config';
import useCollectAllSmartTags from '@components/pages/live/hooks/useGetAllSmartTags';
import TagListItem from '@components/pages/live/sections/cards/smart-tags-card/components/tag-list-item';
import StyledStack from '@components/pages/live/sections/cards/smart-tags-card/kinds/list/list-item/styles';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { SmartTagGroupIdType } from '@shared-types/live';
import { groupedTrainingSetsUpdated } from '@state/slices/live';
import { Fragment } from 'react';

type Props = {
  categoryId: SmartTagGroupIdType;
  categoryTitle: string;
  items: {
    name: string;
    id: number;
  }[];
};

export default function ListItem({ categoryId, items, categoryTitle }: Props) {
  const groupedTrainingSets = useAppSelector(
    (state) => state.live.groupedTrainingSets,
  );

  const dispatch = useAppDispatch();

  const { collectedSmartTags } = useCollectAllSmartTags();

  function handleCategoryToggle({
    categoryId,
    checked,
  }: {
    categoryId: SmartTagGroupIdType;
    checked: boolean;
  }) {
    const ids = collectedSmartTags[categoryId].items.map((item) => item.id);
    dispatch(
      groupedTrainingSetsUpdated({
        ...groupedTrainingSets,
        [categoryId]: checked ? ids : [],
      }),
    );
  }

  function handleItemToggle({
    categoryId,
    checked,
    itemId,
  }: {
    categoryId: SmartTagGroupIdType;
    itemId: number;
    checked: boolean;
  }) {
    dispatch(
      groupedTrainingSetsUpdated({
        ...groupedTrainingSets,
        [categoryId]: checked
          ? [...groupedTrainingSets[categoryId], itemId]
          : groupedTrainingSets[categoryId].filter((id) => id !== itemId),
      }),
    );
  }

  const allChecked = groupedTrainingSets[categoryId]?.length === items?.length;
  const someChecked =
    groupedTrainingSets[categoryId]?.length > 0 && !allChecked;

  return (
    <StyledStack>
      <TagListItem
        checkbox={{
          Component: (
            <Checkbox
              onChange={(_event, checked) =>
                handleCategoryToggle({ categoryId, checked })
              }
              checked={allChecked}
              indeterminate={someChecked}
              icon={<StyledCheckboxIcon />}
              checkedIcon={<StyledCheckbox />}
              indeterminateIcon={<StyledIntermittentCheckbox />}
            />
          ),
          position: 'end',
        }}
        title={{
          text: categoryTitle,
          variant: 'textSm',
          weight: 'semibold',
        }}
      />
      <Divider />
      {items.map((item, index, originalItems) => {
        const { name, id } = item as {
          name: keyof typeof CONFIG.SMART_TAGS;
          id: number;
        };
        return (
          <Fragment key={index}>
            <TagListItem
              checkbox={{
                Component: (
                  <Checkbox
                    onChange={(_event, checked) =>
                      handleItemToggle({ categoryId, itemId: id, checked })
                    }
                    checked={groupedTrainingSets[categoryId].includes(id)}
                    icon={<StyledCheckboxIcon />}
                    checkedIcon={<StyledCheckbox />}
                    indeterminateIcon={<StyledIntermittentCheckbox />}
                  />
                ),
                position: 'end',
              }}
              title={{
                text: name,
                variant: 'textMd',
                weight: 'medium',
              }}
              subtitle={CONFIG.SMART_TAGS[name].description}
            />
            {index !== originalItems.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </StyledStack>
  );
}
