import Text from '@components/text';
import { styled } from '@mui/material';

const StyledText = styled(Text)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
})) as typeof Text;

export default StyledText;
