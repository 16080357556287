/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledCard = styled(Stack)(({ theme }) => ({
  padding: 0,
  border: `1px solid ${theme.palette.mode === 'dark' ? '#22262F' : '#E9EAEB'}`, // TODO: theme.palette.divider
  borderRadius: theme.shape.borderRadius * 2.5,
  backgroundColor: theme.palette.mode === 'dark' ? '#13161B' : '#FFFFFF', // TODO: theme.palette.background.paper
})) as typeof Stack;

export const StyledAccordion = styled(MuiAccordion)(() => ({
  background: 'transparent',
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#22262F' : '#E9EAEB'}`,
  marginTop: theme.spacing(2),
  paddingBottom: 0,
}));
