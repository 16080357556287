import requests from '@lib/agent/requests';
import axiosAwsInstance from '@lib/agent/services/aws/axiosInstance';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { StepType } from '@lib/step/types';

export type CommandBlockType = {
  id?: number;
  name: string;
  description: string;
  icon: string;
  packages?: string[];
};

export const commandBlocks = {
  getAllPackages: () =>
    requests(axiosApiInstance).get<string[]>(
      '/v1/entities/command-blocks/packages'
    ),

  getAllCommandBlocks: (payload: { package?: string }) =>
    requests(axiosApiInstance).get<CommandBlockType[]>(
      '/v1/entities/command-blocks' +
        (typeof payload.package === 'undefined'
          ? '' : `?package=${payload.package}`)
    ),

  getCommandBlock: (blockId: number | string) =>
    requests(axiosApiInstance).get<CommandBlockType>(
      `/v1/entities/command-blocks/${blockId}`,
    ),

  deleteOneCommandBlock: (blockId: number | string) =>
    requests(axiosApiInstance).delete(`/v1/entities/command-blocks/${blockId}`),

  getCommandBlockDefinition: (signedUrl: string) =>
    requests(axiosAwsInstance).get<StepType>(signedUrl),

  createOneCommandBlock: (body: CommandBlockType) =>
    requests(axiosApiInstance).post<CommandBlockType>('/v1/entities/command-blocks', body),

  updateOneCommandBlock: (
    blockId: number | string,
    body: Partial<CommandBlockType>,
  ) =>
    requests(axiosApiInstance).patch<CommandBlockType>(
      `/v1/entities/command-blocks/${blockId}`,
      body,
    ),
};
