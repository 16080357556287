import Text from '@components/text';
import { useStopwatch } from 'react-timer-hook';

export default function Timer() {
  //TODO: we should use this hook for 10 minutes timer in code verification step in secure account
  const { minutes, seconds, hours } = useStopwatch({
    autoStart: true,
  });

  return (
    <Text
      variant="textSm"
      fontWeight={500}
      sx={{ fontFamily: 'Monospace', lineHeight: 'initial', mt: '2px' }}
    >
      {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
      {String(seconds).padStart(2, '0')}
    </Text>
  );
}
