import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import Button, { ButtonProps } from '@mui/material/Button';
import paths from '@router/paths';
import { Link } from 'react-router-dom';
import StarIcon from '~icons/knowz-iconify/star';

const SHARED_PROPS: ButtonProps = {
  fullWidth: true,
  variant: 'contained',
  size: 'medium',
  sx: { marginBlockStart: 3 },
  startIcon: <StarIcon />,
};

export default function CreditActionButton() {
  const { t } = useTranslation();
  const { me } = useAuth();
  const { setQueryParam } = useQueryParams();

  if (me?.auth_type === 'web_fingerprint') {
    return (
      <Button
        onClick={() => {
          setQueryParam('action', 'secure-account');
        }}
        {...SHARED_PROPS}
      >
        {t('layout.toolbar.buyAICredit.getStartedButton', {
          defaultValue: 'Get started!',
        })}
      </Button>
    );
  }

  return (
    <Button
      component={Link}
      to={paths.upgradeAccount.pathname}
      {...SHARED_PROPS}
    >
      {t('layout.toolbar.buyAICredit.button', {
        defaultValue: 'Buy more credits!',
      })}
    </Button>
  );
}
