import FormProvider from '@components/react-hook-form/FormProvider';
import RHFCheckbox from '@components/react-hook-form/RHFCheckbox';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';
import FormHeading from '@components/user-authentication/components/form-heading';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { setupPasswordSchema } from '@components/user-authentication/schemas';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const defaultValues = {
  password: '',
  is_subscribe_marketing: false,
};

type SetupPasswordFormType = {
  password: string;
  is_subscribe_marketing?: boolean;
};

export default function SetupPassword() {
  const { t } = useTranslation();

  const { handlePassword, handleIsUserSubscribe, handleNextStep } =
    useUserAuth();

  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm({
    resolver: yupResolver<SetupPasswordFormType>(setupPasswordSchema),
    defaultValues,
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  function handleNextStepOnClick(data: SetupPasswordFormType) {
    handlePassword(data.password);
    handleIsUserSubscribe(data.is_subscribe_marketing || false);
    handleNextStep();
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(handleNextStepOnClick)}
    >
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.setupPassword.label', {
          defaultValue: 'Welcome to Knowz',
        })}
        withBackButton
      />
      <Stack gap={5}>
        <Text variant="textMd">
          {t('layout.auth.desktop.modal.steps.setupPassword.description', {
            defaultValue: 'Create a password which is hard to guess:',
          })}
        </Text>
        <Text variant="textMd">
          {t(
            'layout.auth.desktop.modal.steps.setupPassword.form.password.label',
            {
              defaultValue: 'Set a password',
            },
          )}
        </Text>
        <RHFInputField
          fullWidth
          size="small"
          name="password"
          type={showPassword ? 'text' : 'password'}
          error={!!errors.password}
          autoComplete="new-password" //? https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
          placeholder={t(
            'layout.auth.desktop.modal.steps.setupPassword.form.password.placeholder',
            {
              defaultValue: 'Password',
            },
          )}
          helperText={errors.password?.message}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <Stack
          direction="row"
          sx={{ alignItems: 'center' }}
        >
          <RHFCheckbox
            label={t(
              'layout.auth.desktop.modal.steps.setupPassword.form.subscribe',
              {
                defaultValue:
                  'I agree to Knowz sending marketing communications about Knowz to me.',
              },
            )}
            name="is_subscribe_marketing"
          />
        </Stack>
        <LoadingButton
          onClick={handleSubmit(handleNextStepOnClick)}
          disabled={!isDirty || isSubmitting}
          loading={isSubmitting}
          variant="contained"
          fullWidth
        >
          {t('layout.auth.desktop.modal.steps.setupPassword.form.button', {
            defaultValue: 'Continue',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
