import { SourcePreviewContext } from '@components/source-preview/providers/SourcePreviewProvider';
import { useContext } from 'react';

export default function useSourcePreview() {
  const context = useContext(SourcePreviewContext);

  if (context === null) {
    throw new Error(
      'useSourcePreview must be used within a SourcePreviewProvider',
    );
  }

  return context;
}
