import { useMemo } from 'react';
import '@fontsource/inter';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { breakpoints, palette, shape, typography } from '@theme/.';
import ComponentsOverrides from '@theme/overrides';
import shadows from '@theme/shadows';
import useThemeSettings from '@hooks/useThemeSettings';

export default function ThemeProvider({ children }: React.PropsWithChildren) {
  const { themeMode } = useThemeSettings();
  const isThemeModeDark = themeMode === 'dark';

  const theme = useMemo(
    () =>
      createTheme({
        typography,
        palette: isThemeModeDark ? palette.dark : palette.light,
        breakpoints,
        shape,
        customShadows: isThemeModeDark ? shadows.dark : shadows.light,
        spacing: 4,
        direction: 'ltr',
      }),
    [isThemeModeDark],
  );

  theme.components = ComponentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
