import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import CONFIG from './config';
import { TextVariantsType, TextWeightType } from './types';
import { TypographyProps } from '@mui/material';

type Props<C extends React.ElementType> = Omit<
  TypographyProps<C, { component: C }>,
  'variant'
> & {
  variant: TextVariantsType;
  weight?: TextWeightType;
  isItalic?: boolean;
  isUnderlined?: boolean;
  children: React.ReactNode;
};

const Text = <C extends React.ElementType>(
  {
    variant,
    weight = 'regular',
    isItalic = false,
    isUnderlined = false,
    sx,
    children,
    ...otherProps
  }: Props<C>,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  return (
    <Typography
      ref={ref}
      variant={variant}
      fontWeight={CONFIG.WEIGHT_MAPPINGS[weight]}
      sx={{
        fontStyle: isItalic ? 'italic' : 'normal',
        textDecoration: isUnderlined ? 'underline' : 'none',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

Text.displayName = 'Text';

export default forwardRef(Text) as <C extends React.ElementType>(
  props: Props<C> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof Text>;
