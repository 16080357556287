import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { TextWeightType } from './types';

const WEIGHT_MAPPINGS: Record<
  TextWeightType,
  TypographyStyleOptions['fontWeight']
> = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const CONFIG = {
  WEIGHT_MAPPINGS,
} as const;

export default CONFIG;
