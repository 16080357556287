import {
  StyledChip,
  StyledLabelsContainer,
  StyledCard,
  StyledCardIcon,
} from '@components/pages/live-session/sections/content/components/transcript/card/styles';
import Text from '@components/text';
import Stack from '@mui/material/Stack';
import { TranscriptType } from '@shared-types/live';
import { forwardRef, memo } from 'react';
import { motion } from 'framer-motion';
import Dropdown from './dropdown';
import SmartStarIcon from '~icons/knowz-iconify/smart-star';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { getOutcomeLabel } from '@components/pages/live-session/sections/content/components/feed/list-item/summary';

type Props = {
  content: string;
  labels?: TranscriptType['labels'];
  time: TranscriptType['time'];
  speaker: {
    name: string;
    reference: string;
  };
  id: string;
};

const AVATAR_SIZE = 24;

const Card = forwardRef<HTMLDivElement, Props>(
  ({ content, labels, time, speaker, id }, ref) => {
    const theme = useTheme();

    if (!content) return null;

    const hasLabels = labels?.factChecks?.length || labels?.sentiments?.length;

    return (
      <StyledCard
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        ref={ref}
      >
        <Stack
          direction="row"
          gap={2}
          sx={{ padding: (theme) => theme.spacing(3, 2) }}
        >
          <StyledCardIcon>
            {speaker && id && (
              <Dropdown speaker={speaker.reference}>
                <Avatar
                  alt="John Doe"
                  sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
                />
              </Dropdown>
            )}
          </StyledCardIcon>

          <Stack
            direction="column"
            gap={1}
          >
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
            >
              <Dropdown
                speaker={speaker.reference}
                iconProps={{
                  disableFocusRipple: true,
                  disableRipple: true,
                  disableTouchRipple: true,
                }}
              >
                <Text
                  variant="textSm"
                  weight="medium"
                >
                  {speaker.name}
                </Text>
              </Dropdown>

              {time && (
                <Text
                  variant="textXs"
                  color="text.secondary"
                >
                  {time.minutes}:{time.seconds}
                </Text>
              )}
            </Stack>

            <Text
              sx={{ marginInlineEnd: 2 }}
              variant="textSm"
            >
              {content}
            </Text>
          </Stack>
        </Stack>

        {hasLabels && (
          <StyledLabelsContainer
            direction="row"
            gap={2}
          >
            <StyledCardIcon sx={{ paddingBlockTop: 1 }}>
              <SmartStarIcon
                fontSize="0.9em"
                color={theme.palette.mode === 'dark' ? '#CECFD2' : '#414651'}
              />
            </StyledCardIcon>

            <Stack
              direction="row"
              gap={2}
              sx={{
                flexWrap: 'wrap',
              }}
            >
              {labels?.sentiments?.map(({ sentiment }, index) => (
                <StyledChip
                  key={index}
                  label={sentiment}
                  size="small"
                  variant="outlined"
                />
              ))}
              {labels?.factChecks?.map((label, index) => (
                <StyledChip
                  key={index}
                  label={getOutcomeLabel(label.outcome)}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Stack>
          </StyledLabelsContainer>
        )}
      </StyledCard>
    );
  },
);

Card.displayName = 'Card';

export default memo(Card); //TODO: memo does not work since the props are not primitive types
