import Wrapper from '@layouts/assistants-session/components/wrapper';
import AssistantsSessionProvider from '@layouts/assistants-session/providers/AssistantsSessionProvider';
import { Stack } from '@mui/material';

export default function AssistantsSessionLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <AssistantsSessionProvider>
      <AssistantsSessionProvider.Header />
      <Stack direction="row">
        <Wrapper>
          <AssistantsSessionProvider.Main>
            {children}
          </AssistantsSessionProvider.Main>
          <AssistantsSessionProvider.Drawer />
        </Wrapper>
      </Stack>
    </AssistantsSessionProvider>
  );
}
