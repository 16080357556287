import useQueryAfterAuthenticationLoaded from '@hooks/useQueryAfterAuthenticationLoaded';
import { threads } from '@lib/agent';
import paths from '@router/paths';
import { isAxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

type HistoryBlocksType = {
  threadId?: string;
};

export default function useGetHistoryBlocksByThreadIdLazily<T>({
  threadId,
}: HistoryBlocksType) {
  const { state } = useLocation() as { state?: { isUserRedirected: boolean } };
  const navigateTo = useNavigate();

  const {
    data: historyBlocksQueryData,
    isError: isHistoryBlocksError,
    isFetching: isHistoryBlocksFetching,
    isLoading: isHistoryBlocksLoading,
  } = useQueryAfterAuthenticationLoaded({
    queryKey: ['historyBlocks', threadId],
    queryFn: async () => {
      try {
        const { data: historyBlocksData } =
          await threads.getHistoryBlockById<T>({
            id: threadId!,
            size: '10',
            order: 'ASC',
          });

        return historyBlocksData;
      } catch (error) {
        //? thread not found redirect user to home page
        if (isAxiosError(error) && error.response?.status === 404) {
          navigateTo(paths.home.pathname);
          throw new Error('Thread not found');
        }
        throw new Error(`An error occurred while fetching thread: ${error}`);
      }
    },
    options: {
      enabled: !!threadId && !state?.isUserRedirected, //? do not fetch if user is redirected since there are not any history blocks yet!
      retry: 1,
    },
  });

  return {
    historyBlocksQueryData,
    isHistoryBlocksError,
    isHistoryBlocksFetching,
    isHistoryBlocksLoading,
  };
}
