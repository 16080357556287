import Text from '@components/text';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { Controller, useFormContext } from 'react-hook-form';

interface RHFCheckboxProps
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  CheckboxProps?: CheckboxProps;
  label?: string;
}

export default function RHFCheckbox({
  name,
  CheckboxProps,
  label,
  ...other
}: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      label={
        <Text
          color="text.secondary"
          variant="textMd"
        >
          {label}
        </Text>
      }
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              size="small"
              {...field}
              checked={field.value}
              {...CheckboxProps}
            />
          )}
        />
      }
      {...other}
    />
  );
}
