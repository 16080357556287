import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { convertPxToRem } from '@lib/fonts';

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export const StyledCard = styled(Stack)(({ theme }) => ({
  padding: 0,
  border: `1px solid ${theme.palette.mode === 'dark' ? '#22262F' : '#E9EAEB'}`, // TODO: theme.palette.divider
  borderRadius: theme.shape.borderRadius * 2.5,
  backgroundColor: theme.palette.mode === 'dark' ? '#13161B' : '#FFFFFF', // TODO: theme.palette.background.paper
})) as typeof Stack;

export const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: convertPxToRem(12),
  fontWeight: 500,
  lineHeight: 1.5,
  color: theme.palette.mode === 'dark' ? '#ECECED' : '#252B37', // TODO: design system
  borderColor: theme.palette.mode === 'dark' ? '#ECECED' : '#252B37', // TODO: design system
}));

export const StyledLabelsContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  borderRadius: theme.shape.borderRadius * 2.4,
  background: `
      linear-gradient(
        to right,
        rgba(83, 177, 237, 0.17),
        rgba(137, 95, 242, 0.17),
        rgba(30, 26, 249, 0.17)
      )`, // TODO: design system
}));

export const StyledCardIcon = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: '31px',
  alignItems: 'flex-start',
}));
