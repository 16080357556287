import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StyledToolbarExtensionToggleButton from '@layouts/main/sections/toolbar/components/expand-button/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { useTranslation } from '@desygner/ui-common-translation';
import { forwardRef } from 'react';
import Fade from '@mui/material/Fade';
import CONFIG from '@layouts/main/sections/toolbar/config';

const ToolbarExpansionButton = forwardRef((_, ref) => {
  const {
    handleToggleToolbar,
    toolbarExpansionStatus,
    isExpandedButtonDisplayed,
  } = useToolbar();
  const { t } = useTranslation();
  const isToolbarExpanded = toolbarExpansionStatus === 'on';

  return (
    <Fade
      in={isExpandedButtonDisplayed}
      timeout={{ exit: CONFIG.DELAY_HIDE_EXPANDED_BUTTON }}
    >
      <Tooltip
        ref={ref}
        arrow
        placement="right"
        title={
          isToolbarExpanded
            ? t('layout.toolbar.buttons.collapse', {
                defaultValue: 'Collapse',
              })
            : t('layout.toolbar.buttons.expand', {
                defaultValue: 'Expand',
              })
        }
      >
        <StyledToolbarExtensionToggleButton onClick={handleToggleToolbar}>
          {isToolbarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </StyledToolbarExtensionToggleButton>
      </Tooltip>
    </Fade>
  );
});

ToolbarExpansionButton.displayName = 'ExpandButton';

export default ToolbarExpansionButton;
