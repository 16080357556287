import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};

export default function FormProvider({ children, onSubmit, methods }: Props) {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code !== 'Enter') return;
    if (e.shiftKey && e.key === 'Enter') {
      return;
    }
    if (e.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  };

  return (
    <Form {...methods}>
      <form
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={handleKeyDown}
      >
        {children}
      </form>
    </Form>
  );
}
