import { StyledCard } from '@layouts/assistants-session/drawer/conversation-history/styles';
import MuiSkeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Card() {
  return (
    <StyledCard>
      <Stack gap={4}>
        <MuiSkeleton
          variant="text"
          width={250}
        />
        <Stack>
          <MuiSkeleton
            variant="text"
            sx={{ width: '100%' }}
          />
          <MuiSkeleton
            variant="text"
            sx={{ width: '100%' }}
          />
        </Stack>
        <MuiSkeleton
          variant="text"
          width={50}
        />
      </Stack>
    </StyledCard>
  );
}
