import Text from '@components/text';
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledText = styled(Text)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.brand.purple.main, //TODO: we need to add this color to the theme palette after new design is implemented
  backgroundColor: theme.palette.brand.purple.light,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));
