import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[300],
  '&:hover': {
    color: theme.palette.grey[800],
  },
}));

export default StyledCloseButton;
