import { SharedStyledSlide } from '@components/pages/styles';
import { styled } from '@mui/material';

const StyledExpandedSlider = styled(SharedStyledSlide)(({ theme }) => ({
  cursor: 'inherit',
  [theme.breakpoints.up('md')]: {
    height: theme.spacing(2),
    width: '100%',
  },
}));

export default StyledExpandedSlider;
