import { useGetCommand } from '@hooks/useCommands';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCommandBlockDefinitions } from './useCommandBlocks';
import { getStaticGroups } from '../lib/helpers';

export default function useStepsDefinition() {
  const { id } = useParams();
  const {
    commandData,
    isLoadingCommand
  } = useGetCommand(id!);

  const command = commandData?.data;
  const blocksRequests = useGetCommandBlockDefinitions(command?.commandBlocks || []);
  const isLoadingStepsDefinition = useMemo(() => {
    return isLoadingCommand ||
      blocksRequests.length !== command?.commandBlocks.length ||
      blocksRequests.some((request) => request.isLoading)
  }, [isLoadingCommand, blocksRequests, command]);

  const steps = blocksRequests.map((request) => request.data).filter(Boolean);

  return {
    command,
    isLoadingStepsDefinition,
    toolboxConfiguration:{
      groups: [...getStaticGroups(), {
        name: 'default',
        steps: steps.map((step) => ({
          ...step,
          id: undefined,
          isExpanded: step.type === 'block' ? false : undefined,
        }))
      }],
      isCollapsed: false,
    }
  };
}
