import { DirectiveDescriptor, useNestedEditorContext } from '@mdxeditor/editor';
import DirectiveLabel from './DirectiveLabel';

export const secretRegex = /:secret\[([^\]]+)\]/g;

export const SecretDirectiveDescriptor: DirectiveDescriptor = {
  name: 'secret',
  testNode: (node) => node.name === 'secret',
  hasChildren: true,
  attributes: [],
  type: 'textDirective',
  Editor: ({ mdastNode: { children } }) => {
    const { parentEditor, lexicalNode } = useNestedEditorContext();

    return (
      <DirectiveLabel
        component="span"
        color="green"
        sx={{
          position: 'relative',
          cursor: 'not-allowed',
        }}
        onClick={(e) => {
          e.preventDefault();
          parentEditor.update(() => {
            lexicalNode.remove();
          });
        }}
      >
        {children[0]?.value || '<missing context>'}
      </DirectiveLabel>
    );
  },
};
