import useAutoSelectAllTagsOnMount from '@components/pages/live/hooks/useAutoSelectAllTagsOnMount';
import useCollectAllSmartTags from '@components/pages/live/hooks/useGetAllSmartTags';
import ListItem from '@components/pages/live/sections/cards/smart-tags-card/kinds/list/list-item';
import StyledCircularProgressStack from '@components/pages/live/sections/cards/smart-tags-card/kinds/list/styles';
import Masonry from '@mui/lab/Masonry';
import { CircularProgress } from '@mui/material';
import { SmartTagGroupIdType } from '@shared-types/live';

const DEFAULT_HEIGHT = 300;

export default function List() {
  const { collectedSmartTags, areCollectedSmartTagsSuccessfullyLoaded } =
    useCollectAllSmartTags();

  useAutoSelectAllTagsOnMount();

  return areCollectedSmartTagsSuccessfullyLoaded ? (
    <Masonry
      columns={{ xs: 1, sm: 2, md: 3 }}
      spacing={2}
      defaultHeight={DEFAULT_HEIGHT}
    >
      {Object.entries(collectedSmartTags).map((collectedSmartTag, index) => {
        const categoryId = collectedSmartTag[0] as SmartTagGroupIdType;
        const { title: categoryTitle, items: categoryItems } =
          collectedSmartTag[1];

        return (
          <ListItem
            key={index}
            categoryTitle={categoryTitle}
            categoryId={categoryId}
            items={categoryItems}
          />
        );
      })}
    </Masonry>
  ) : (
    <StyledCircularProgressStack>
      <CircularProgress />
    </StyledCircularProgressStack>
  );
}
