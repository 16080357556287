import SectionHeading from '@components/pages/search/components/SectionHeading';
import TextToSpeechIconButton from '@components/pages/search/components/TextToSpeechIconButton';
import { useTranslation } from '@desygner/ui-common-translation';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const NUMBER_OF_LINES = 18;

const ORB_ICON_ELEMENT = (
  <img
    width={20}
    height={20}
    src="https://assets.knowz.com/assets/svg/orb---small.png"
    alt="answer section"
  />
);

export default function Answer() {
  const { t } = useTranslation();

  return (
    <SectionHeading
      title={
        <>
          {t('page.search.sections.answer', {
            defaultValue: 'Answer',
          })}
          &nbsp;
          {typeof window.MediaSource === 'function' && (
            <TextToSpeechIconButton disabled>
              <RecordVoiceOverRoundedIcon />
            </TextToSpeechIconButton>
          )}
        </>
      }
      icon={ORB_ICON_ELEMENT}
    >
      <Stack gap={4}>
        {Array.from({ length: NUMBER_OF_LINES }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
          />
        ))}
      </Stack>
    </SectionHeading>
  );
}
