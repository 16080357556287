import { useFormContext, Controller } from 'react-hook-form';
import Input from '@components/input';

type Props = React.ComponentProps<typeof Input> & {
  name: string;
};

export default function RHFInputField({ name, ...otherProps }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Input
          {...field}
          fullWidth
          autoFocus
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error?.message}
          {...otherProps}
        />
      )}
    />
  );
}
