import { useTranslation } from '@desygner/ui-common-translation';
import useResponsive from '@hooks/useResponsive';
import useTimer from '@hooks/useTimer';
import { useRoomContext } from '@livekit/components-react';
import paths from '@router/paths';
import { useConfirm } from 'material-ui-confirm';
import { useEffect } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import Desktop from './desktop';
import Mobile from './mobile';
import { ConnectionState, RoomEvent } from 'livekit-client';
import useQueryParams from '@hooks/useQueryParams';
import useRoomMetadata from '../../hooks/useRoomMetadata';
import { queryClient } from '@providers/ReactQueryProvider';
import useWorkspace from '@hooks/useWorkspace';
import useAppDispatch from '@hooks/useAppDispatch';
import { setThreadId } from '@state/slices/live-session';
import useAppSelector from '@hooks/useAppSelector';

export default function Content({
  shouldLoadHistory,
}: {
  shouldLoadHistory: boolean;
}) {
  const isItDesktop = useResponsive('up', 'md');
  const room = useRoomContext();
  const { t } = useTranslation();
  const timer = useTimer();
  const confirm = useConfirm();
  const navigateTo = useNavigate();
  const { getQueryParamByKey } = useQueryParams();
  const { handleSetMetadata, handleSetRoomStatus } = useRoomMetadata();
  const { getNamespacedQueryKey } = useWorkspace();
  const dispatch = useAppDispatch();
  const storeStatus = useAppSelector((state) => state.liveSession.status);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    dispatch(
      setThreadId(shouldLoadHistory ? getQueryParamByKey('t') : undefined),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    shouldLoadHistory &&
      handleSetRoomStatus(storeStatus === 'active' ? 'loaded' : 'loading');
  }, [shouldLoadHistory, storeStatus, handleSetRoomStatus]);

  useEffect(() => {
    function onMetadataChanged() {
      handleSetMetadata(room.metadata || '{}');
    }

    onMetadataChanged();
    room.on(RoomEvent.RoomMetadataChanged, onMetadataChanged);
    room.once(RoomEvent.Connected, onMetadataChanged);

    return () => {
      room.off(RoomEvent.RoomMetadataChanged, onMetadataChanged);
    };
  }, [room, handleSetMetadata]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const isActiveSession = room.state !== ConnectionState.Disconnected;

      if (!isActiveSession) {
        blocker.proceed?.();
        return;
      }

      (async function () {
        try {
          await confirm({
            title: t('page.liveSession.confirmLeave.title', {
              defaultValue: 'Leave Recording Session',
            }),
            description: t('page.liveSession.confirmLeave.content', {
              defaultValue: 'Are you sure you want to leave recording?',
            }),
            confirmationText: t('page.liveSession.confirmLeave.yes', {
              defaultValue: 'Yes, I want to leave',
            }),
            cancellationText: t('page.liveSession.confirmLeave.no', {
              defaultValue: 'No, I want to continue',
            }),
          });

          queryClient.invalidateQueries({
            queryKey: getNamespacedQueryKey('threads', 'live'),
          });

          navigateTo(paths.live.pathname);
          timer.stopTimer();
          blocker.proceed();

          const encoder = new TextEncoder();
          const encodedData = encoder.encode('{}');
          room.localParticipant.publishData(encodedData, {
            reliable: true,
            topic: 'client_shut',
          });
        } catch (err) {
          blocker.reset();
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, blocker.state]);

  if (isItDesktop) {
    return <Desktop />;
  }

  return <Mobile />;
}
