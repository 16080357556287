import DataRenderer from '@components/data-loader';
import { StyledPopper, StyledStack } from '@components/dropdown/styles';
import type {
  DropdownItemProps,
  OverridePopperComponentProps,
} from '@components/dropdown/types';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, {
  cloneElement,
  forwardRef,
  useMemo,
  useRef,
  useState,
} from 'react';

type Props = Omit<OverridePopperComponentProps, 'sx'> & {
  areOptionsLoading?: boolean;
  haveOptionsError?: boolean;
  trigger: {
    element: React.ReactNode;
    onClick?: (
      event: React.MouseEventHandler<HTMLButtonElement> | undefined,
    ) => void;
  };
  options: (Omit<DropdownItemProps, 'text'> & {
    element: React.ReactNode;
    textForSearch: string;
  })[];
  sx?: {
    menuItemSx?: SxProps;
    popperSx?: SxProps;
    paperSx?: SxProps;
  };
};

/**
 * @description A dropdown component that takes in a trigger element and a list of options to display - NOTE: All refs passed to this component will be forwarded to the root div element
 * @param {React.ReactNode} trigger - The element that will trigger the dropdown
 * @param {DropdownOptionsProps["options"]} options - The options that will be displayed in the dropdown
 * @param {OverridePopperComponentProps} props - The props that will be passed to the Popper component of MUI
 * @param {SxProps} sx - The styles that will be passed to the Popper component
 */
const SearchableDropdown = forwardRef<HTMLDivElement, Props>(
  function SearchableDropdown(
    {
      options,
      trigger: Trigger,
      sx,
      areOptionsLoading,
      haveOptionsError,
      ...otherProps
    },
    ref,
  ) {
    const [open, setOpen] = useState(false);

    const [searchText, setSearchText] = useState('');

    const anchorRef = useRef<HTMLButtonElement>(null);

    function handleToggle(
      value: React.MouseEventHandler<HTMLButtonElement> | undefined | boolean,
    ) {
      if (value == null) return;

      if (typeof value === 'boolean') {
        setOpen(value);
      }

      setOpen((prevOpen) => !prevOpen);
    }

    function handleClose(event: Event | React.SyntheticEvent) {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    function handleListItemOnClick(
      event: React.MouseEvent<HTMLLIElement>,
      item: Props['options'][number],
    ) {
      item.onClick(event, item.value);
      handleClose(event);
    }

    const displayedOptions = useMemo(
      () =>
        options.filter((option) =>
          containsText(option.textForSearch.toString(), searchText),
        ),
      [options, searchText],
    );

    return (
      <>
        {cloneElement(Trigger.element as React.ReactElement, {
          ref: anchorRef,
          onClick: (
            event: React.MouseEventHandler<HTMLButtonElement> | undefined,
          ) => {
            handleToggle(event);
            Trigger.onClick && Trigger.onClick(event);
          },
          'aria-haspopup': 'listbox',
        })}
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          ref={ref}
          transition
          aria-expanded={open ? 'true' : 'false'}
          aria-haspopup={open ? 'true' : 'false'}
          sx={sx?.popperSx}
          {...otherProps}
        >
          {({ TransitionProps }) => {
            return (
              <Grow {...TransitionProps}>
                <Paper
                  square
                  sx={{
                    backgroundColor: 'background.card.main',
                    borderRadius: 2,
                    ...sx?.paperSx,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      onKeyDown={handleListKeyDown}
                      sx={{
                        maxHeight: (theme) => theme.spacing(75),
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      <TextField
                        sx={{ mb: 1.5 }}
                        autoFocus
                        size="small"
                        placeholder="Type to search..."
                        fullWidth
                        autoComplete="off"
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          },
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== 'Escape') {
                            //? Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                          }
                        }}
                      />
                      <DataRenderer
                        data={{
                          emptyMessage: 'Nothing found',
                          length: displayedOptions.length,
                        }}
                        loading={{
                          description: 'Loading...',
                          isLoading: areOptionsLoading || false,
                        }}
                        error={{
                          isError: haveOptionsError || false,
                          title: 'Error loading options',
                        }}
                      >
                        {displayedOptions.map((option, i) => {
                          const hasStartIcon = option.icon?.iconStart;
                          const hasEndIcon = option.icon?.iconEnd;
                          return (
                            <MenuItem
                              key={`${option.value}-${i}`}
                              onClick={(e) => handleListItemOnClick(e, option)}
                              role="option"
                              disableRipple
                              color={option.color}
                              sx={sx?.menuItemSx}
                            >
                              <StyledStack direction="row">
                                {hasStartIcon}
                                <Stack>{option.element}</Stack>
                              </StyledStack>
                              {hasEndIcon}
                            </MenuItem>
                          );
                        })}
                      </DataRenderer>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            );
          }}
        </StyledPopper>
      </>
    );
  },
);

SearchableDropdown.displayName = 'SearchableDropdown';

export default SearchableDropdown;

function containsText(text: string, searchText: string) {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}
