import useCollectAllSmartTags from '@components/pages/live/hooks/useGetAllSmartTags';
import useStartRecordingSession from '@components/pages/live/hooks/useStartRecordingSession';
import StyledStack from '@components/pages/live/sections/start-recording/styles';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import useResponsive from '@hooks/useResponsive';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LoadingButton from '@mui/lab/LoadingButton';

const LIVE_IMAGE_WIDTH = 242;
const LIVE_IMAGE_HEIGHT = 165;

export default function StartRecording() {
  const { t } = useTranslation();

  const { handleStartRecoding, isMutateLiveTokenPending, isPermissionLoading } =
    useStartRecordingSession();

  const isItMobileOrTablet = useResponsive('down', 'md');

  const { areCollectedSmartTagsSuccessfullyLoaded } = useCollectAllSmartTags();

  const isBusy = isMutateLiveTokenPending || isPermissionLoading;

  return (
    <StyledStack gap={7}>
      <img
        src="https://assets.knowz.com/assets/svg/waves.svg"
        alt="knowz live"
        width={LIVE_IMAGE_WIDTH}
        height={LIVE_IMAGE_HEIGHT}
      />
      <Text
        component="h1"
        variant="displayXs"
        weight="bold"
      >
        {t('page.live.grids.transcribe.body.title', {
          defaultValue: 'Record smarter, get AI insights instantly!',
        })}
      </Text>
      <Text
        variant="textMd"
        weight="medium"
      >
        {t('page.live.grids.transcribe.body.subtitle', {
          defaultValue:
            'Tap to record and let our assistant turn your words into valuable insights. Speak freely, and see your thoughts come to life!',
        })}
      </Text>
      <LoadingButton
        disabled={!areCollectedSmartTagsSuccessfullyLoaded}
        startIcon={<GraphicEqIcon />}
        variant="contained"
        size={isItMobileOrTablet ? 'medium' : 'large'}
        onClick={handleStartRecoding}
        loading={isBusy}
      >
        {t('page.live.grids.transcribe.body.button', {
          defaultValue: 'Start Smart Recording',
        })}
      </LoadingButton>
    </StyledStack>
  );
}
