import Text from '@components/text';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { HTMLMotionProps } from 'framer-motion';

export const StyledCharsTypography = styled(Text)<
  HTMLMotionProps<'div'> & React.ComponentProps<typeof Text>
>(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.primary,
  marginBlockStart: theme.spacing(5),
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius * 5,
  display: 'flex',
  flexDirection: 'column',
}));
