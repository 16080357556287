import { useTranslation } from '@desygner/ui-common-translation';
import { auth } from '@lib/agent';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import type { ValidateResetToken } from '@shared-types/auth';

const validateResetToken = async (data: ValidateResetToken) =>
  await auth.validateResetToken(data);

export default function useValidateResetToken() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: validateResetToken,
    onSuccess: () => {
      toast.success(
        t('layout.auth.desktop.modal.steps.verifyCode.toast.success', {
          defaultValue: 'Code has been successfully validated.',
        }),
      );
    },
    onError: (response) => {
      if (response.status === 400) {
        toast.error(
          t('layout.auth.desktop.modal.steps.verifyCode.toast.error', {
            defaultValue: 'Invalid code. Please enter the correct code',
          }),
        );
      } else {
        toast.error(
          t('response.errors.code.520', {
            defaultValue: 'Something went wrong. Please try again later.',
          }),
        );
      }

      console.error(response);
    },
  });
}
