import EllipsisText from '@components/ellipsis-typography';
import CONFIG from '@components/pages/history/config';
import {
  StyledCard,
  StyledImage,
} from '@components/pages/history/sections/thread-listitem/styles';
import Text from '@components/text';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import paths from '@router/paths';
import { GetAllThreadsResponseType } from '@shared-types/threads';
import { formatRelative } from 'date-fns';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const THUMBNAIL_SIZE = 32;

function brokenImageHandler(
  event: React.SyntheticEvent<HTMLImageElement, Event>,
) {
  event.currentTarget.style.display = 'none';
}

const ThreadListItem = forwardRef<HTMLAnchorElement, GetAllThreadsResponseType>(
  ({ description, id, lastInteraction, name, thumbnail }, ref) => {
    return (
      <StyledCard
        ref={ref}
        component={Link}
        to={`${paths.search.pathname}?t=${id}`}
      >
        <Stack gap={4}>
          <EllipsisText
            lineClamp={1}
            sx={{ height: CONFIG.SIZES.TITLE_HEIGHT }}
            variant="textXl"
            weight="semibold"
          >
            {name}
          </EllipsisText>
          {description && (
            <EllipsisText
              sx={{ height: CONFIG.SIZES.DESCRIPTION_HEIGHT, margin: 0 }}
              variant="textSm"
              lineClamp={2}
              color="text.secondary"
            >
              {description}
            </EllipsisText>
          )}

          {thumbnail.length > 0 && (
            <Stack
              direction="row"
              sx={{ flexWrap: 'wrap', height: CONFIG.SIZES.IMAGE_STACK_HEIGHT }}
            >
              <List
                disablePadding
                component={Stack}
                direction="row"
                gap={1}
                sx={{ flexWrap: 'wrap' }}
              >
                {thumbnail.map((singleThumb, index) => (
                  <StyledImage
                    onError={brokenImageHandler}
                    loading="lazy"
                    width={THUMBNAIL_SIZE}
                    height={THUMBNAIL_SIZE}
                    key={index + singleThumb}
                    src={singleThumb}
                    alt={`${name} - ${index}`}
                  />
                ))}
              </List>
            </Stack>
          )}

          <Stack
            direction="row"
            gap={1}
            sx={{ alignItems: 'center', height: CONFIG.SIZES.DATE }}
          >
            <AccessTimeIcon
              fontSize="small"
              color="disabled"
            />
            <Text
              color="text.secondary"
              variant="textXs"
            >
              {formatRelative(lastInteraction, new Date())}
            </Text>
          </Stack>
        </Stack>
      </StyledCard>
    );
  },
);

ThreadListItem.displayName = 'ThreadListItem';

export default ThreadListItem;
