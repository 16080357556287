import { useGetCommandBlockPackages } from '@components/pages/command-designer/hooks/useCommandBlocks';
import Text from '@components/text';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DialogProps } from '@toolpad/core';
import { useState } from 'react';

export default function PackagesDialog({
  open,
  onClose,
}: DialogProps<undefined, string[] | void>) {
  const [packages, setPackages] = useState<string[]>([]);
  const { blockPackages, isLoadingBlockPackages } =
    useGetCommandBlockPackages();

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Text
          variant="textXl"
          fontWeight={600}
          sx={{ mb: 2 }}
        >
          Create New Block
        </Text>
        <Text variant="textSm">
          Select the packages that this block will belong to. Users will be able
          to find this block by searching for these packages.
        </Text>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          freeSolo
          multiple
          loading={isLoadingBlockPackages}
          sx={{ '.MuiInputBase-root': { minHeight: '50px' } }}
          options={blockPackages?.data || []}
          onChange={(event, value) => setPackages([...value])}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingBlockPackages ? (
                        <CircularProgress
                          color="inherit"
                          size={20}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                },
              }}
            />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ mt: 3 }}>
        <Button
          onClick={() => onClose()}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={!packages.length}
          onClick={() => onClose(packages)}
          variant="contained"
        >
          Save Block
        </Button>
      </DialogActions>
    </Dialog>
  );
}
