import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import CrossIcon from '~icons/knowz-iconify/cross';
import NoIcon from '~icons/knowz-iconify/no-rounded';
import WaveIcon from '~icons/knowz-iconify/wave';
import StyledStackButton from '../styles';
import { StyledStack, StyledStackWrapper } from './styles';
import useAppDispatch from '@hooks/useAppDispatch';
import { setVoiceMatch } from '@state/slices/live-session';
import { toast } from 'react-toastify';
import { VoiceMatchType } from '../../../../types';
import useAppSelector from '@hooks/useAppSelector';

type Props = {
  handleCloseModal: VoidFunction;
  speakerRef: string;
  handleSetVoiceMatchType: (type: VoiceMatchType) => void;
};

const AVATAR_SIZE = 32;

export default function Rematch({
  handleCloseModal,
  speakerRef,
  handleSetVoiceMatchType,
}: Props) {
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState<
    'remove-match' | 'rematch' | null
  >(null);
  const dispatch = useAppDispatch();
  const voiceMatch = useAppSelector((state) => state.liveSession.voiceMatch);
  function handleRadioOnChange(
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) {
    setRadioValue(value as 'remove-match' | 'rematch');
  }

  function handleSubmit() {
    if (radioValue === 'remove-match') {
      dispatch(setVoiceMatch({ [speakerRef]: { name: null } }));
      toast.success('Voice match removed');
      handleCloseModal();
    } else if (radioValue === 'rematch') {
      handleSetVoiceMatchType('match');
    }
  }

  return (
    <StyledStackWrapper>
      <Stack>
        <StyledStack direction="row">
          <Text
            variant="textLg"
            weight="semibold"
          >
            {t('page.liveSession.transcriptSetting.voiceMatchSpeaker', {
              defaultValue: 'Voice Match Speaker',
            })}
          </Text>
          <IconButton onClick={handleCloseModal}>
            <CrossIcon fontSize="0.7em" />
          </IconButton>
        </StyledStack>
        <Text variant="textSm">
          {t('page.liveSession.transcriptSetting.voiceMatch.whoMatched', {
            defaultValue: 'Voice is matched to',
          })}
        </Text>
      </Stack>

      <Stack
        direction="row"
        gap={3}
        sx={{
          padding: (theme) => theme.spacing(3, 4),
          my: 5,
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }} />
        <Stack>
          <Text variant="textSm">
            {voiceMatch && voiceMatch[speakerRef].name}
          </Text>
          {/* <Text variant="textXs">email</Text> //TODO: we set it up later */}
        </Stack>
      </Stack>

      <Stack
        component="form"
        gap={3.5}
      >
        <Text
          variant="textMd"
          weight="semibold"
        >
          {t('page.liveSession.transcriptSetting.voiceMatch.editMatch', {
            defaultValue: 'Edit Voice Match',
          })}
        </Text>
        <RadioGroup onChange={handleRadioOnChange}>
          <FormControlLabel
            disableTypography
            sx={{
              justifyContent: 'space-between',
            }}
            labelPlacement="start"
            value="remove-match"
            label={
              <ListItem
                disablePadding
                disableGutters
                sx={{
                  py: 2,
                }}
              >
                <ListItemIcon>
                  <NoIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t(
                    'page.liveSession.transcriptSetting.voiceMatch.removeMatch',
                    {
                      defaultValue: 'Remove Match',
                    },
                  )}
                  primaryTypographyProps={{
                    variant: 'textSm',
                  }}
                />
              </ListItem>
            }
            control={<Radio />}
          />

          <FormControlLabel
            disableTypography
            sx={{
              justifyContent: 'space-between',
            }}
            labelPlacement="start"
            value="rematch"
            label={
              <ListItem
                disablePadding
                disableGutters
                sx={{
                  py: 2,
                }}
              >
                <ListItemIcon>
                  <WaveIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t(
                    'page.liveSession.transcriptSetting.voiceMatch.rematch',
                    {
                      defaultValue: 'Rematch to another user',
                    },
                  )}
                  primaryTypographyProps={{
                    variant: 'textSm',
                  }}
                />
              </ListItem>
            }
            control={<Radio />}
          />
        </RadioGroup>
        <StyledStackButton
          direction="row"
          gap={2}
        >
          <Button
            onClick={handleCloseModal}
            fullWidth
            variant="outlined"
          >
            {t('page.liveSession.transcriptSetting.voiceMatch.buttons.cancel', {
              defaultValue: 'Cancel',
            })}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!radioValue}
            fullWidth
            variant="contained"
          >
            {t(
              'page.liveSession.transcriptSetting.voiceMatch.buttons.continue',
              {
                defaultValue: 'Continue',
              },
            )}
          </Button>
        </StyledStackButton>
      </Stack>
    </StyledStackWrapper>
  );
}
