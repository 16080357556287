import { StyledStack } from '@components/pages/live/sections/cards/smart-tags-card/components/tag-list-item/styles';
import Text from '@components/text';
import Stack from '@mui/material/Stack';

type Props = {
  title: {
    text: string;
    variant: React.ComponentProps<typeof Text>['variant'];
    weight?: React.ComponentProps<typeof Text>['weight'];
  };
  subtitle?: string;
  checkbox: {
    position?: 'start' | 'end';
    Component: React.ReactNode;
  };
};

export default function TagListItem({ subtitle, title, checkbox }: Props) {
  const { Component: CheckboxComponent } = checkbox;
  const isCheckboxEnd = checkbox.position === 'end';

  return (
    <StyledStack
      direction="row"
      gap={2}
    >
      <Stack
        direction="row"
        gap={3}
      >
        {!isCheckboxEnd && CheckboxComponent}
        <Stack>
          <Text
            variant={title.variant}
            weight={title.weight}
          >
            {title.text}
          </Text>
          {subtitle && (
            <Text
              variant="textXs"
              color="text.disabled"
            >
              {subtitle}
            </Text>
          )}
        </Stack>
      </Stack>
      {isCheckboxEnd && CheckboxComponent}
    </StyledStack>
  );
}
