import CloseButton from '@components/source-preview/components/close-button';
import FileIcon from '@components/source-preview/components/file-icon';
import LoadingSkeleton from '@components/source-preview/components/loading-skeleton';
import PathBreadcrumb from '@components/source-preview/components/path-breadcrumb';
import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';
import {
  StyledButtonBase,
  StyledCard,
  StyleDismissTypography,
  StyledRedirectButton,
  StyledRedirectTypography,
  StylePreviewText,
  StylePreviewTitle,
  StyledPreviewFilePath,
} from '@components/source-preview/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import removeMd from 'remove-markdown';
import useResponsive from '@hooks/useResponsive';

export default function SourcePreviewCard() {
  const [showFullPreview, setShowFullPreview] = useState(false);
  const {
    sourcePreviewCard,
    closeSourcePreviewCard,
    isLoading,
    sourceDetailsData,
    handleLinkPath,
  } = useSourcePreview();
  const { t } = useTranslation();
  const isItMobile = useResponsive('down', 'sm');

  const previewText =
    sourcePreviewCard.metadata?.originalText ??
    sourceDetailsData?.summary ??
    '';

  if (sourceDetailsData == null) return null;

  return (
    <Slide
      direction="left"
      in={sourcePreviewCard.isOpen}
      mountOnEnter
      unmountOnExit
    >
      <StyledCard>
        {isLoading ? (
          <LoadingSkeleton
            sourceIcon={
              !isItMobile && <FileIcon fileType={sourceDetailsData.fileType} />
            }
            title={
              <Stack>
                <StylePreviewTitle
                  variant="textMd"
                  weight="bold"
                >
                  {sourceDetailsData?.name}
                </StylePreviewTitle>
                <PathBreadcrumb sourceDetailsData={sourceDetailsData} />
              </Stack>
            }
            close={<CloseButton />}
          />
        ) : (
          <Stack
            gap={5}
            direction="row"
            sx={{
              alignItems: 'flex-start',
            }}
          >
            {!isItMobile && (
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <FileIcon fileType={sourceDetailsData.fileType} />
              </Box>
            )}
            <Stack gap={2}>
              <Stack>
                <StylePreviewTitle
                  variant="textMd"
                  weight="bold"
                >
                  {sourceDetailsData?.name}
                </StylePreviewTitle>

                <PathBreadcrumb sourceDetailsData={sourceDetailsData} />
              </Stack>

              <StylePreviewText variant="textSm">
                Preview: &nbsp;
                {previewText
                  ? showFullPreview
                    ? removeMd(previewText)
                    : `${removeMd(previewText)
                        .slice(0, 80)
                        .split('\n')
                        .slice(0, 3)
                        .join('\n')} ${showFullPreview ? '' : '...'}`
                  : 'waiting for preview'}
              </StylePreviewText>
              {previewText && (
                <StyledButtonBase
                  onClick={() => {
                    setShowFullPreview((prevState) => !prevState);
                  }}
                >
                  {showFullPreview ? 'View Less' : 'Read More'}
                </StyledButtonBase>
              )}

              <Stack sx={{ flexDirection: 'row', gap: 2 }}>
                <ButtonBase onClick={closeSourcePreviewCard}>
                  <StyleDismissTypography variant="textMd">
                    {t('components.knowledgeInfoCard.dismiss', {
                      defaultValue: 'Dismiss',
                    })}
                  </StyleDismissTypography>
                </ButtonBase>
                <StyledRedirectButton
                  onClick={handleLinkPath}
                  size="small"
                  endIcon={<OpenInNewIcon />}
                >
                  <StyledRedirectTypography variant="textMd">
                    {t('components.knowledgeInfoCard.goToFile', {
                      defaultValue: 'Go to File',
                    })}
                  </StyledRedirectTypography>
                </StyledRedirectButton>
              </Stack>
            </Stack>

            <CloseButton />
          </Stack>
        )}
      </StyledCard>
    </Slide>
  );
}
