//TODO: props drilling should be fixed as soon as we start implementing new design
import Accordion from '@components/pages/live-session/sections/content/components/feed/list-item/accordion';
import { FactCheckType, TimeType } from '@shared-types/live';
import { forwardRef, memo } from 'react';
import { motion } from 'framer-motion';
import { StyledCard } from '@components/pages/live-session/sections/content/components/feed/styles';

const Card = memo(
  forwardRef<
    HTMLDivElement,
    FactCheckType & {
      time: TimeType;
    }
  >(({ avatar, name, time, result }, ref) => {
    return (
      <>
        {result.map(({ body, id, outcome, sources }, _index) => (
          <StyledCard
            ref={ref}
            key={id}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Accordion
              ref={ref}
              time={time}
              body={body}
              outcome={outcome}
              sources={sources}
              avatar={avatar}
              name={name}
            />
          </StyledCard>
        ))}
      </>
    );
  }),
);

Card.displayName = 'Card';

export default Card;
