import Text from '@components/text';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';

type Props = React.PropsWithChildren<{
  icon: React.ReactNode;
  title: React.ReactNode;
}>;

export default function SectionHeading({ children, icon, title }: Props) {
  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={2}
        sx={{ alignItems: 'center' }}
      >
        {icon}
        <Text
          component="h2"
          variant="textSm"
          weight="bold"
        >
          {title}
        </Text>
      </Stack>
      <Box component="section">{children}</Box>
    </Stack>
  );
}
