import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import EllipsisText from '@components/ellipsis-typography';
import SourceConfigurationsPopup from '@components/pages/connectors/SourceConfigurationsPopup';
import { StyledStack } from '@components/pages/commands/styles';
import {
  StyledListItem,
  StyledListItemImage,
} from '@components/pages/sso/styles';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import { useCreateMembershipAliasOnSSO } from '@hooks/useCreateMembershipAliasOnSSO';
import useDeleteSourceConfigurationConfig from '@hooks/useDeleteSourceConfigurationConfig';
import useGetAllSourceConfigurations from '@hooks/useGetAllSourceConfigurations';
import { useCreateOneSourceConfiguration } from '@hooks/useSourceConfigurations';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { HashMap } from '@shared-types/utils';
import { useCallback, useEffect, useState } from 'react';
import WebsiteConnectorDialog from './WebsiteConnectorDialog';
import Input from '@components/input';

const IMAGE_SIZE_WIDTH = 300;
const IMAGE_SIZE_HEIGHT = 200;

const ICON_SIZE = 84;
const desygnerUrl = import.meta.env.VITE_DESYGNER_URL || '';
const authUrl = import.meta.env.VITE_KNOWZ_AUTH_URL || '';
const ssoAliases: HashMap<string> = {
  slack: import.meta.env.VITE_KNOWZ_CONNECTOR_SLACK_SSO || '',
  confluence: import.meta.env.VITE_KNOWZ_CONNECTOR_CONFLUENCE_SSO || '',
  ms_teams: import.meta.env.VITE_KNOWZ_CONNECTOR_MS_TEAMS_SSO || '',
  ms_sharepoint: import.meta.env.VITE_KNOWZ_CONNECTOR_MS_SHAREPOINT_SSO || '',
  google_drive: import.meta.env.VITE_KNOWZ_CONNECTOR_GOOGLE_DRIVE_SSO || '',
};

type TextComponentProps = React.ComponentProps<typeof Text>;

const SHARED_PROPS: {
  variant: TextComponentProps['variant'];
  weight: TextComponentProps['weight'];
  component: TextComponentProps['component'];
  lineClamp: number;
} = {
  variant: 'textMd',
  weight: 'semibold',
  component: 'h2',
  lineClamp: 2,
};

export default function Connectors() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [authWindow, setAuthWindow] = useState<Window | null>(null);
  const [handler, setHandler] = useState<string>('none');
  const { mutateAsync: mutateCreateOneSourceConfigurationAsync } =
    useCreateOneSourceConfiguration();
  const { mutateAsync: mutateDeleteSourceConfigurationAsync } =
    useDeleteSourceConfigurationConfig();
  const { mutateAsync: mutateCreateMembershipAliasOnSSOAsync } =
    useCreateMembershipAliasOnSSO();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isWebsitePopupOpen, setIsWebsitePopupOpen] = useState(false);
  const {
    sourceConfigs = [],
    isLoading,
    isError,
    refetch: refetchSourceConfigs,
  } = useGetAllSourceConfigurations();

  const onMessage = useCallback(
    (event: MessageEvent) => {
      let handled = false;

      if (event.origin === authUrl && handler === 'confluence') {
        const { storeKey } = event.data;
        handled = true;
        mutateCreateMembershipAliasOnSSOAsync({
          handler: handler,
          storeKey,
        }).then(() => {
          window.open(
            import.meta.env.VITE_KNOWZ_CONNECTOR_CONFLUENCE_APP,
            '_blank',
          );
        });
      } else if (event.origin === authUrl && handler !== 'none') {
        const { storeKey } = event.data;
        handled = true;
        mutateCreateOneSourceConfigurationAsync({
          handler: handler,
          storeKey,
        });
      }

      if (event.origin === desygnerUrl) {
        const { token, domain } = event.data;
        handled = true;

        if (token && domain) {
          mutateCreateOneSourceConfigurationAsync({
            handler: 'webrand',
            token,
            domain,
          });
        }
      }

      if (handled) {
        authWindow && authWindow.close();
        window.removeEventListener('message', onMessage);
        setAuthWindow(null);
      }
    },
    [authWindow, mutateCreateOneSourceConfigurationAsync],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    const handleAuthResponse = () => {
      const url = new URL(window.location.href);
      const urlParams = new URLSearchParams(window.location.search);
      const state = urlParams.get('state');
      const consent = urlParams.get('admin_consent');

      if (
        (state === 'ms_teams' || state === 'ms_sharepoint') &&
        consent === 'True'
      ) {
        setHandler(state);
        setAuthWindow(
          window.open(
            `${authUrl}/connect/oauth2?alias=${ssoAliases[state]}&process=store`,
            'Knowz Authentication',
            'menubar=1,resizable=1,width=800,height=800',
          ),
        );
      }
      window.history.replaceState({}, document.title, url.pathname);
    };

    handleAuthResponse();

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage, sourceConfigs]);

  const connect = useCallback((handler: string) => {
    setHandler(handler);
    if (handler === 'webrand') {
      setAuthWindow(
        window.open(
          `${desygnerUrl}/integration/knowz/setup`,
          'We Brand Authentication',
          'menubar=1,resizable=1,width=600,height=700',
        ),
      );
    } else if (handler === 'email') {
      setIsPopupOpen(true);
    } else if (handler === 'website') {
      setIsWebsitePopupOpen(true);
    } else if (handler === 'ms_teams') {
      const msTeamsRedirectUrl = `https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=${
        import.meta.env.VITE_KNOWZ_CONNECTOR_MS_TEAMS_CLIENT_ID
      }&scope=.default&state=${handler}&redirect_uri=${encodeURIComponent(
        `${import.meta.env.VITE_KNOWZ_WEBSITE_URL}/app/settings/connectors`,
      )}`;
      window.open(msTeamsRedirectUrl);
    } else if (handler === 'ms_sharepoint') {
      const msSharepointRedirectUrl = `https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=${
        import.meta.env.VITE_KNOWZ_CONNECTOR_MS_SHAREPOINT_CLIENT_ID
      }&scope=.default&state=${handler}&redirect_uri=${encodeURIComponent(
        `${import.meta.env.VITE_KNOWZ_WEBSITE_URL}/app/settings/connectors`,
      )}`;
      window.open(msSharepointRedirectUrl);
    } else {
      setAuthWindow(
        window.open(
          `${authUrl}/connect/oauth2?alias=${ssoAliases[handler]}&process=store`,
          'Knowz Authentication',
          'menubar=1,resizable=1,width=800,height=800',
        ),
      );
    }
  }, []);

  const handleWebsiteDelete = async (id: number) => {
    await mutateDeleteSourceConfigurationAsync(id);
    setIsWebsitePopupOpen(false);
    setHandler('none');
    refetchSourceConfigs();
  };

  const handleWebsiteSubmit = async (
    websiteUrl: string,
    scanInterval: string,
  ) => {
    await mutateCreateOneSourceConfigurationAsync({
      handler: handler,
      website: websiteUrl,
      websiteInterval: scanInterval,
    });
    setIsWebsitePopupOpen(false);
    setHandler('none');
    refetchSourceConfigs();
  };

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/svg/waves.svg"
        alt="knowz Connectors"
        width={IMAGE_SIZE_WIDTH}
        height={IMAGE_SIZE_HEIGHT}
      />

      <Text
        component="h1"
        variant="displaySm"
      >
        {t('layout.page.connectors.hero.title', {
          defaultValue: 'Bring your knowledge',
        })}
      </Text>

      <StyledStack gap={5}>
        <Input
          placeholder={t('page.connectors.search.title', {
            defaultValue: 'Find Connectors',
          })}
          onChange={(event) =>
            setFilter(event.target.value.toLocaleLowerCase())
          }
        />

        <ListboxComponent>
          <StyledListItem
            slots={{ root: 'div' }}
            onClick={() => connect('webrand')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://s3.amazonaws.com/virginia.webrand.com/virginia/company/5899/logo/original/qqwwqryrrwyqrry/group-7.svg"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>We Brand</EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            slots={{ root: 'div' }}
            onClick={() => connect('slack')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>Slack</EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            slots={{ root: 'div' }}
            onClick={() => connect('google_drive')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://png.pngtree.com/png-vector/20230817/ourmid/pngtree-google-drive-data-processing-platform-vector-png-image_9183272.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>Google-Drive</EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            slots={{ root: 'div' }}
            onClick={() => connect('email')}
            data-handler="email"
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://cdn0.iconfinder.com/data/icons/apple-apps/100/Apple_Mail-512.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText
                variant="textMd"
                weight="semibold"
                component="h2"
                lineClamp={2}
              >
                Email
              </EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            slots={{ root: 'div' }}
            onClick={() => connect('ms_teams')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://download.logo.wine/logo/Microsoft_Teams/Microsoft_Teams-Logo.wine.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>MS-Teams</EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            components={{ Root: 'div' }}
            onClick={(event) => connect('ms_sharepoint')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://download.logo.wine/logo/SharePoint/SharePoint-Logo.wine.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>MS-Sharepoint</EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            slots={{
              root: 'div',
            }}
            onClick={(event) => connect('confluence')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://www.svgrepo.com/show/353597/confluence.svg"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>Confluence</EllipsisText>
            </Box>
          </StyledListItem>

          <StyledListItem
            slots={{ root: 'div' }}
            onClick={() => connect('website')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisText {...SHARED_PROPS}>Website</EllipsisText>
            </Box>
          </StyledListItem>

          <Dialog
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
          >
            <SourceConfigurationsPopup connect={connect} />
          </Dialog>

          {!isLoading && (
            <WebsiteConnectorDialog
              isOpen={isWebsitePopupOpen}
              onClose={() => setIsWebsitePopupOpen(false)}
              onSubmit={handleWebsiteSubmit}
              sourceConfigs={sourceConfigs}
              onDelete={handleWebsiteDelete}
            />
          )}
        </ListboxComponent>
      </StyledStack>
    </>
  );
}
