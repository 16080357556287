import useLiveKitTranscriber from '@components/pages/live-session/hooks/useLiveKitTranscriber';
import Text from '@components/text';
import { useRoomContext } from '@livekit/components-react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

export default function LiveKitAudioTranscriber() {
  const room = useRoomContext();
  const { transcript } = useLiveKitTranscriber(room);

  const characterVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <Box>
      <Text
        sx={{ ml: 12, mr: 4, paddingBottom: 4 }}
        variant="textSm"
      >
        {transcript?.split('').map((char, index) => (
          <motion.span
            initial="hidden"
            animate="visible"
            variants={characterVariants}
            transition={{ duration: 0.2, delay: index * 0.01, ease: 'linear' }}
            key={index}
          >
            {char}
          </motion.span>
        ))}
      </Text>
    </Box>
  );
}
