import RHFInputField from '@components/react-hook-form/RHFInputField';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';

type Props = {
  name: string;
  disabled: boolean;
  helperText?: string;
  placeholder: string;
};

export default function PasswordField({
  name,
  disabled,
  helperText,
  placeholder,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <RHFInputField
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      size="small"
      name={name}
      disabled={disabled}
      helperText={helperText}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? (
                  <RemoveRedEyeIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
