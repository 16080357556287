/* eslint-disable import/prefer-default-export */
import {
  StepComponentView,
  StepComponentViewContext,
  StepComponentViewFactory,
  StepContext,
  createContainerStepComponentViewFactory,
  createTaskStepComponentViewFactory,
} from 'sequential-workflow-designer';
import { BlockStepComponentViewConfiguration } from './blockStepComponentViewConfiguration';
import { BlockStep } from './blockStepExtension';

export const createBlockStepComponentViewFactory = (cfg: BlockStepComponentViewConfiguration): StepComponentViewFactory => {
  const containerStepComponentViewFactory = createContainerStepComponentViewFactory(cfg);
  const taskStepComponentViewFactory = createTaskStepComponentViewFactory(false, cfg);

  return (parentElement: SVGElement, stepContext: StepContext<BlockStep>, viewContext: StepComponentViewContext): StepComponentView => {
    const { step } = stepContext;

    return (step.isExpanded)
      ? containerStepComponentViewFactory(parentElement, stepContext, viewContext)
      : taskStepComponentViewFactory(parentElement, stepContext, viewContext);
  };
};
