import KnowledgeBaseProvider from '@components/pages/knowledge-base/providers/KnowledgeBaseProvider';
import StyledStack from '@components/pages/knowledge-base/styles';
import SourcePreviewProvider from '@components/source-preview/providers/SourcePreviewProvider';
import CapacityIndicator from '@components/pages/knowledge-base/components/capacity-indicator';

export default function AdminKnowledgeBase() {
  return (
    <KnowledgeBaseProvider>
      <SourcePreviewProvider>
        <StyledStack gap={6}>
          <KnowledgeBaseProvider.AddKnowledgeBaseModal />
          <StyledStack sx={{ p: 0 }}>
            <KnowledgeBaseProvider.Breadcrumb
              knowledgeCapacityIndicator={<CapacityIndicator />}
            />
            <KnowledgeBaseProvider.Header />
          </StyledStack>
          <KnowledgeBaseProvider.Table />
        </StyledStack>
      </SourcePreviewProvider>
    </KnowledgeBaseProvider>
  );
}
