import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ArcProgressWithTooltipType } from '../../types';
import {
  StyledBox,
  StyledMuiCircularProgress,
  StyledText,
  StyledWrapperBox,
} from './styles';

const THICKNESS = 4.75;
const ARC_ID = 'arc-gradient';

export default function ArcProgress({
  size = 64,
  value,
  ...otherProps
}: Omit<ArcProgressWithTooltipType, 'withTooltip'>) {
  const isValueLow = (value && value <= 10) ?? false;

  return (
    <StyledWrapperBox size={size}>
      <Box>
        <StyledMuiCircularProgress
          variant="determinate"
          sx={{
            color: 'background.card.main',
            strokeLinecap: 'round',
          }}
          thickness={THICKNESS}
          value={50}
          size={size}
        />
        <svg
          width={0}
          height={0}
        >
          <defs>
            <linearGradient
              id={ARC_ID}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              {/* //TODO: design system */}
              <stop
                offset="0%"
                stopColor={isValueLow ? '#FF1E1E' : '#36BFFA'}
              />
              <stop
                offset="100%"
                stopColor={isValueLow ? '#FF48A0' : '#0040C1'}
              />
            </linearGradient>
          </defs>
        </svg>
        {typeof value !== 'undefined' && (
          <StyledBox>
            <CircularProgress
              thickness={THICKNESS}
              sx={{
                'svg circle': { stroke: `url(#${ARC_ID})` },
                strokeLinecap: 'round',
              }}
              {...otherProps}
              size={size}
              value={value / 2}
            />
          </StyledBox>
        )}

        <StyledText variant="textXs">{value}%</StyledText>
      </Box>
    </StyledWrapperBox>
  );
}
