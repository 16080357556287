import { useTranslation } from '@desygner/ui-common-translation';
import { auth } from '@lib/agent';
import { ResetPasswordType } from '@shared-types/auth';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const resetPassword = async (data: ResetPasswordType) =>
  await auth.resetPassword(data);

export default function useResetPassword() {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success(
        t('layout.auth.desktop.modal.steps.resetPassword.response.success', {
          defaultValue: 'Your password has been successfully reset.',
        }),
      );
    },
    onError: (error) => {
      if (
        isAxiosError(error) &&
        error.response &&
        error.response.status <= 400 &&
        error.response.status < 500
      ) {
        toast.error(
          t('response.errors.code.400', {
            defaultValue: 'Invalid request. Please try again.',
          }),
        );
        return;
      }
      if (
        isAxiosError(error) &&
        error.response &&
        error.response.status >= 500 &&
        error.response.status < 600
      ) {
        toast.error(
          t('response.errors.code.520', {
            defaultValue: 'Something went wrong. Please try again later.',
          }),
        );
        return;
      }
      console.error(error);
      return;
    },
  });
}
