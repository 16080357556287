import InvitationForm from '@components/invitation-form';
import Modal from '@components/modal';
import StyledStack from '@components/pages/knowledge-base/components/header/styles';
import SkeletonLoading from '@components/pages/users/header/SkeletonLoading';
import useGetWorkspaceById from '@components/pages/users/hooks/useGetWorkspaceById';
import Text from '@components/text';
import Heading from '@components/workspace/setup/components/heading';
import WorkspaceSetupProvider from '@components/workspace/setup/providers/WorkspaceSetupProvider';
import { useTranslation } from '@desygner/ui-common-translation';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useState } from 'react';

const MODAL_WIDTH = 500;

export default function Header() {
  const { t } = useTranslation();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { isWorkspaceDataLoading, workspaceData } = useGetWorkspaceById();

  function handleCloseModal() {
    setIsInviteModalOpen(!isInviteModalOpen);
  }

  return (
    <StyledStack>
      <Modal
        paddingType="sm"
        open={isInviteModalOpen}
        onClose={handleCloseModal}
        width={{
          xs: 320,
          md: MODAL_WIDTH,
        }}
        height="auto"
      >
        <WorkspaceSetupProvider>
          <InvitationForm
            onSubmitHandler={handleCloseModal}
            skippable={false}
            shouldShowWorkspaceOwner={false}
            heading={
              <Heading
                title={t(
                  'page.workspace.createWorkspaceModal.step3.form.email.label',
                  {
                    defaultValue: 'Workspace Name',
                  },
                )}
              />
            }
          />
        </WorkspaceSetupProvider>
      </Modal>

      {isWorkspaceDataLoading ? (
        <SkeletonLoading />
      ) : (
        <Text variant="displayXs">{workspaceData?.name}</Text>
      )}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleCloseModal}
      >
        {t('page.workspace.header.button', {
          defaultValue: 'Invite',
        })}
      </Button>
    </StyledStack>
  );
}
