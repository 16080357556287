import TxtFileIcon from '~icons/knowz-iconify/txt-file';
import PDFFileIcon from '~icons/knowz-iconify/pdf-file';
import DocxFileIcon from '~icons/knowz-iconify/docx-file';
import MarkdownFileIcon from '~icons/knowz-iconify/markdown-file';
import JsonFileIcon from '~icons/knowz-iconify/json-file';

const MIME_ENUM_TYPES = {
  pdf: 'pdf',
  docx: 'docx',
  md: 'md',
  json: 'json',
  txt: 'txt',
  default: 'default',
} as const;

type MimeTypes = keyof typeof MIME_ENUM_TYPES;

type FileIconMapping = {
  [key in MimeTypes]: React.ReactNode;
};

const FONT_SIZE = '3em';

const FILE_ICONS: FileIconMapping = {
  pdf: <PDFFileIcon fontSize={FONT_SIZE} />,
  docx: <DocxFileIcon fontSize={FONT_SIZE} />,
  md: <MarkdownFileIcon fontSize={FONT_SIZE} />,
  json: <JsonFileIcon fontSize={FONT_SIZE} />,
  txt: <TxtFileIcon fontSize={FONT_SIZE} />,
  default: <TxtFileIcon fontSize={FONT_SIZE} />,
};

type Props = {
  fileType: MimeTypes;
};

export default function FileIcon({ fileType }: Props) {
  const Icon = FILE_ICONS[fileType] || FILE_ICONS.default;
  return Icon;
}
