import DoneIcon from '@mui/icons-material/Done';
import StyledListItem from '@components/upgrade-modal/components/card/key-features/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { Fragment } from 'react';

type Props = {
  keys: string[];
};

export default function KeyFeatures({ keys }: Props) {
  return (
    <StyledListItem disablePadding>
      <Stack
        gap={2}
        sx={{ width: '100%' }}
      >
        {keys.map((key, index) => (
          <Fragment key={key}>
            <Stack
              direction="row"
              sx={{ alignItems: 'center' }}
            >
              <ListItemIcon>
                <DoneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: index === 0 ? 'textSm' : 'textMd',
                  fontWeight: index === 0 ? 600 : 500,
                }}
                primary={key}
              />
            </Stack>
          </Fragment>
        ))}
      </Stack>
    </StyledListItem>
  );
}
