import { useTranslation } from '@desygner/ui-common-translation';
import { sources } from '@lib/agent';
import { DeleteManyRequestType } from '@shared-types/sources';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

type Props = {
  onSuccess:
    | ((
        data: AxiosResponse<unknown, unknown> | undefined,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  onSettled?:
    | ((
        data: unknown,
        error: Error | null,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  onMutate?: ((variables: DeleteManyRequestType) => unknown) | undefined;
};

export default function useDeleteManyResources({
  onSuccess,
  onSettled,
  onMutate,
}: Props) {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: ['deleteManyResources'],
    mutationFn: async (selectedItems: DeleteManyRequestType) =>
      await sources.deleteMany(selectedItems),
    onSuccess,
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error(`An error occurred while deleting a source: ${error}`);
    },
    onSettled,
    onMutate,
  });
}
