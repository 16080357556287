import Markdown from '@components/markdown';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import TextToSpeechIconButton from '@components/pages/search/components/TextToSpeechIconButton';
import Icon from '@mui/material/Icon';
import useAppSelector from '@hooks/useAppSelector';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { useTranslation } from '@desygner/ui-common-translation';
import { SourceLinksType } from '@shared-types/search-result/streaming';
import { useDispatch } from 'react-redux';

type Props = {
  answer?: string;
  id: string;
  type: 'websocket' | 'history';
  links?: Array<SourceLinksType>;
};

const ORB_ICON_ELEMENT = (
  <Icon>
    <img
      width={20}
      height={20}
      src="https://assets.knowz.com/assets/svg/orb---small.png"
      alt="answer section"
    />
  </Icon>
);

export default function Answer({ answer, id, type, links }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { threadId } = useGetThreadIdFromQueryParam();

  const currentAudioPlayingId = useAppSelector(
    (state) => state.searchResult.currentAudioPlayingId,
  );

  const audioMetadata = useAppSelector(
    (state) => state.searchResult.audioMetadata,
  );
  const WSTextToSpeechIsLoading = useAppSelector(
    (state) => state.searchResult.WSTextToSpeechIsLoading,
  );

  if (answer == null || answer.length === 0) return null;

  async function togglePlayPause() {
    dispatch({
      type: 'searchResult/textToSpeechEmitted',
      payload: {
        id,
        threadId,
        text: answer,
      },
    });
  }

  return (
    <SectionHeading
      title={
        <>
          {t('page.search.sections.answer', {
            defaultValue: 'Answer',
          })}
          &nbsp;
          {typeof window.MediaSource === 'function' && (
            <TextToSpeechIconButton
              disabled={WSTextToSpeechIsLoading || type === 'websocket'}
              text={answer}
              onClick={togglePlayPause}
            >
              {audioMetadata &&
              currentAudioPlayingId &&
              audioMetadata[id]?.isPlaying ? (
                <PauseCircleOutlinedIcon fontSize="small" />
              ) : (
                <RecordVoiceOverRoundedIcon fontSize="small" />
              )}
            </TextToSpeechIconButton>
          )}
        </>
      }
      icon={ORB_ICON_ELEMENT}
    >
      <Markdown links={links}>{answer}</Markdown>
    </SectionHeading>
  );
}
