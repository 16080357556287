import FormProvider from '@components/react-hook-form/FormProvider';
import RHFSearchModeSelect from '@components/search-form/react-hook-form-search-mode-select';
import searchFormSchema from '@components/search-form/schema';
import {
  StyledRHFInputField,
  StyledSearchButton,
  StyledSearchFormWrapper,
} from '@components/search-form/styles';
import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import useAppSelector from '@hooks/useAppSelector';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { AskAISearchType } from '@shared-types/search-result/types';
import { SubmitHandler, useForm } from 'react-hook-form';

type SearchFormValuesType = {
  name: string;
  mode: AskAISearchType['mode'];
};

type SearchFormType = {
  onSubmit: SubmitHandler<SearchFormValuesType>;
  sx?: SxProps;
};

export default function SearchForm({ onSubmit, sx }: SearchFormType) {
  const { t } = useTranslation();

  const mode = useAppSelector((state) => state.searchResult.mode);

  const defaultValues: SearchFormValuesType = {
    name: '',
    mode,
  };

  const methods = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;

  return (
    <StyledSearchFormWrapper sx={sx}>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          direction="row"
          gap={2}
          sx={{
            alignItems: 'center',
            marginBlockEnd: 2.5,
            marginBlockStart: 1, //TODO: remove this when upload and screen implemented
            justifyContent: 'flex-end',
          }}
        >
          {/* <Tooltip //TODO: implement this feature
            title={t('comingSoon', {
              defaultValue: 'Coming soon',
            })}
            placement="top"
          >
            <Stack direction="row">
              <IconButton disabled>
                <UploadFileOutlinedIcon />
              </IconButton>
              <IconButton disabled>
                <CameraOutlinedIcon />
              </IconButton>
            </Stack>
          </Tooltip> */}
          <RHFSearchModeSelect name="mode" />
        </Stack>
        <StyledRHFInputField
          role="search"
          type="text"
          name="name"
          disabled={isSubmitting}
          autoComplete="off"
          helperText={undefined} //? we don't want to show the error message
          placeholder={t('page.home.hero.search.placeholder', {
            defaultValue: 'Ask anything...',
          })}
          endAdornment={
            <StyledSearchButton
              variant="outlined"
              color="inherit"
              endIcon={<SearchIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || isSubmitting}
              disableRipple
              disableTouchRipple
              disableFocusRipple
            >
              <Text
                component="span"
                variant="textSm"
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                {t('page.home.hero.search.button', {
                  defaultValue: 'Uncover secrets',
                })}
              </Text>
            </StyledSearchButton>
          }
        />
      </FormProvider>
    </StyledSearchFormWrapper>
  );
}
