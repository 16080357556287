import Input from '@components/input';
import ActionDrawer from '@components/pages/knowledge-base/components/table/action-drawer';
import Action from '@components/pages/knowledge-base/components/table/cells/action';
import Format from '@components/pages/knowledge-base/components/table/cells/format';
import Location from '@components/pages/knowledge-base/components/table/cells/location';
import Name from '@components/pages/knowledge-base/components/table/cells/name';
import Permission from '@components/pages/knowledge-base/components/table/cells/permission';
import SyncStatus from '@components/pages/knowledge-base/components/table/cells/sync-status';
import TABLE_CONFIG from '@components/pages/knowledge-base/components/table/config';
import Skeleton from '@components/pages/knowledge-base/components/table/loading-skeleton';
import DataNotFound from '@components/pages/knowledge-base/components/table/page/data-not-found';
import { StyledPaper } from '@components/pages/knowledge-base/components/table/styles';
import UppyUpload from '@components/pages/knowledge-base/components/uppy-upload';
import KNOWLEDGE_BASE_CONFIG from '@components/pages/knowledge-base/config';
import useDebounce from '@components/pages/knowledge-base/hooks/useDebounce';
import useGetFoldersDataByIdLazily from '@components/pages/knowledge-base/hooks/useGetFoldersDataById';
import useGetSourcesDataByFolderId from '@components/pages/knowledge-base/hooks/useGetSourcesDataByFolderId';
import useGetSourcesDataByQuery from '@components/pages/knowledge-base/hooks/useGetSourcesDataByQuery';
import Pagination from '@components/pagination';
import { useTranslation } from '@desygner/ui-common-translation';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
} from '@dnd-kit/core';
import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import IconButton from '@mui/material/IconButton';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import { FolderType } from '@shared-types/folders';
import { SourceType, SourceWithMetaDataType } from '@shared-types/sources';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import CrossIcon from '~icons/knowz-iconify/cross';
import SearchIcon from '~icons/knowz-iconify/search';
import useMoveFolderByCurrentFolderId from '../../hooks/useMoveFolderByCurrentFolderId';
import useMoveSourceByCurrentFolderId from '../../hooks/useMoveSourceByCurrentFolderId';
import SelectCell from './cells/select';
import SelectHeader from './headers/select';
import BodyRow from './row/body';
import HeadRow from './row/head';
import calculateSyncStatusLabel from './utils/calculateSyncStatusLabel';
import { Button, Stack } from '@mui/material';
import useDeleteManyResources from '../../hooks/useDeleteManyResources';
import { useConfirm } from 'material-ui-confirm';
import { queryClient } from '@providers/ReactQueryProvider';
import { toast } from 'react-toastify';

const DEBOUNCE_MAKING_REQUEST_IN_MS = 500;
const REFETCH_INTERVAL_IN_MS = 2000;

export default function Table() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>(() => {
    const search =
      new URLSearchParams(window.location.search).get('search') || '';
    return search;
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const { getNamespacedQueryKey } = useWorkspace();
  const { getQueryParamByKey } = useQueryParams();
  const path = getQueryParamByKey<'path' | null>('path');

  const page = Number(getQueryParamByKey('page') || 1);

  const debouncedSearch = useDebounce(
    searchQuery,
    DEBOUNCE_MAKING_REQUEST_IN_MS,
  );

  const {
    folderData,
    isFolderDataError,
    isFolderDataPending,
    isFolderDataFetching,
  } = useGetFolderDataByPath({ path });

  const { foldersData, isFoldersDataError, isFoldersDataPending } =
    useGetFoldersDataByIdLazily({
      folderId: path === null ? null : folderData?.id,
    });

  const { sourcesData, isSourcesDataError, isSourcesDataPending } =
    useGetSourcesDataByFolderId({
      folderId: folderData?.id as number | null,
      limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
      page: page,
      refetchInterval: ({ state }) => {
        const data = state.data?.data as unknown;
        if (!Array.isArray(data)) {
          return false;
        }
        const foundASourceInProgress = (data as SourceType[]).some(
          (source) =>
            calculateSyncStatusLabel({
              isDigested: source.isDigested,
              isDigestedFail: source.isDigestedFail,
            }) === 'in_progress',
        );
        return foundASourceInProgress ? REFETCH_INTERVAL_IN_MS : false;
      },
    });

  const {
    filteredSourcesData,
    isFilteredSourcesDataError,
    isFilteredSourcesDataLoading,
  } = useGetSourcesDataByQuery({
    query: debouncedSearch,
    limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
    folderId: folderData?.id ?? null,
    page,
  });

  const { mutateAsync: mutateMoveSourceAsync } = useMoveSourceByCurrentFolderId(
    {
      id: folderData?.id,
    },
  );

  const { mutateAsync: mutateMoveFolderAsync } = useMoveFolderByCurrentFolderId(
    {
      id: folderData?.id,
    },
  );

  const [openDrawerById, setOpenDrawerById] = useState<number | null>(null);

  const resources = useMemo(
    () => [...(foldersData || []), ...(sourcesData?.data || [])],
    [foldersData, sourcesData],
  );
  const { setupMode } = useWorkspace();

  const areResourcesPending =
    isSourcesDataPending ||
    isFoldersDataPending ||
    isFolderDataPending ||
    isFilteredSourcesDataLoading;
  const isResourceEmpty = resources.length === 0;

  const hasResourceError =
    isFolderDataError ||
    isFoldersDataError ||
    isSourcesDataError ||
    isFilteredSourcesDataError;

  function handleToggleActionDrawer(id: number | null) {
    setOpenDrawerById(id);
  }

  const columns = useMemo<ColumnDef<FolderType | SourceType>[]>(
    () => [
      {
        id: 'select',
        header: (props) => <SelectHeader {...props} />,
        cell: (props) => <SelectCell {...props} />,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        cell: (props) => <Name {...props} />,
      },
      {
        header: 'Format',
        accessorKey: 'fileType',
        cell: (props) => <Format {...props} />,
      },
      {
        header: 'Location',
        accessorKey: 'sourceConfiguration',
        cell: (props) => <Location {...props} />,
      },
      {
        header: 'Sync Status',
        cell: (props) => <SyncStatus {...props} />,
      },
      {
        header: 'Permission',
        accessorKey: 'examplesMemberships',
        cell: (props) => <Permission {...props} />,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        cell: (props) => (
          <Action
            {...props}
            handleToggleActionDrawer={handleToggleActionDrawer}
          />
        ),
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, setupMode],
  );

  const tableData = useReactTable(
    (filteredSourcesData?.data || []).length > 0
      ? {
          data: filteredSourcesData?.data || [],
          columns,
          getCoreRowModel: getCoreRowModel(),
          getFilteredRowModel: getFilteredRowModel(),
          manualPagination: true,
          enableRowSelection: true,
          getRowId: (row) => row.id.toString(),
          onRowSelectionChange: setRowSelection,
          state: {
            rowSelection,
          },
        }
      : {
          data: resources,
          columns,
          getCoreRowModel: getCoreRowModel(),
          getFilteredRowModel: getFilteredRowModel(),
          manualPagination: true,
          enableRowSelection: true,
          getRowId: (row) => row.id.toString(),
          onRowSelectionChange: setRowSelection,
          state: {
            rowSelection,
          },
        },
  );

  const confirm = useConfirm();

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      setSearchQuery('');
    }
  }

  function handleClearSearch() {
    setSearchQuery('');
  }

  function handleSearchQuery(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
  }

  function handleMoveSources(e: DragEndEvent) {
    const { active, over } = e;
    if (!active || !over) return;
    const isDroppingOnASource = over.data.current?.type === 'source';

    if (isDroppingOnASource || active.id === over.id) return;

    const overId = over?.id as number | undefined;
    const activeId = active.id as number;
    const { name, type } = active.data.current as {
      type: 'source' | 'folder';
      name: string;
    };

    if (!overId) return;

    if (type === 'source') {
      mutateMoveSourceAsync({
        id: activeId,
        folderToMove: overId,
        name,
      });
      return;
    }

    mutateMoveFolderAsync({
      id: activeId,
      folderToMove: overId,
      name,
    });
  }
  const { mutate: deleteManySources } = useDeleteManyResources({
    onMutate: () => {
      tableData.resetRowSelection();
      const calculateFolderId =
        typeof folderData?.id === 'undefined' ? null : folderData?.id;
      const folderQueryKey = getNamespacedQueryKey(
        'folders',
        calculateFolderId,
      );
      const prevFolders = queryClient.getQueryData<FolderType[] | null>(
        folderQueryKey,
      );
      queryClient.setQueryData(folderQueryKey, () => {
        if (!prevFolders) return [];
        return prevFolders?.filter((folder) => !rowSelection[folder.id]);
      });

      const isItRootFolder = folderData?.id === null;
      const queryKey = getNamespacedQueryKey('sources', {
        ...(!isItRootFolder && { folderId: folderData?.id }),
        page,
      });

      const prevSources =
        queryClient.getQueryData<SourceWithMetaDataType | null>(queryKey);

      queryClient.setQueryData(queryKey, (): SourceWithMetaDataType => {
        if (!prevSources || !prevSources.data)
          return {
            meta: prevSources!.meta,
            data: [],
          };
        return {
          meta: prevSources.meta,
          data: prevSources.data.filter((source) => !rowSelection[source.id]),
        };
      });
    },
    onSuccess: () => {
      toast.success(
        t('page.knowledgeBase.bulkActions.toast.success', {
          defaultValue: 'Sources have been deleted successfully.',
        }),
      );
    },
    onSettled: () => {
      tableData.resetRowSelection();
      queryClient.invalidateQueries({
        exact: false,
        predicate(query) {
          return query.queryKey.includes('sources');
        },
      });
      queryClient.invalidateQueries({
        exact: false,
        predicate(query) {
          return query.queryKey.includes('folders');
        },
      });
    },
  });

  useEffect(() => {
    tableData.resetRowSelection();
  }, [tableData, path, setupMode, resources.length]);

  async function handleBulkDelete() {
    const foldersToDelete: number[] = [];
    const sourcesToDelete: number[] = [];
    tableData.getRowModel().rows.forEach((row) => {
      if (rowSelection[row.id]) {
        const isItFolder = 'breadcrumb' in row.original;
        if (isItFolder) {
          foldersToDelete.push(row.original.id);
        } else {
          sourcesToDelete.push(row.original.id);
        }
      }
    });
    confirm({
      title: t('page.knowledgeBase.bulkActions.delete.title', {
        defaultValue: 'Delete sources',
      }),
      description: t('page.knowledgeBase.bulkActions.delete.content', {
        defaultValue: 'Are you sure you want to delete these sources?',
      }),
    })
      .then(() => {
        deleteManySources({
          folders: foldersToDelete,
          sources: sourcesToDelete,
        });
      })
      .catch((error) => console.error(error));
  }

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const paginationPageCount =
    filteredSourcesData?.data.length !== 0
      ? filteredSourcesData?.meta.pagination.pageCount ||
        sourcesData?.meta.pagination.pageCount ||
        0
      : 0;

  return !areResourcesPending && isResourceEmpty ? (
    <UppyUpload />
  ) : (
    <>
      <ActionDrawer
        anchor="right"
        isDrawerOpenById={openDrawerById}
        onClose={() => handleToggleActionDrawer(null)}
      />
      <Stack
        direction="row"
        gap={3}
      >
        <Input
          placeholder={t(
            'page.assistants.knowledgeBase.table.search.placeholder',
            {
              defaultValue: 'Search sources by name',
            },
          )}
          sx={{
            width: {
              xs: '100%',
              md: 250,
            },
          }}
          value={searchQuery}
          startAdornment={<SearchIcon />}
          endAdornment={
            searchQuery && (
              <IconButton
                size="small"
                edge="end"
                onClick={handleClearSearch}
              >
                <CrossIcon />
              </IconButton>
            )
          }
          onChange={handleSearchQuery}
          onKeyDown={handleKeyDown}
        />
        {(tableData.getIsSomeRowsSelected() ||
          tableData.getIsAllRowsSelected()) && (
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleBulkDelete}
            sx={{ flexShrink: 0 }}
          >
            {t('page.knowledgeBase.bulkActions.button', {
              defaultValue: 'Delete All',
            })}
          </Button>
        )}
      </Stack>
      {filteredSourcesData?.data.length === 0 ? (
        <DataNotFound />
      ) : (
        <DndContext
          onDragEnd={handleMoveSources}
          sensors={[mouseSensor]}
        >
          <StyledPaper>
            <MuiTable>
              <MuiTableHead>
                <MuiTableRow>
                  {tableData?.getHeaderGroups().map((headerGroup) => {
                    return headerGroup.headers.map((header) => (
                      <HeadRow
                        key={header.id}
                        {...header}
                      />
                    ));
                  })}
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {areResourcesPending || isFolderDataFetching ? (
                  <Skeleton
                    headItems={TABLE_CONFIG.ADMIN_TABLE_HEADER}
                    numberOfRows={KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT}
                  />
                ) : (
                  tableData.getRowModel().rows.map((row) => (
                    <BodyRow
                      key={row.id}
                      {...row}
                    />
                  ))
                )}
              </MuiTableBody>
            </MuiTable>
          </StyledPaper>
        </DndContext>
      )}
      {paginationPageCount > 0 && (
        <Pagination pageCount={paginationPageCount} />
      )}
    </>
  );
}
