import MuiTableSortLabel from '@mui/material/TableSortLabel';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import { flexRender, Header } from '@tanstack/react-table';
import { StyledMuiTableHeadCell } from '../../styles';

export default function Row({
  id,
  column,
  getContext,
}: Header<SourceType | FolderType, unknown>) {
  const isItSelectCell = id === 'select';
  const areTheySelectOrActionCell = isItSelectCell || id === 'action';

  return (
    <StyledMuiTableHeadCell
      sx={{
        ...(isItSelectCell && {
          width: '10px',
        }),
      }}
    >
      <MuiTableSortLabel
        active={!areTheySelectOrActionCell}
        hideSortIcon={areTheySelectOrActionCell}
        // IconComponent={SwapVertIcon} {/*TODO: we added the icon once we have sorting functionality */}
        IconComponent={null}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <span>{flexRender(column.columnDef.header, getContext())}</span>
      </MuiTableSortLabel>
    </StyledMuiTableHeadCell>
  );
}
