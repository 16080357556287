import { folders, sources } from '@lib/agent';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

type Props = {
  type: 'folder' | 'source';
  onSuccess?:
    | ((
        data: AxiosResponse<unknown, unknown> | undefined,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  onSettled?:
    | ((
        data: unknown,
        error: Error | null,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  onMutate?: (() => unknown) | undefined;
};
export default function useRenameByTypeAndId({
  type,
  onSuccess,
  onMutate,
  onSettled,
}: Props) {
  return useMutation({
    mutationKey: ['rename', type],
    mutationFn: async ({ id, name }: { id: number; name: string }) => {
      if (type === 'folder') {
        return await folders.updateOne({
          id,
          name,
        });
      }
      return await sources.updateOne({
        id,
        name,
      });
    },
    onSuccess,
    onMutate,
    onSettled,
  });
}
