import Logo from '@components/logo';
import CONFIG_HEADER from '@layouts/main/sections/header/config';
import ExpandedListItem from '@layouts/main/sections/toolbar/components/expanded-list-item';
import LegalLinks from '@layouts/main/sections/toolbar/components/legal-links';
import CreditUsage from '@layouts/main/sections/toolbar/expanded/sections/credit-usage';
import { StyledStack } from '@layouts/main/sections/toolbar/expanded/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledListStack } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import Socials from '@layouts/main/sections/toolbar/socials';
import { StyledStackWrapper } from '@layouts/styles';
import { ButtonBase, Divider } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import CrossIcon from '~icons/knowz-iconify/cross';
import useCloseDrawerOnRouteChange from './hooks/useCloseDrawerOnRouteChange';

export default function NavbarDrawer() {
  const { toolbarExpansionStatus, handleToggleToolbar } = useToolbar();
  useCloseDrawerOnRouteChange();

  return (
    <Drawer
      PaperProps={{ sx: { width: CONFIG_HEADER.DRAWER_WIDTH } }}
      anchor="left"
      open={toolbarExpansionStatus === 'on'}
      onClose={handleToggleToolbar}
    >
      <StyledStack>
        <StyledListStack
          disablePadding
          component={Stack}
          direction="column"
        >
          <Stack
            direction="row"
            gap={4}
            sx={{
              alignItems: 'center',
              p: 4,
            }}
          >
            <ButtonBase onClick={handleToggleToolbar}>
              <CrossIcon fontSize="0.8em" />
            </ButtonBase>
            <Logo />
          </Stack>
          <Divider />
          <ExpandedListItem />
        </StyledListStack>
        <StyledStackWrapper>
          <Socials />
          <LegalLinks />
          <CreditUsage />
        </StyledStackWrapper>
      </StyledStack>
    </Drawer>
  );
}
