import { secrets } from '@lib/agent';
import { TFunction, useTranslation } from '@desygner/ui-common-translation';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import useWorkspace from '@hooks/useWorkspace';
import { queryClient } from '@providers/ReactQueryProvider';

export function useCreateOneSecret() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();

  return useMutation({
    mutationFn: secrets.createOneSecret,
    mutationKey: ['createOneSecret'],
    onError: (error) => onError(t, error),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('secrets')
      })
    }
  });
}

export function useGetAllSecrets() {
  const { t } = useTranslation();

  const { data, isLoading: isLoadingSecrets } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['secrets'],
      queryFn: async () => {
        try {
          return await secrets.getAllSecrets();
        } catch (error) {
          onError(t, error);
        }
      },
    });

  return { secrets: data, isLoadingSecrets };
}

function onError(t: TFunction, error: any) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}
