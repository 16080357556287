import {
  getAvailableOptionsOfType,
  getStepContextTypes,
} from '@components/pages/command-designer/config';
import Text from '@components/text';
import { BagItemType, StepType } from '@lib/step/types';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useDialogs } from '@toolpad/core';
import { useMemo, useState } from 'react';
import { useStepEditor } from 'sequential-workflow-designer-react';
import { useGetAllSecrets } from '../../hooks/useSecrets';
import { SecretDefinitionDialog } from '../secret-definition';
import DirectiveLabel from './DirectiveLabel';
import Input from '@components/input';

const CONTEXT_LIMIT_TOTAL = 15;

export type ToolbarOptionType = {
  value?: string;
  type?: BagItemType;
  name?: string;
};

function OptionButton({
  value,
  type,
  name,
  onClick,
}: ToolbarOptionType & { onClick: (option: ToolbarOptionType) => void }) {
  return (
    <DirectiveLabel
      component={Button}
      size="small"
      color={value ? 'purple' : 'green'}
      variant="outlined"
      onClick={() => onClick({ value, type, name })}
    >
      <Box
        component="span"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value || name}
      </Box>
    </DirectiveLabel>
  );
}

export default function ToolbarContents({
  step,
  onClick,
}: {
  step: StepType;
  onClick: (option: ToolbarOptionType) => void;
}) {
  const [filter, setFilter] = useState('');
  const dialogs = useDialogs();
  const { id, definition } = useStepEditor();
  const { secrets, isLoadingSecrets } = useGetAllSecrets();

  const availableContext = useMemo(() => {
    const result = [];
    const types = getStepContextTypes(step);

    for (const type of types) {
      const availableContextOfType = getAvailableOptionsOfType(
        id,
        type,
        definition,
      ) as string[];

      result.push(...availableContextOfType.map((value) => ({ value, type })));
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, step]);

  const filteredOptions = useMemo(() => {
    const secretsData = secrets?.data || [];

    return [...availableContext, ...secretsData].filter(
      (context: ToolbarOptionType) =>
        !filter ||
        context.value?.toLocaleLowerCase().includes(filter) ||
        context.name?.toLocaleLowerCase().includes(filter),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableContext, filter, secrets, isLoadingSecrets]);

  function handleDefineSecret() {
    dialogs.open(SecretDefinitionDialog);
  }

  if (availableContext.length + (secrets?.data?.length || 0) === 0) {
    return (
      <Alert
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          background: (theme) => theme.palette.background.paper,
          borderTop: (theme) =>
            `1px solid ${theme.palette.background.card.main}`,

          borderRadius: '0!important',
        }}
        severity="info"
      >
        No options defined yet. You can add context in previous steps or
        <Text
          component="span"
          sx={{
            cursor: 'pointer',
            marginLeft: 1,

            '&:hover': {
              opacity: 0.8,
            },
          }}
          variant="textMd"
          onClick={handleDefineSecret}
        >
          define secrets
        </Text>
        .
      </Alert>
    );
  }

  return (
    <Stack
      gap={1}
      sx={{
        width: '100%',
        padding: (theme) => theme.spacing(3, 2, 2, 2),
        background: (theme) => theme.palette.background.paper,
        borderRadius: 0,
        borderTop: (theme) => `1px solid ${theme.palette.background.card.main}`,
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          width: '100%',
        }}
      >
        <Input
          placeholder="Insert secrets or context from previous steps"
          onChange={(event) =>
            setFilter(event.target.value.toLocaleLowerCase())
          }
          sx={{ flexGrow: 1 }}
        />

        <Button
          size="small"
          variant="outlined"
          onClick={handleDefineSecret}
        >
          Define Secret
        </Button>
      </Stack>

      <Stack
        direction="row"
        gap={1}
        sx={{
          width: '100%',
        }}
      >
        {filteredOptions.slice(0, CONTEXT_LIMIT_TOTAL).map((context, i) => (
          <OptionButton
            key={`context-button-${i}`}
            {...context}
            onClick={onClick}
          />
        ))}

        {filteredOptions.length > CONTEXT_LIMIT_TOTAL && (
          <Text variant="textMd">...</Text>
        )}

        {!filteredOptions.length && (
          <Text variant="textMd">No options found</Text>
        )}
      </Stack>
    </Stack>
  );
}
