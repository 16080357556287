/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { SourceConfigurationType } from '@shared-types/sources';

export const sourceConfigurations = {
  createOneSourceConfiguration: (body: SourceConfigurationType) =>
    requests(axiosApiInstance).post<SourceConfigurationType>(
      '/v1/entities/source-configurations',
      body,
    ),
  updateOneSourceConfiguration: (
    id: number | string,
    body: SourceConfigurationType,
  ) =>
    requests(axiosApiInstance).patch<SourceConfigurationType>(
      `/v1/entities/source-configurations/${id}`,
      body,
    ),
  deleteOneSourceConfiguration: (id: number) =>
    requests(axiosApiInstance).delete(
      `/v1/entities/source-configurations/${id}`,
    ),
  getAllSourceConfigurations: () =>
    requests(axiosApiInstance).get<SourceConfigurationType[]>(
      '/v1/entities/source-configurations',
    ),
  getSourceConfiguration: (id: number) =>
    requests(axiosApiInstance).get<SourceConfigurationType>(
      `/v1/entities/source-configurations/${id}`,
    ),
  getSourceConfigurationConfigHandler: (id: number, handler: string) =>
    requests(axiosApiInstance).get<SourceConfigurationType>(
      `/v1/entities/source-configurations/${id}/${handler}`,
    ),
};
