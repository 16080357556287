import Text from '@components/text';
import { useTranslation } from '@desygner/ui-common-translation';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

type Props = {
  img: React.ReactNode;
  title: string;
  subtitle: string;
};

export default function ErrorTemplate({ img, title, subtitle }: Props) {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Stack
        gap={6}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        {img}
        <Stack sx={{ alignItems: 'center' }}>
          <Text
            variant="displayMd"
            component="h1"
            sx={{ textAlign: 'center' }}
          >
            {title}
          </Text>
          <Text
            variant="textSm"
            sx={{ color: 'neutral.grey', textAlign: 'center' }}
          >
            {subtitle}
          </Text>
        </Stack>
        <Button
          LinkComponent={Link}
          href={import.meta.env.VITE_KNOWZ_WEBSITE_URL}
          variant="contained"
        >
          {t('page.error.cta', {
            defaultValue: 'Back to Home',
          })}
        </Button>
      </Stack>
    </Container>
  );
}
