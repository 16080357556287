import { alpha, Theme } from '@mui/material';

export default function Button(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: {
                size: 'xxlarge',
              },
              style: {
                fontSize: theme.typography.textLg.fontSize,
                lineHeight: theme.typography.textLg.lineHeight,
                minHeight: 60,
                padding: theme.spacing(4, 5.5),
              },
            },
            {
              props: { size: 'xlarge' },
              style: {
                fontSize: theme.typography.textMd.fontSize,
                lineHeight: theme.typography.textMd.lineHeight,
                minHeight: 48,
                padding: theme.spacing(3, 4.5),
              },
            },
            {
              props: { size: 'large' },
              style: {
                fontSize: theme.typography.textSm.fontSize,
                lineHeight: theme.typography.textSm.lineHeight,
                minHeight: 44,
                padding: theme.spacing(2.5, 4),
              },
            },
            {
              props: { size: 'medium' },
              style: {
                fontSize: theme.typography.textSm.fontSize,
                lineHeight: theme.typography.textSm.lineHeight,
                minHeight: 40,
                padding: theme.spacing(2.5, 3.5),
              },
            },
            {
              props: { size: 'small' },
              style: {
                fontSize: theme.typography.textSm.fontSize,
                lineHeight: theme.typography.textSm.lineHeight,
                minHeight: 36,
                padding: theme.spacing(2, 3),
              },
            },
          ],
          fontWeight: 600,
          borderRadius: theme.shape.borderRadius * 2,
          textTransform: 'none',
          '&, &:hover, &:focus': {
            boxShadow: 'none',
          },
          '&.MuiButton-text, &.MuiButton-outlined': {
            '&.MuiButton-colorPrimary': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            },
            '&.MuiButton-colorError': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.error.main, 0.08),
              },
            },
            '&.MuiButton-colorSuccess': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.success.main, 0.08),
              },
            },
            '&.MuiButton-colorWarning': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.warning.main, 0.08),
              },
            },
            '&.MuiButton-colorInfo': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.info.main, 0.08),
              },
            },
            '&.MuiButton-colorInherit': {
              backgroundColor: alpha(
                isLight
                  ? theme.palette.common.black
                  : theme.palette.common.white,
                0.05,
              ),
              borderColor: alpha(theme.palette.text.primary, 0.23),
              color: theme.palette.text.primary,
            },
          },
        },
      },
    },
  };
}
