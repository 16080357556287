import LogoutButton from '@components/logout-button';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useWorkspace from '@hooks/useWorkspace';
import { signUp } from '@lib/auth/fingerprint';
import Button, { ButtonProps } from '@mui/material/Button';
import { queryClient } from '@providers/ReactQueryProvider';
import paths from '@router/paths';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type TrimmedButtonProps = Omit<ButtonProps, 'label'>;

const MOBILE_BUTTONS: Array<
  TrimmedButtonProps & {
    mappedLabel: 'page.auth.mobile.buttons.primary';
    defaultLabel: 'Login';
  }
> = [
  {
    mappedLabel: 'page.auth.mobile.buttons.primary',
    defaultLabel: 'Login',
    variant: 'contained',
  },
];

export default function AuthButton() {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParamsState('action', '');
  const { me, handleUpdateMe } = useAuth();
  const { resetForm } = useUserAuth();
  const { resetWorkspaceToIndividualRole, setupMode } = useWorkspace();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigateTo = useNavigate();

  function openModal() {
    setSearchParams('secure-account');
  }

  //TODO: we have some repetitive logic for both mobile/desktop dry it up later!
  async function handleLogout() {
    window.localStorage.removeItem('token');
    //TODO: temporary fix to redirect user to home page after logout if they are in setup mode
    if (setupMode !== null) {
      navigateTo(paths.home.pathname);
    }
    resetForm();
    resetWorkspaceToIndividualRole();
    toast.success(
      t('response.success.logout', {
        defaultValue: 'You have successfully logged out',
      }),
    );

    if (!executeRecaptcha) return;

    const recaptchaToken = await executeRecaptcha();

    const response = await signUp(recaptchaToken);

    queryClient.refetchQueries();

    if (response?.data.status === 'Ok') {
      window.localStorage.removeItem('fingerPrintId');
      handleUpdateMe(response.data.token);
    }
  }

  if (me && (me.auth_type === 'email' || me.auth_type === 'oauth2')) {
    return <LogoutButton handleLogout={handleLogout} />;
  }

  return MOBILE_BUTTONS.map(({ defaultLabel, mappedLabel, ...other }) => {
    return (
      <Button
        key={mappedLabel}
        size="small"
        {...other}
        onClick={openModal}
      >
        {t(mappedLabel, { defaultValue: defaultLabel })}
      </Button>
    );
  });
}
